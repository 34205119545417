<template>
  <v-main align="center">
    <v-container class="py-8 px-2" style="margin-bottom: 80px">
      <div style="font-size: 2rem; font-weight: bold; color: var(--main-color)">
        <div v-if="error.statusCode === 403">
          <div>403 Forbidden</div>
          <div style="font-size: var(--read-font-size-m)">
            {{ $t('error_page_not_allowed') }}
          </div>
        </div>
        <div v-if="error.statusCode === 404">
          <div>404 Not Found</div>
          <div style="font-size: var(--read-font-size-m)">
            {{ $t('error_page_not_found') }}
          </div>
        </div>
        <div v-else>
          <div>500 Server Error</div>
          <div style="font-size: var(--read-font-size-m)">
            {{ $t('error_page_server_error') }}
          </div>
        </div>
        <div class="py-10">
          <v-img
            max-width="250"
            height="100%"
            width="100%"
            :src="errorImg"
            style="margin-left: auto; margin-right: auto"
          ></v-img>
        </div>
        <div
          style="
            font-size: var(--read-font-size-s);
            font-weight: bold;
            color: var(--read-font-color-sub);
          "
        >
          <div v-if="error.statusCode === 403">
            {{ $t('error_page_access_denied') }}<br />
            {{ $t('Press the back button on your device to return.') }}
          </div>
          <div v-if="error.statusCode === 404">
            {{ $t('error_page_check_url_not_found') }}
          </div>
          <div v-else>
            {{ $t('error_page_check_url_server_error') }}
          </div>
        </div>
      </div>
    </v-container>
    <v-btn
      block
      :to="`/${$i18n.locale}`"
      color="primary"
      style="
        font-size: var(--read-font-size-l);
        height: 55px;
        border-radius: 0;
        position: fixed;
        bottom: 0;
        left: 0;
      "
      >{{ $t('back_to_home') }}</v-btn
    >
  </v-main>
</template>

<script>
import errorImg from '~/assets/img/error.png';

export default {
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  head() {
    const title =
      this.error.statusCode === 404 ? this.pageNotFound : this.otherError;
    return {
      title,
    };
  },
  data() {
    return {
      errorImg: errorImg,
    };
  },
};
</script>

<style scoped>
h1 {
  font-size: var(--read-font-size-l);
}
</style>
