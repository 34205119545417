<template>
  <v-btn
    class="guest_entry_btn"
    fixed
    bottom
    right
    ripple
    dark
    large
    fab
    color="blue darken-2"
    v-on:click="toLogin()"
  >
    <v-icon large>mdi-login-variant</v-icon>
  </v-btn>
</template>

<script>
import _ from 'lodash';

export default {
  components: {},
  props: [],
  data: () => ({}),
  computed: {},
  created: function () {},
  mounted: function () {},
  methods: {},
};
</script>

<style scoped>
.guest_entry_btn {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  background: linear-gradient(to right, #b22c27, #e83029);
  bottom: calc(env(safe-area-inset-bottom) + 70px);
  z-index: 100;
}
</style>
