import Vue from 'vue'

/* ローディング */
import LoadingPost from '~/components/ui/loading/LoadingPost.vue';
import LoadingSave from '~/components/ui/loading/LoadingSave.vue';
import LoadingDelete from '~/components/ui/loading/LoadingDelete.vue';
import LoadingChange from '~/components/ui/loading/LoadingChange.vue';
import LoadingLogin from '~/components/ui/loading/LoadingLogin.vue';
import LoadingLogout from '~/components/ui/loading/LoadingLogout.vue';
import LoadingImage from '~/components/ui/loading/LoadingImage.vue';
import LoadingFavorite from '~/components/ui/loading/LoadingFavorite.vue';

Vue.mixin({
  components: {
    LoadingPost,
    LoadingSave,
    LoadingDelete,
    LoadingChange,
    LoadingLogin,
    LoadingLogout,
    LoadingImage,
    LoadingFavorite,
  }
})