/**
 * ログインユーザー設定
 */
export const state = () => ({
  chatSelectTab: 0,
})

export const mutations = {
  chatSelectTab(state, type) {
    state.chatSelectTab = type;
  }
}

export const actions = {
  chatSelectTab(context, type) {
    context.commit('chatSelectTab', type)
  }
}
