import Vue from 'vue'
import loadImage from 'blueimp-load-image'
const moment = require('moment')

Vue.mixin({
  methods: {
    /**
     * メッセージ日付け一致チェック:
     * 引数1(date)の日付けが引数2(lastDate)と
     * 同日の場合true,別日の場合falseを返す
     */
    messageDateCheck: function(date = null, lastDate = null){
      if(!date || !lastDate) return false
      if(lastDate && moment(date).format('YYYY-MM-DD') === moment(lastDate.created_at).format('YYYY-MM-DD')) return true
      else return false
    },
    /**
     * メッセージエンコード:
     * 引数の文字列内に「https」を含んでいた場合、aタグを付けて返す
     */
     messageEncodeLink: function(text = null) {
      if(!text) return ''
      return (typeof text === 'string') ? text.replace(/(https?:\/\/[^\s]*)/g, "<a href='$1' target='_blank' class='message-a-tag'>$1</a>") : ''
    },
    /**
     * チャットコンテンツの高さを調整
     */
    adjustContentsHeight: function(initialFlag = false){
      setTimeout(() => {
        let target = document.getElementById('usr-chat__form-target')
        if(target){
          this.messageMarginBottom = target.clientHeight
          this.scrollToEnd(initialFlag) // 引数の設定値を渡す
        }

        // スクロール位置を取得して最下部の場合、画像読み込み完了フラグをtrueに変更する
        if(!this.imgLoadingFinishFlag&&this.getRoomCompleteFlag){
          let body = window.document.body
          let html = window.document.documentElement
          let scrollTop = body.scrollTop || html.scrollTop
          let scroll = html.scrollHeight - html.clientHeight - scrollTop
          if(scroll < 100) this.imgLoadingFinishFlag = true
        }
      }, 300)
    },
    /**
     * チャットコンテンツの高さを調整(iPhone用)
     * 入力時にテキストボックスが消える問題を回避
     */
    adjustContentsHeightForIPhone: function(){
      setTimeout(() => {
        let target = document.getElementById('usr-chat__form-target')
        if(target) this.messageMarginBottom = target.clientHeight

        // スクロール位置を取得して最下部の場合、画像読み込み完了フラグをtrueに変更する
        if(!this.imgLoadingFinishFlag&&this.getRoomCompleteFlag){
          let body = window.document.body
          let html = window.document.documentElement
          let scrollTop = body.scrollTop || html.scrollTop
          let scroll = html.scrollHeight - html.clientHeight - scrollTop
          if(scroll < 100) this.imgLoadingFinishFlag = true
        }
      }, 300);
    },
    /**
     * メッセージの最下部まで移動する:
     * 第1引数 initialFlag: trueの場合未読のメッセージまで移動する
     * 第2引数 attachmentDownloadFlag: trueの場合(usr-chat__contents-attachment-target)の位置まで移動する
     */
     scrollToEnd(initialFlag = false, attachmentDownloadFlag = false) {
      this.$nextTick(() => {
        let elementHtml = document.documentElement

        // 未読のメッセージまで移動する 参考タスク: MCG-385 チャットルームに「ここから未読メッセージ」を表示する
        if(initialFlag && document.getElementById('usr-chat__unread-marker-target')){
          const unreadTargetHeight = (elementHtml.clientHeight*3/4) + document.getElementById('usr-chat__unread-marker-target').getBoundingClientRect().top + window.pageYOffset
          let bottom = unreadTargetHeight - elementHtml.clientHeight
          window.scroll(0, bottom)
        }else if(attachmentDownloadFlag && document.getElementById('usr-chat__contents-attachment-target')){
          const unreadTargetHeight = (elementHtml.clientHeight*3/4) + document.getElementById('usr-chat__contents-attachment-target').getBoundingClientRect().top + window.pageYOffset
          let bottom = unreadTargetHeight - elementHtml.clientHeight
          window.scroll(0, bottom);
          document.getElementById("usr-chat__contents-attachment-target").removeAttribute("id") // idを削除
        }else{
          let bottom = elementHtml.scrollHeight - elementHtml.clientHeight
          window.scroll(0, bottom)
        }
      })
    },
    /**
     * 画像のレンダリングが終わったら最下部に移動する
     */
     imgLoadingInitial: function(id = null) {
      if(!id) return false
      if(!this.imgLoadingFinishFlag){
        if(this.latestImageMessageId == id) this.imgLoadingFinishFlag = true
        setTimeout(() => {
          this.scrollToEnd(true)
        }, 300)
      }
    },
    // 画像アップロード
    uploadMessageAttachment(e) {
      // ファイル未選択
      if (!e.target.files || !e.target.files[0]) return
      // 拡張子制限
      if (!e.target.files[0].type.match(/jpg|jpeg|png|gif|pdf/i) ) {
        this.alertSnackbar = true
        this.alertSnackbarMessage = '画像かPDFをアップロードしてください'
        return
      }
      // ファイルサイズ制限(MAX:20MB)
      if (e.target.files[0].size > 20 * 1024 * 1024) {
        this.alertSnackbar = true
        this.alertSnackbarMessage = '最大20MBのファイルまで送信可能です。'
        return
      }

      this.fileData     = null
      this.menuViewFlag = false
      this.uploading    = true

      if (e.target.files[0].type.match(/jpg|jpeg|png|gif/i)){
        // 画像の場合
        this.uploadType = 1
        const sizeLimit = 2 * 1024 * 1024 // 画像サイズ制限（MAX:2MB）
        const file = e.target.files[0]

        loadImage.parseMetaData(file, (data) => {
          const options = { canvas: true }
          // 回転補正
          if (data.exif) options.orientation = data.exif.get('Orientation')

          loadImage(file, async (canvas) => {
            let fileData = canvas.toDataURL(file.type)
            let fileQuality = Math.sqrt(sizeLimit / fileData.length) // 容量調整
            while (fileData.length > sizeLimit) {
              fileData = canvas.toDataURL(file.type, fileQuality)
              fileQuality -= 0.1
            }

            this.fileData = fileData
            this.scrollToEnd()
            this.uploading = false
          }, options)
        })
      }else if (e.target.files[0].type.match(/pdf/i) ){
        // PDFの場合
        this.uploadType = 2
        this.fileData = e.target.files[0]
        this.scrollToEnd()
        this.uploading = false
      }
    },
    /**
     * 添付ファイルをダウンロード
     * 第1引数 roomType: 対象ルームタイプ
     * 第2引数 id: ルームまたはお知らせのID
     */
    attachmentDownload: function(roomType = null, id = null) {
      if(!roomType || !id) return false
      this.downloading = true
      let tmp = document.getElementsByClassName('usr-chat__contents-attachment-marker-'+this.selectFilename)
      if(tmp) tmp[0].setAttribute("id", 'usr-chat__contents-attachment-target') // id属性追加

      this.$axios.post(
        `private/get-${roomType}-attachment-url`
      , {
        email: this.$store.state.auth.user.email,
        roomId:   id,
        filename: this.selectFilename
      }, {
        headers: {
          Authorization: this.$auth.$storage.getUniversal('_token.auth0', true),
        }
      }).then(async response => {
        let url = response.data.url
        let xhr = new XMLHttpRequest()
        xhr.open('GET', url, true)
        xhr.responseType = 'blob'
        xhr.onload = function() {
          let selectFilename = document.getElementById("select-filename").textContent

          if (this.status == 200) {
            let myBlob = this.response
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(myBlob)
            link.download = selectFilename
            link.click()
          }
        };
        xhr.send()
      }).finally(() => {
        this.downloading = false
      })
    },
  }
})