import Vue from 'vue'

Vue.mixin({
  methods: {
    wrapSetTimeout(func, delay) {
      // callbackでdispatchを実行するとMaximum Memoryをひきを越す可能性があるため、念の為チェック
      if (this.$route.path === '/callback' || this.$route.path === '/login') return;
      let timerId = setTimeout(func, delay);
      if (timerId) this.$store.dispatch('timerStore/commitTimerIds', timerId);
    },
    clearAllTimeouts() {
      const timers = this.$store.state.timerStore.timerIds;
      if (!timers.length) return;
      for (var i = 0; i < timers.length; i++) {
          clearTimeout(timers[i]);
      }
      this.$store.dispatch('timerStore/commitInitTimerIds', []);
    }
  },
})