<template>
  <v-dialog
    v-model="flag"
    persistent
    no-click-animation
    fullscreen
    transition="dialog-bottom-transition"
  >
    <CastEvaluation
      :reservationId="Number(this.reservationId)"
      :customerId="Number(this.customerId)"
      @closeDialog="closeDialog()"
    />
  </v-dialog>
</template>

<script>
import CastEvaluation from "~/components/pages/castEvaluation/CastEvaluation.vue";

export default {
  components: {
    CastEvaluation,
  },
  props: [],
  data: () => ({
    flag: false,
    reservationId: "",
    customerId: "",
  }),
  computed: {},
  created: function () {
    if (this.$store.state.auth.loggedIn === false) {
      return;
    }
    this.getAjaxCastEvalutaion();
  },
  mounted: function () {},
  methods: {
    /**
     * ajax: サイト会員未評価存在チェック
     * storeを更新
     */
    async getAjaxCastEvalutaion() {
      // 未評価存在チェック
      await this.$axios
        .post(
          "private/customer-evalutaion-info",
          {
            email: `${this.$store.state.auth.user.email}`,
          },
          {
            headers: {
              Authorization: `${this.$auth.$storage.getUniversal(
                "_token.auth0",
                true
              )}`,
            },
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            // 存在しないときは何もしない
          } else if (response.data.statusCode === 201) {
            this.reservationId = response.data.data.reservation_id;
            this.customerId = response.data.data.customer_id;
            // storeを更新
            this.$store.dispatch(
              "notification/castEvaluation",
              response.data.data.reservation_id
            );
            this.flag = true;
          } else {
            // ログを保存
          }
        })
        .catch((error) => {
          // トークンエラー
          this.$store.dispatch("dialog/tokenError", true);
        });
    },
    closeDialog: function () {
      this.flag = false;
    }
  },
};
</script>