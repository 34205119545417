/**
 * ダイアログ
 */

export const state = () => ({
  selectedCastUrlHash: '',
  selectedTopScrollY: 0,
  selectedCastProfileDialog: false,
  selectedCastProfileScheduleDialog: false,
  selectedCastReserveDialog: false,
  selectedSlideCastClass: '', // 選択キャスト（ホーム）
  sliderScrollX: 0,
  sliderScrollXTargetId: '',
})

export const mutations = {
  selectedCastUrlHash (state, data) {
    state.selectedCastUrlHash = data;
  },
  selectedTopScrollX (state, data) {
    state.selectedTopScrollY = data;
  },
  selectedTopScrollY (state, data) {
    state.selectedTopScrollY = data;
  },
  selectedCastProfileDialog (state, data) {
    state.selectedCastProfileDialog = data;
  },
  selectedCastProfileScheduleDialog (state, data) {
    state.selectedCastProfileScheduleDialog = data;
  },
  selectedCastReserveDialog (state, data) {
    state.selectedCastReserveDialog = data;
  },
  selectedSlideCastClass (state, data) {
    state.selectedSlideCastClass = data;
  },
  sliderScrollX (state, data) {
    state.sliderScrollX = data;
  },
  sliderScrollXTargetId (state, data) {
    state.sliderScrollXTargetId = data;
  },
}

export const actions = {
  selectedCastUrlHash(context, data) {
    context.commit('selectedCastUrlHash', data)
  },
  selectedTopScrollY(context, data) {
    context.commit('selectedTopScrollY', data)
  },
  selectedCastProfileDialog(context, data) {
    context.commit('selectedCastProfileDialog', data)
  },
  selectedCastProfileScheduleDialog(context, data) {
    context.commit('selectedCastProfileScheduleDialog', data)
  },
  selectedCastReserveDialog(context, data) {
    context.commit('selectedCastReserveDialog', data)
  },
  selectedSlideCastClass(context, data) {
    context.commit('selectedSlideCastClass', data);
  },
  sliderScrollX(context, data) {
    context.commit('sliderScrollX', data);
  },
  sliderScrollXTargetId(context, data) {
    context.commit('sliderScrollXTargetId', data);
  },
}
