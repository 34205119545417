<template>
  <div class="d-flex align-center">
    <v-btn 
      small
      class="mr-2 px-2 selected-tag d-flex align-center"
      :disabled="loading"
      elevation="0"
      @click="changeTermType()"
    >
      <!-- <v-icon x-small class="mr-1">mdi-swap-horizonta</v-icon> -->
      <div class="mr-1" style="font-weight:bold;">
        <div style="height:6px;">→</div>
        <div>←</div>
      </div>
      {{ termBtnData[termBtnIndex].label }}
    </v-btn>
    <GuestAlertDialog :message="guestAlertMessage" ref="guestAlert" />
  </div>
</template>

<script>
import _ from 'lodash';
import GuestAlertDialog from "~/components/pages/dialog/GuestAlertDialog.vue";

export default {
  components: {
    GuestAlertDialog
  },
  data() {
    return {
      selectedTermType: 1,
      termBtnIndex: 0,
      guestAlertMessage: ""
    }
  },
  created() {
    this.selectedTermType = this.termType;
    const termType = this.selectedTermType;
    this.termBtnIndex = _.findIndex(this.termBtnData, function (o) {
      return o.id === termType;
    });
  },
  watch: {
    termType(newVal, oldVal) {
      this.selectedTermType = newVal;
      this.termBtnIndex = _.findIndex(this.termBtnData, function (o) {
        return o.id === newVal;
      });
    }
  },
  props: {
    termBtnData: {
      type: Array
    },
    termType: {
      type: Number
    },
    loading: {
      type: Boolean
    }
  },
  methods: {
    changeTermType() {
      if (!this.$store.state.auth.loggedIn) {
        this.isGuestDialog(this.$t('login_text_2'));
        return;
      }
      this.termBtnIndex++;
      if (this.termBtnIndex === this.termBtnData.length) this.termBtnIndex = 0;
      this.$emit('updateTermType', this.termBtnData[this.termBtnIndex].id);
    },
  }
}
</script>