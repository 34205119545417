<template>
  <div>
    <nav>
      <v-bottom-navigation
        app
        fixed
        color="primary"
        class="bottom-navigation-wrapper"
        grow
      >
        <v-row justify="space-between" no-gutters>
          <v-col cols="5" class="pa-0">
            <v-layout class="justify-start">
              <v-btn
                :ripple="false"
                class="bottom-navigation-button"
                min-width="50%"
                :to="`/${$i18n.locale}`"
                v-on:click="getAjaxSiteMemberData()"
              >
                <span
                  :style="{
                    color:
                      this.$route.path === '/'
                        ? 'var(--main-color)'
                        : 'rgba(0, 0, 0, 0.54)',
                  }"
                  style="font-weight: bold"
                  >{{ $t('home') }}</span
                >
                <IconHome
                  :style="{
                    fill:
                      this.$route.path === '/'
                        ? 'var(--main-color)'
                        : 'rgba(0, 0, 0, 0.54)',
                  }"
                  style="fill: #red; height: 32px"
                />
              </v-btn>
              <v-btn
                v-if="$store.state.auth.loggedIn === true"
                :ripple="false"
                class="bottom-navigation-button"
                min-width="50%"
                :to="`/${$i18n.locale}/chat/operationRoom`"
                v-on:click="getAjaxSiteMemberData()"
              >
                <span style="font-weight: bold">{{ $t('chat') }}</span>
                <div v-if="chatUnReadMessageCount>0">
                <v-badge
                  color="#B22C27"
                  overlap
                >
                  <template v-slot:badge><span v-if="chatUnReadMessageCount>0">{{ chatUnReadMessageCount }}</span></template>
                  <IconTalk :style="{'fill':(this.$route.path===`/${$i18n.locale}/chat`||this.$route.path.match(`/${$i18n.locale}/chat`))?'var(--main-color)':'rgba(0, 0, 0, 0.54)'}" style="height:32px;" />
                </v-badge>
                </div>
                <div v-else>
                  <IconTalk :style="{'fill':(this.$route.path===`/${$i18n.locale}/chat`||this.$route.path.match(`/${$i18n.locale}/chat`))?'var(--main-color)':'rgba(0, 0, 0, 0.54)'}" style="height:32px;" />
                </div>
              </v-btn>
              <v-btn
                v-else
                v-on:click="isGuestDialog($t('login_text_3'))"
                class="bottom-navigation-button"
                min-width="50%"
              >
                <span style="font-weight: bold">{{ $t('chat') }}</span>
                <div>
                  <IconTalk :style="{'fill':(this.$route.path===`/${$i18n.locale}/chat`||this.$route.path.match(`/${$i18n.locale}/chat`))?'var(--main-color)':'rgba(0, 0, 0, 0.54)'}" style="height:32px;" />
                </div>
              </v-btn>
              <v-btn
                :ripple="false"
                class="bottom-navigation-button"
                min-width="50%"
              ></v-btn>
            </v-layout>
          </v-col>

          <div class="link-reserve">
            <nuxt-link
              v-ripple="{ class: `white--text` }"
              class="link-reserve2 v-btn"
              :to="`/${$i18n.locale}/call`"
            >
              <span class="v-btn__content" v-on:click="getAjaxSiteMemberData()">
                <span style="font-weight: bold; color: #fff">{{
                  $t('search')
                }}</span>
                <IconSp class="pb-2" style="height: auto" />
              </span>
            </nuxt-link>
          </div>

          <v-col cols="5" class="pa-0">
            <v-layout class="justify-end">
              <v-btn
                :ripple="false"
                class="bottom-navigation-button"
                min-width="50%"
                :to="`/${$i18n.locale}/photo-diary`"
                v-on:click="getAjaxSiteMemberData()"
              >
                <span style="font-weight: bold">{{ $t('photo_diary') }}</span>
                <IconCamera
                  :style="{
                    fill: this.$route.path.match('/photo-diary')
                      ? 'var(--main-color)'
                      : 'rgba(0, 0, 0, 0.54)',
                  }"
                  style="height: 32px"
                />
              </v-btn>
              <v-btn
                :ripple="false"
                class="bottom-navigation-button"
                min-width="50%"
                :to="`/${$i18n.locale}/mypage`"
                v-on:click="getAjaxSiteMemberData()"
                style="position: relative"
              >
                <span style="font-weight: bold">{{ $t('my_page') }}</span>
                <!-- <v-badge color="#B22C27" overlap>
                  <template v-slot:badge>
                    <span v-if="$store.state.newNotification.newNotificationSum">
                      {{ $store.state.newNotification.newNotificationSum }}
                    </span>
                  </template> -->
                <IconMypage
                  :style="{
                    fill: this.$route.path.match('/mypage')
                      ? 'var(--main-color)'
                      : 'rgba(0, 0, 0, 0.54)',
                  }"
                  style="height: 32px; fill: rgba(0, 0, 0, 0.54)"
                />
                <!-- </v-badge> -->
              </v-btn>
            </v-layout>
          </v-col>
        </v-row>
      </v-bottom-navigation>
    </nav>
    <GuestAlertDialog :message="guestAlertMessage" ref="guestAlert" />
  </div>
</template>

<script>
export default {
  auth: false,
  data() {
    return {
      isGuest: !this.$store.state.auth.loggedIn,
      guestAlertMessage: '',
    }
  },
  components: {},
  computed: {
    chatUnReadMessageCount() {
      return this.$store.state.notification.chatUnReadMessageCount;
    },
  },
  methods: {
    /**
     * ajax: 一定期間ごとにサイト会員情報を更新(CSR)
     */
    getAjaxSiteMemberData() {
      // 保持している予約IDを空にする。
      this.$store.dispatch('call/selectedReserveId', '');
      this.closeCastDialog();
      if (process.client) {
        // 更新APIで取得した時間から指定時間以上過ぎていた場合再度更新APIで取得
        if (
          this.$store.state.notification.notificationCheckTime === '' ||
          this.$moment(this.$store.state.notification.notificationCheckTime)
            .add(process.env.NOTIFICATION_UPDATE_TIME_INTERVAL, 'seconds')
            .format('YYYY-MM-DD HH:mm:ss') <=
            this.$moment().format('YYYY-MM-DD HH:mm:ss')
        ) {
          // 予約通知情報を更新
          this.getAjaxReserveNotification();

          // チャット通知情報を更新
          this.getAjaxChatNotification();

          this.$store.dispatch(
            'notification/notificationCheckTime',
            this.$moment()
          );
        } else {
          // var diffTime = this.$moment(this.$store.state.notification.notificationCheckTime).add(process.env.NOTIFICATION_UPDATE_TIME_INTERVAL, 'seconds')-this.$moment();
          // console.log('更新まであと'+Math.floor(diffTime/1000)+'秒')
        }
      }
    },
  },
};
</script>
<style scoped>
.v-btn--disabled {
  opacity: 0.2;
}
.v-btn:before {
  background-color: transparent !important;
}
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(
    .v-btn--outlined
  ) {
  background-color: transparent !important;
}
.link-reserve {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: '';
  display: block;
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.1);
}
.link-reserve2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  background: var(--webkit-main-gradient);
  background: linear-gradient(40deg, #b22c27, #e83029) !important;
  width: 4rem !important;
  min-width: 4rem !important;
  height: 4rem !important;
  border-radius: 100% !important;
}
.v-btn {
  font-size: 0.65rem !important;
}
.v-btn__content {
  opacity: 1 !important;
}
.v-item-group.v-bottom-navigation .v-btn .v-btn__content > *:not(.v-icon) {
  line-height: 0.8 !important;
}
.bottom-navigation-wrapper {
  height: calc(56px + env(safe-area-inset-bottom)) !important;
  padding-top: calc(env(safe-area-inset-bottom) - 56px) !important;
  padding-bottom: calc(env(safe-area-inset-bottom)) !important;
}
.bottom-navigation-button {
  height: 56px !important;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .bottom-navigation-wrapper {
    height: calc(76px + env(safe-area-inset-bottom)) !important;
    padding-top: calc(env(safe-area-inset-bottom) - 76px) !important;
    padding-bottom: calc(env(safe-area-inset-bottom)) !important;
  }
  .bottom-navigation-button {
    height: 76px !important;
    font-size: 1rem !important;
  }
  .v-icon.v-icon {
    font-size: 29px !important;
  }
  .link-reserve {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '';
    display: block;
    width: 6.8rem;
    height: 6.8rem;
    border-radius: 100%;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.1);
  }
  .link-reserve2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    font-size: 1rem !important;
    background: var(--webkit-main-gradient);
    background: var(--main-gradient);
    width: 6rem !important;
    min-width: 6rem !important;
    height: 6rem !important;
    border-radius: 100% !important;
  }
}
</style>
