<template>
  <div class="mx-auto" style="height: 100%; max-width: 768px; touch-action: auto;">
    <h1 v-if="loading && !postflag" class="d-flex align-center pl-3 help-dialog-header">
        <IconMedalWhite class="mr-2 icon-question" />{{ $t('text_iG2r') }}
    </h1>
    <v-card v-if="loading && !postflag" class="pa-4 pb-0" style="height: 100%">
      <v-layout column justify-center fill-height>
        <v-container class="text-center">
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
            class="mb-4 pa-2"
          />
          <div v-if="errorContents">
            {{ $t('text_A3xj') }}<br />
            {{ $t('text_zA27') }}
          </div>
        </v-container>
      </v-layout>
    </v-card>
    <v-card v-else-if="!loading && !postflag">
      <section class="pa-4 pb-0">
        <p class="usr-evalutaion-header__msg">
          {{ $t('text_pB4U') }}
        </p>
        <p class="usr-evalutaion-header__msg">{{ $t('text_fP5d') }}{{ reservation.answer_deadline }}</p>
        <v-card dark class="mx-auto cast-card">
          <v-subheader class="cast-card-header">{{ reservation.cast.shop.name }}</v-subheader>
          <v-card-text>
            <v-row align="center" justify="center">
              <v-col cols="6">
                <v-img
                  height="100%"
                  eager
                  :src="
                    defaultImage(
                      reservation.thumbnailUrl,
                      reservation.cast.shop_default_thumbnail_set.set_name
                    )
                  "
                ></v-img>
              </v-col>
              <v-col cols="6">
                <div class="cast-detail my-4">
                  <p class="ma-0 mb-10 py-2">
                    <span class="cast-detail__honorific">{{ $t('ms') }}</span>
                    &nbsp;
                    <span class="cast-detail__name">{{ reservation.cast.site_cast_name }}</span>
                  </p>
                  <p class="ma-0 cast-detail__date">
                    {{ reservation | dispayCourseName }}{{ $t('course') }}
                    <span v-if="extensionTime > 0"
                      >({{ extensionTime }}{{ $t('text_A3mc') }})</span
                    >
                  </p>
                  <p class="ma-0 cast-detail__date">
                    {{ this.$moment(reservation.start_datetime).format('MM/DD/YYYY HH:mm') }}～
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </section>
      <section class="pa-4 pb-0" v-if="reservation['is_cast_evaluation'] === true">
        <div class="my-8 text-center">
          <p class="text-msg2 text-left">
            {{
              reservation.cast.site_cast_name
            }}&nbsp;{{ $t('text_Q4u8') }}
          </p>
          <v-btn
            class="my-2 px-4"
            color="--read-font-color-primary"
            style="font-size: var(--read-font-size-m)"
            v-on:click="confirmDialog2 = true"
          >
            {{ $t('text_U6uR') }}
          </v-btn>
        </div>
      </section>
      <section class="px-4">
        <h2 class="cast-report__header">
          <IconMedalPink class="mr-2" />{{ $t('text_K9an') }}<span>{{ $t('text_F7rQ') }}</span>
        </h2>
        <p class="ma-0 pb-2 cast-repaort_msg">
          {{ $t('text_Je95') }}<br />
          {{ $t('text_pG3e') }}
        </p>
        <v-card class="cast-report__wrapper">
          <v-card-actions class="v-rating__wrapper">
            <span class="v-rating__title"> {{ $t('text_L7kn') }} </span>
            <v-rating
              v-model="overallSatisfaction"
              background-color="#E4C76A"
              color="#E4C76A"
              dense
              hover
              size="32"
            ></v-rating>
          </v-card-actions>
          <v-card-actions class="v-rating__wrapper"
            v-if="reservation.cast.shop.business_type_id === 2"
          >
            <span class="v-rating__title">{{ $t('text_y3EZ') }}</span>
            <v-rating
              v-model="treatmentSatisfaction"
              background-color="#E4C76A"
              color="#E4C76A"
              dense
              hover
              size="32"
            ></v-rating>
            <v-card-text class="v-rating__text">{{ starRatingMessages['treatmentSatisfaction'][treatmentSatisfaction] }}</v-card-text>
            <div :style="ratingStyle(treatmentSatisfaction)" class="triangle"></div><!-- 吹き出し用三角形 -->
          </v-card-actions>
          <div v-if="this.reservation['is_cast_evaluation'] === false">
            <v-card-actions v-for="(starRating, index) in starRatings" :key="`star_${index}`" class="v-rating__wrapper">
              <span class="v-rating__title"> {{ starRatingLabels[index].label }} </span>
              <v-rating
                v-model="starRating.point"
                background-color="#E4C76A"
                color="#E4C76A"
                dense
                hover
                size="32"
              ></v-rating>
            </v-card-actions>
          </div>
          <section>
            <h2 :class="reservation['is_cast_evaluation'] === false ? 'cast-report__header-optional' : 'cast-report__header'">
              {{ $t('review') }}
              <button class="mr-2 ml-1" @click="helpDialog = !helpDialog"><IconQuestion /></button>
              <span v-if="reservation['is_cast_evaluation'] === false"> {{ $t('text_a9FN') }} </span>
              <span v-if="reservation['is_cast_evaluation'] === true"> {{ $t('text_F7rQ') }} </span>
            </h2>
            <p class="pa-0 cast-repaort_msg">
              {{ $t('text_E2wP') }}
            </p>
            <div class="pa-0 mb-2 cast-repaort_msg text-right">{{ inputCommentCount }}/{{ maxCommentCount }}</div>
            <v-textarea outlined v-model="comment" :hide-details="true" rows="5" style="background-color: #fff;" />
            <template v-if="reservation['cast_schedule']['display_type'] !== 3">
              <p class="mt-5 pa-0 cast-repaort_msg">
                {{ $t('text_gC6x') }}
              </p>
              <div class="vertical-align">
                <v-checkbox
                  v-model="commentIntroductionFlag"
                  :label= "$t('text_uV8R')"
                  class="checkbox-text"
                  color="#4267B2"
                ></v-checkbox>
              </div>
            </template>
            <p v-else class="mt-5 pa-0 cast-repaort_msg" style="color: #B22C27;">
              {{ $t('text_f5T2') }}
            </p>
          </section>
        </v-card>
      </section>
      <!-- キャストへのプラスな印象 -->
      <section v-if="reservation['is_cast_evaluation'] === false">
        <div class="pa-4 pb-0">
          <h2 class="cast-report__header" style="font-size: var(--read-font-size-l);">
            <IconMedalPink class="mr-2" />{{ $t('text_bN3v') }}<span>{{ $t('text_F7rQ') }}</span>
          </h2>
          <p class="ma-0 pb-2 cast-repaort_msg">
            {{ $t('text_gP7b') }}
          </p>
          <p class="title_annotation">{{ $t('text_H5my') }}</p>
        </div>
        <div v-for="(categry, index) in categoryNameList2" :key="`c_${index}`">
          <h3 class="cast-report__header-sub">
            <component :is="categry.icon" style="margin-right:2px; height: 20px;" />{{ categry.label }}
          </h3>
          <v-row v-if="tags.length != 0" no-gutters align="center" class="px-4 mb-10">
            <v-col
              cols="6"
              v-for="tag in castTags[categry.cid]"
              :key="tag.id"
              class="ma-0 pa-1 evaluation_tag"
            >
              <input
                :id="tag.id"
                type="checkbox"
                :value="tag.id"
                v-model="selectTags"
                v-on:click="clickTag"
                v-on:change="selectTag(tag.id)"
              />
              <label :for="tag.id">
                <div
                  class="evaluation_tag_select_button"
                  v-text="tag.content"
                  v-ripple
                ></div>
              </label>
            </v-col>
          </v-row>
        </div>
      </section>
      <!-- キャストへのマイナスな印象 -->
      <section class="pa-4 pb-0" v-if="reservation['is_cast_evaluation'] === false">
        <h2 class="cast-report__header" style="font-size: var(--read-font-size-l);">
          <IconMedalPink class="mr-2" />{{ $t('text_D7qy') }}<span>{{ $t('text_F7rQ') }}</span>
        </h2>
        <p class="ma-0 pb-2 cast-repaort_msg">
          {{ $t('text_zM5u') }}
        </p>
        <p class="title_annotation">{{ $t('text_G2tJ') }}</p>
        <v-row v-if="negativeTags.length != 0" class="ma-0 pa-0 py-3">
          <v-col
            cols="6"
            v-for="tag in castNegativeTags"
            :key="tag.id"
            class="ma-0 pa-1 evaluation_tag"
          >
            <input
              :id="`negative_tag_${tag.id}`"
              type="checkbox"
              :value="tag.id"
              v-model="selectNegativeTags"
              v-on:change="selectNegativeTag(tag.id)"
            />
            <label :for="`negative_tag_${tag.id}`">
              <div
                class="evaluation_tag_select_button"
                v-text="tag.content"
                v-ripple
              ></div>
            </label>
          </v-col>
        </v-row>
        <p v-if="selectNegativeTags.includes(24)" class="text-msg2 ma-0 text-left">{{ $t('text_Aw6q') }}</p>
      </section>

      <!-- プライベートメモ -->
      <section class="pa-4" v-if="reservation.m_id || reservation.c_m_id">
        <h2 class="mt-2 cast-report__header-optional">
          <IconMedalPink class="mr-2" />{{ $t('text_j5JP') }}
        </h2>
        <v-card class="cast-report__wrapper pb-5">
          <h3 class="mt-4 cast-report__header-optional">
            {{ $t('text_z2ZT') }}<span>{{ $t('text_a9FN') }}</span>
          </h3>
          <div class="pa-0 cast-repaort_msg">
            {{ $t('text_F2uA') }}
          </div>
          <div class="pa-0 mb-2 cast-repaort_msg text-right">{{ inputCastFreeMemoCount }}/{{ maxCastFreeMemoCount }}</div>
          <v-textarea outlined v-model="castFreeMemo" :hide-details="true" rows="5" style="background-color: #fff;" />
        </v-card>
      </section>

      <!-- 送信ボタン -->
      <section class="pa-4 pt-0">
        <div class="my-8 text-center">
          <v-btn
            class="my-2 px-4"
            color="primary"
            block
            style="font-size: var(--read-font-size-m)"
            :disabled="!postCheck || textCountCheckMessage !== ''"
            v-on:click="confirmDialog1 = true"
          >
            {{ $t('text_Tn8h') }}
          </v-btn>
          <!-- 必須項目未選択時のメッセージ -->
          <div
            v-if="!postCheck"
            style="font-size: var(--read-font-size-s); color: var(--main-color)"
          >
            {{ $t('text_Nv4T') }}
          </div>
          <!-- 各テキストエリアの文字数制限メッセージ -->
          <div
            v-if="textCountCheckMessage !== ''"
            v-html="textCountCheckMessage"
            style="font-size: var(--read-font-size-s); color: var(--main-color)"
          >
          </div>
        </div>
      </section>
    </v-card>
    <!-- クチコミ modal -->
    <v-dialog
      v-model="helpDialog"
      max-width="400"
      transition="dialog-bottom-transition"
      style="z-index: 9999"
    >
      <v-card class="pa-3 text-center" style="font-weight: bold">
        <p class="pa-3 ma-0 primary--text text-left">{{ $t('text_E8rk') }}</p>
        <div class="pa-3 text-left">
          {{ $t('text_U7hJ') }}
          {{ $t('text_Xb5W') }}<br><br>
          {{ $t('text_b8Ar') }}<br>
          {{ $t('text_Jg4z') }}<br>
          {{ $t('text_De7x') }}<br>
          {{ $t('text_k8HY') }}<br>
          {{ $t('text_L9i2') }}<br><br>
          {{ $t('text_q9Tz') }}
        </div>
        <div class="px-3">
          <v-btn
            block
            small
            elevation="0"
            v-on:click="helpDialog = !helpDialog"
            style="color: var(--main-color); font-weight: bold"
            >{{ $t('close') }}</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
    <!-- Error Modal -->
    <v-dialog
      v-model="errorDialog"
      max-width="350"
      persistent
      no-click-animation
      transition="dialog-bottom-transition"
      style="z-index: 9999"
    >
      <v-card
        class="pa-4"
        color="primary"
        style="color: #fff; font-size: var(--read-font-size-m)"
      >
        <v-row>
          <v-col class="pa-2" cols="2">
            <v-layout column justify-center fill-height>
              <v-container class="pa-0 text-center">
                <v-icon color="yellow" large>mdi-alert</v-icon>
              </v-container>
            </v-layout>
          </v-col>
          <v-col class="pa-1" cols="10">
            <div>{{ $t('text_Tw8u') }}</div>
            <div style="font-size: var(--read-font-size-s)">
              {{ $t('text_W4w2') }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <v-btn
              small
              class="mt-22"
              elevation="0"
              v-on:click="closeErrorDialogToTop"
              >{{ $t('close') }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="limitOverDialog"
      max-width="350"
      persistent
      no-click-animation
      transition="dialog-bottom-transition"
      style="z-index: 9999"
    >
      <v-card
        class="pa-4"
        color="primary"
        style="color: #fff; font-size: var(--read-font-size-m)"
      >
        <v-row>
          <v-col class="pa-2" cols="2">
            <v-layout column justify-center fill-height>
              <v-container class="pa-0 text-center">
                <v-icon color="yellow" large>mdi-alert</v-icon>
              </v-container>
            </v-layout>
          </v-col>
          <v-col class="pa-1" cols="10">
            <div>{{ selectTagsLimit + 1 }}{{ $t('text_C4qJ') }}</div>
          </v-col>
        </v-row>
        <div class="text-right">
          <v-btn
            small
            class="mt-8"
            elevation="0"
            v-on:click="limitOverDialog = false"
            >{{ $t('close') }}</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmDialog1" persistent max-width="450">
      <v-card>
        <v-card-title class="subtitle-1">
          <h3>{{ $t('text_t6GA') }}</h3>
        </v-card-title>
        <!-- <v-card-text>キャスト評価を送信してもよろしいですか？</v-card-text> -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            x-large
            @click="confirmDialog1 = false"
          >
            {{ $t('text_tW2Y') }}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            x-large
            @click="postEvaluation()"
            :disabled="btnDisabled"
          >
            {{ $t('text_Tn8h') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmDialog2" persistent max-width="450">
      <v-card color="#ffffee">
        <v-card-title class="subtitle-1">
          <h3>{{ $t('text_Hd3Z') }}</h3>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            x-large
            @click="confirmDialog2 = false"
          >
            {{ $t('text_tW2Y') }}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            x-large
            :disabled="btnDisabled"
            @click="postNoEvaluation()"
          >
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="savedEvaluationDialog" persistent max-width="450">
      <v-card color="#ffffee">
        <v-card-title class="subtitle-1">
          <h3>{{ $t('text_Kc7Z') }}</h3>
        </v-card-title>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import noimage_m from '~/assets/img/noimage_m.png';
import ngimage_m from '~/assets/img/ngimage_m.png';
import IconEx from '~/assets/img/icon-ex.svg';
import IconLike from '~/assets/img/icon-like.svg';
import IconOmg from '~/assets/img/icon-omg.svg';
import IconRip from '~/assets/img/icon-rip.svg';
import IconHeart from '~/assets/img/icon-heart2.svg';
import IconHand from '~/assets/img/icon-hand.svg';
import IconMedalPink from '~/assets/img/icon-medal4.svg';
import IconMedalWhite from '~/assets/img/icon-medal5.svg';

import _ from 'lodash';

export default {
  auth: false,
  components: {
    IconEx,
    IconLike,
    IconOmg,
    IconRip,
    IconHeart,
    IconHand,
    IconMedalPink,
    IconMedalWhite,
  },
  props: {
    reservationId: { type: Number, default: null },
    customerId: { type: Number, default: null },
    siteMemberId: { type: Number, default: null },
  },
  data: () => ({
    noimage_m: noimage_m,
    ngimage_m: ngimage_m,
    IconEx: IconEx,
    IconLike: IconLike,
    IconOmg: IconOmg,
    IconMedalPink: IconMedalPink,
    IconMedalWhite: IconMedalWhite,
    postflag: false,
    loading: true,
    reservation: [], // キャスト情報
    helpDialog: false, // クチコミについて
    errorDialog: false,
    errorContents: false,
    limitOverDialog: false,
    confirmDialog1: false,
    confirmDialog2: false,
    savedEvaluationDialog: false,
    overallSatisfaction: 3, // 総合満足度
    treatmentSatisfaction: 3, // 施術満足度
    starRatings: [
      { category: 1, point: 3 },
      { category: 2, point: 3 },
      { category: 3, point: 3 },
      { category: 4, point: 3 },
      { category: 5, point: 3 },
    ],
    tags: [], // キャストタグ一覧
    negativeTags: [], // キャストタグ一覧
    selectTags: [], // 選択したキャストタグ一覧
    selectTagsLimit: 8, // 選択可能数
    selectNegativeTags: [], // 選択したキャストタグ一覧

    /*
    * クチコミ(旧：評価コメント)
    */
    comment: '', // クチコミ(旧：評価コメント)
    inputCommentCount: 0, // クチコミの文字数
    maxCommentCount: 600, // クチコミの最大文字数
    commentIntroductionFlag: true, // クチコミの紹介フラグ

    /*
    * プライベートメモ
    */
    castFreeMemo: '', // キャストフリーメモ
    inputCastFreeMemoCount: 0, // キャストフリーメモの文字数
    maxCastFreeMemoCount: 600, // キャストフリーメモの最大文字数
    noEvaluation: false, // 評価しないフラグ（2回目以降）
    btnDisabled: false,
  }),
  computed: {
    castTags: function() {
      return {
        // 外見
         1 : [
          { id: 1,  content: this.$t('cast_tags.1.name') , key: this.$t('cast_tags.1.key') , category: this.$t('cast_tags.1.category') , sort_no: this.$t('cast_tags.1.sort_no')  }, // かわいい
          { id: 2,  content: this.$t('cast_tags.2.name') , key: this.$t('cast_tags.2.key') , category: this.$t('cast_tags.2.category') , sort_no: this.$t('cast_tags.2.sort_no')  }, // きれい
          { id: 26, content: this.$t('cast_tags.26.name'), key: this.$t('cast_tags.26.key'), category: this.$t('cast_tags.26.category'), sort_no: this.$t('cast_tags.26.sort_no') }, // 清楚
          { id: 23, content: this.$t('cast_tags.23.name'), key: this.$t('cast_tags.23.key'), category: this.$t('cast_tags.23.category'), sort_no: this.$t('cast_tags.23.sort_no') }, // ハーフ顔
          { id: 25, content: this.$t('cast_tags.25.name'), key: this.$t('cast_tags.25.key'), category: this.$t('cast_tags.25.category'), sort_no: this.$t('cast_tags.25.sort_no') }, // 童顔
          { id: 48, content: this.$t('cast_tags.48.name'), key: this.$t('cast_tags.48.key'), category: this.$t('cast_tags.48.category'), sort_no: this.$t('cast_tags.48.sort_no') }, // 歯がきれい
          { id: 28, content: this.$t('cast_tags.28.name'), key: this.$t('cast_tags.28.key'), category: this.$t('cast_tags.28.category'), sort_no: this.$t('cast_tags.28.sort_no') }, // 色気たっぷり
          { id: 49, content: this.$t('cast_tags.49.name'), key: this.$t('cast_tags.49.key'), category: this.$t('cast_tags.49.category'), sort_no: this.$t('cast_tags.49.sort_no') }, // 美肌
          { id: 19, content: this.$t('cast_tags.19.name'), key: this.$t('cast_tags.19.key'), category: this.$t('cast_tags.19.category'), sort_no: this.$t('cast_tags.19.sort_no') }, // 美白
          { id: 6,  content: this.$t('cast_tags.6.name') , key: this.$t('cast_tags.6.key') , category: this.$t('cast_tags.6.category') , sort_no: this.$t('cast_tags.6.sort_no')  }, // スレンダー
          { id: 8,  content: this.$t('cast_tags.8.name') , key: this.$t('cast_tags.8.key') , category: this.$t('cast_tags.8.category') , sort_no: this.$t('cast_tags.8.sort_no')  }, // グラマー
          { id: 17, content: this.$t('cast_tags.17.name'), key: this.$t('cast_tags.17.key'), category: this.$t('cast_tags.17.category'), sort_no: this.$t('cast_tags.17.sort_no') }, // 美乳
          { id: 29, content: this.$t('cast_tags.29.name'), key: this.$t('cast_tags.29.key'), category: this.$t('cast_tags.29.category'), sort_no: this.$t('cast_tags.29.sort_no') }, // 綺麗なくびれ
          { id: 30, content: this.$t('cast_tags.30.name'), key: this.$t('cast_tags.30.key'), category: this.$t('cast_tags.30.category'), sort_no: this.$t('cast_tags.30.sort_no') }, // 美尻
          { id: 18, content: this.$t('cast_tags.18.name'), key: this.$t('cast_tags.18.key'), category: this.$t('cast_tags.18.category'), sort_no: this.$t('cast_tags.18.sort_no') }, // 美脚
          { id: 3,  content: this.$t('cast_tags.3.name') , key: this.$t('cast_tags.3.key') , category: this.$t('cast_tags.3.category') , sort_no: this.$t('cast_tags.3.sort_no')  }, // お姉さま
          { id: 39, content: this.$t('cast_tags.39.name'), key: this.$t('cast_tags.39.key'), category: this.$t('cast_tags.39.category'), sort_no: this.$t('cast_tags.39.sort_no') }, // OL系
          { id: 40, content: this.$t('cast_tags.40.name'), key: this.$t('cast_tags.40.key'), category: this.$t('cast_tags.40.category'), sort_no: this.$t('cast_tags.40.sort_no') }, // 人妻系
          { id: 38, content: this.$t('cast_tags.38.name'), key: this.$t('cast_tags.38.key'), category: this.$t('cast_tags.38.category'), sort_no: this.$t('cast_tags.38.sort_no') }, // ロリ系
          ],
        2 : [
          { id: 13, content: this.$t('cast_tags.13.name'), key: this.$t('cast_tags.13.key'), category: this.$t('cast_tags.13.category'), sort_no: this.$t('cast_tags.13.sort_no') }, // 明るい
          { id: 14, content: this.$t('cast_tags.14.name'), key: this.$t('cast_tags.14.key'), category: this.$t('cast_tags.14.category'), sort_no: this.$t('cast_tags.14.sort_no') }, // おしとやか
          { id: 15, content: this.$t('cast_tags.15.name'), key: this.$t('cast_tags.15.key'), category: this.$t('cast_tags.15.category'), sort_no: this.$t('cast_tags.15.sort_no') }, // エロい
          { id: 16, content: this.$t('cast_tags.16.name'), key: this.$t('cast_tags.16.key'), category: this.$t('cast_tags.16.category'), sort_no: this.$t('cast_tags.16.sort_no') }, // 癒し系
          { id: 34, content: this.$t('cast_tags.34.name'), key: this.$t('cast_tags.34.key'), category: this.$t('cast_tags.34.category'), sort_no: this.$t('cast_tags.34.sort_no') }, // 愛嬌抜群
          { id: 50, content: this.$t('cast_tags.50.name'), key: this.$t('cast_tags.50.key'), category: this.$t('cast_tags.50.category'), sort_no: this.$t('cast_tags.50.sort_no') }, // 話し上手
          { id: 35, content: this.$t('cast_tags.35.name'), key: this.$t('cast_tags.35.key'), category: this.$t('cast_tags.35.category'), sort_no: this.$t('cast_tags.35.sort_no') }, // 聡明
          { id: 36, content: this.$t('cast_tags.36.name'), key: this.$t('cast_tags.36.key'), category: this.$t('cast_tags.36.category'), sort_no: this.$t('cast_tags.36.sort_no') }, // おっとり
          { id: 37, content: this.$t('cast_tags.37.name'), key: this.$t('cast_tags.37.key'), category: this.$t('cast_tags.37.category'), sort_no: this.$t('cast_tags.37.sort_no') }, // 恥ずかしがり
        ],
        3 : [
          { id: 4,  content: this.$t('cast_tags.4.name') , key: this.$t('cast_tags.4.key') , category: this.$t('cast_tags.4.category') , sort_no: this.$t('cast_tags.4.sort_no')  }, // 素人感
          { id: 9,  content: this.$t('cast_tags.9.name') , key: this.$t('cast_tags.9.key') , category: this.$t('cast_tags.9.category') , sort_no: this.$t('cast_tags.9.sort_no')  }, // テクニシャン
          { id: 10, content: this.$t('cast_tags.10.name'), key: this.$t('cast_tags.10.key'), category: this.$t('cast_tags.10.category'), sort_no: this.$t('cast_tags.10.sort_no') }, // 雰囲気作り
          { id: 32, content: this.$t('cast_tags.32.name'), key: this.$t('cast_tags.32.key'), category: this.$t('cast_tags.32.category'), sort_no: this.$t('cast_tags.32.sort_no') }, // 恋人気分
          { id: 12, content: this.$t('cast_tags.12.name'), key: this.$t('cast_tags.12.key'), category: this.$t('cast_tags.12.category'), sort_no: this.$t('cast_tags.12.sort_no') }, // 受身好き
          { id: 11, content: this.$t('cast_tags.11.name'), key: this.$t('cast_tags.11.key'), category: this.$t('cast_tags.11.category'), sort_no: this.$t('cast_tags.11.sort_no') }, // 責め好き
          { id: 20, content: this.$t('cast_tags.20.name'), key: this.$t('cast_tags.20.key'), category: this.$t('cast_tags.20.category'), sort_no: this.$t('cast_tags.20.sort_no') }, // 感度抜群
          { id: 33, content: this.$t('cast_tags.33.name'), key: this.$t('cast_tags.33.key'), category: this.$t('cast_tags.33.category'), sort_no: this.$t('cast_tags.33.sort_no') }, // 潮吹き体質
          { id: 51, content: this.$t('cast_tags.51.name'), key: this.$t('cast_tags.51.key'), category: this.$t('cast_tags.51.category'), sort_no: this.$t('cast_tags.51.sort_no') }, // キス上手
          { id: 52, content: this.$t('cast_tags.52.name'), key: this.$t('cast_tags.52.key'), category: this.$t('cast_tags.52.category'), sort_no: this.$t('cast_tags.52.sort_no') }, // 手コキ上手
          { id: 53, content: this.$t('cast_tags.53.name'), key: this.$t('cast_tags.53.key'), category: this.$t('cast_tags.53.category'), sort_no: this.$t('cast_tags.53.sort_no') }, // フェラ上手
          { id: 54, content: this.$t('cast_tags.54.name'), key: this.$t('cast_tags.54.key'), category: this.$t('cast_tags.54.category'), sort_no: this.$t('cast_tags.54.sort_no') }, // スマタ上手
          { id: 21, content: this.$t('cast_tags.21.name'), key: this.$t('cast_tags.21.key'), category: this.$t('cast_tags.21.category'), sort_no: this.$t('cast_tags.21.sort_no') }, // ドM
          { id: 22, content: this.$t('cast_tags.22.name'), key: this.$t('cast_tags.22.key'), category: this.$t('cast_tags.22.category'), sort_no: this.$t('cast_tags.22.sort_no') }, // ドS
        ],
        99 : [
          { id: 43, content: this.$t('cast_tags.43.name'), key: this.$t('cast_tags.43.key'), category: this.$t('cast_tags.43.category'), sort_no: this.$t('cast_tags.43.sort_no') }, // ドS
        ]
      };
    },
    //  キャストへのマイナスな印象
    castNegativeTags: function() {
      return [
        { id: 1,   content: this.$t('cast_negative_tags.1.name') ,  key: this.$t('cast_negative_tags.1.key'),  sort_no: this.$t('cast_negative_tags.1.sort_no')   }, // 何もない
        { id: 2,   content: this.$t('cast_negative_tags.2.name') ,  key: this.$t('cast_negative_tags.2.key'),  sort_no: this.$t('cast_negative_tags.2.sort_no')   }, // 写真と違う
        { id: 3,   content: this.$t('cast_negative_tags.3.name') ,  key: this.$t('cast_negative_tags.3.key'),  sort_no: this.$t('cast_negative_tags.3.sort_no')   }, // スリーサイズが違う
        { id: 4,   content: this.$t('cast_negative_tags.4.name') ,  key: this.$t('cast_negative_tags.4.key'),  sort_no: this.$t('cast_negative_tags.4.sort_no')   }, // 太っている
        { id: 5,   content: this.$t('cast_negative_tags.5.name') ,  key: this.$t('cast_negative_tags.5.key'),  sort_no: this.$t('cast_negative_tags.5.sort_no')   }, // タバコ臭い
        { id: 6,   content: this.$t('cast_negative_tags.6.name') ,  key: this.$t('cast_negative_tags.6.key'),  sort_no: this.$t('cast_negative_tags.6.sort_no')   }, // 口臭が強い
        { id: 7,   content: this.$t('cast_negative_tags.7.name') ,  key: this.$t('cast_negative_tags.7.key'),  sort_no: this.$t('cast_negative_tags.7.sort_no')   }, // 体臭が強い
        { id: 8,   content: this.$t('cast_negative_tags.8.name') ,  key: this.$t('cast_negative_tags.8.key'),  sort_no: this.$t('cast_negative_tags.8.sort_no')   }, // アソコが臭い
        { id: 9,   content: this.$t('cast_negative_tags.9.name') ,  key: this.$t('cast_negative_tags.9.key'),  sort_no: this.$t('cast_negative_tags.9.sort_no')   }, // 体毛が濃い
        { id: 10,  content: this.$t('cast_negative_tags.10.name') , key: this.$t('cast_negative_tags.10.key'), sort_no: this.$t('cast_negative_tags.10.sort_no')  }, // テクニックがない
        { id: 11,  content: this.$t('cast_negative_tags.11.name') , key: this.$t('cast_negative_tags.11.key'), sort_no: this.$t('cast_negative_tags.11.sort_no')  }, // 雰囲気作りが下手
        { id: 12,  content: this.$t('cast_negative_tags.12.name') , key: this.$t('cast_negative_tags.12.key'), sort_no: this.$t('cast_negative_tags.12.sort_no')  }, // マグロ
        { id: 13,  content: this.$t('cast_negative_tags.13.name') , key: this.$t('cast_negative_tags.13.key'), sort_no: this.$t('cast_negative_tags.13.sort_no')  }, // すぐ痛がる
        { id: 14,  content: this.$t('cast_negative_tags.14.name') , key: this.$t('cast_negative_tags.14.key'), sort_no: this.$t('cast_negative_tags.14.sort_no')  }, // くすぐったがる
        { id: 15,  content: this.$t('cast_negative_tags.15.name') , key: this.$t('cast_negative_tags.15.key'), sort_no: this.$t('cast_negative_tags.15.sort_no')  }, // 作業っぽい
        { id: 16,  content: this.$t('cast_negative_tags.16.name') , key: this.$t('cast_negative_tags.16.key'), sort_no: this.$t('cast_negative_tags.16.sort_no')  }, // 心がこもってない
        { id: 17,  content: this.$t('cast_negative_tags.17.name') , key: this.$t('cast_negative_tags.17.key'), sort_no: this.$t('cast_negative_tags.17.sort_no')  }, // 暗い
        { id: 18,  content: this.$t('cast_negative_tags.18.name') , key: this.$t('cast_negative_tags.18.key'), sort_no: this.$t('cast_negative_tags.18.sort_no')  }, // 無愛想
        { id: 19,  content: this.$t('cast_negative_tags.19.name') , key: this.$t('cast_negative_tags.19.key'), sort_no: this.$t('cast_negative_tags.19.sort_no')  }, // 言葉遣いが悪い
        { id: 20,  content: this.$t('cast_negative_tags.20.name') , key: this.$t('cast_negative_tags.20.key'), sort_no: this.$t('cast_negative_tags.20.sort_no')  }, // 会話が長い
        { id: 21,  content: this.$t('cast_negative_tags.21.name') , key: this.$t('cast_negative_tags.21.key'), sort_no: this.$t('cast_negative_tags.21.sort_no')  }, // 肌荒れが目立つ
        { id: 22,  content: this.$t('cast_negative_tags.22.name') , key: this.$t('cast_negative_tags.22.key'), sort_no: this.$t('cast_negative_tags.22.sort_no')  }, // ファッションが悪い
        { id: 23,  content: this.$t('cast_negative_tags.23.name') , key: this.$t('cast_negative_tags.23.key'), sort_no: this.$t('cast_negative_tags.23.sort_no')  }, // 下着が悪い
        { id: 24,  content: this.$t('cast_negative_tags.24.name') , key: this.$t('cast_negative_tags.24.key'), sort_no: this.$t('cast_negative_tags.24.sort_no')  }, // その他
      ]
    },
    starRatingLabels: function() {
      return [
        { label: this.$t('starRatings.looks.label') },
        { label: this.$t('starRatings.style.label') },
        { label: this.$t('starRatings.play.label') },
        { label: this.$t('starRatings.erotic.label') },
        { label: this.$t('starRatings.personalityConsideration.label') },
    ]
    },
    starRatingMessages: function() {
      return {
          'overallSatisfaction' : {
          1: this.$t('starRatings.overallSatisfaction.selectedValue.1'),
          2: this.$t('starRatings.overallSatisfaction.selectedValue.2'),
          3: this.$t('starRatings.overallSatisfaction.selectedValue.3'),
          4: this.$t('starRatings.overallSatisfaction.selectedValue.4'),
          5: this.$t('starRatings.overallSatisfaction.selectedValue.5'),
        },
        'treatmentSatisfaction' : {
          1: this.$t('starRatings.treatmentSatisfaction.selectedValue.1'),
          2: this.$t('starRatings.treatmentSatisfaction.selectedValue.2'),
          3: this.$t('starRatings.treatmentSatisfaction.selectedValue.3'),
          4: this.$t('starRatings.treatmentSatisfaction.selectedValue.4'),
          5: this.$t('starRatings.treatmentSatisfaction.selectedValue.5'),
        },
        // ルックス
        'category1' : {
          1: this.$t('starRatings.looks.selectedValue.1'),
          2: this.$t('starRatings.looks.selectedValue.2'),
          3: this.$t('starRatings.looks.selectedValue.3'),
          4: this.$t('starRatings.looks.selectedValue.4'),
          5: this.$t('starRatings.looks.selectedValue.5'),
        },
        // スタイル
        'category2' : {
          1: this.$t('starRatings.style.selectedValue.1'),
          2: this.$t('starRatings.style.selectedValue.2'),
          3: this.$t('starRatings.style.selectedValue.3'),
          4: this.$t('starRatings.style.selectedValue.4'),
          5: this.$t('starRatings.style.selectedValue.5'),
        },
        // プレイ
        'category3' : {
          1: this.$t('starRatings.play.selectedValue.1'),
          2: this.$t('starRatings.play.selectedValue.2'),
          3: this.$t('starRatings.play.selectedValue.3'),
          4: this.$t('starRatings.play.selectedValue.4'),
          5: this.$t('starRatings.play.selectedValue.5'),
        },
        // エロさ
        'category4' : {
          1: this.$t('starRatings.erotic.selectedValue.1'),
          2: this.$t('starRatings.erotic.selectedValue.2'),
          3: this.$t('starRatings.erotic.selectedValue.3'),
          4: this.$t('starRatings.erotic.selectedValue.4'),
          5: this.$t('starRatings.erotic.selectedValue.5'),
        },
        // 性格・気遣い
        'category5' : {
          1: this.$t('starRatings.personalityConsideration.selectedValue.1'),
          2: this.$t('starRatings.personalityConsideration.selectedValue.2'),
          3: this.$t('starRatings.personalityConsideration.selectedValue.3'),
          4: this.$t('starRatings.personalityConsideration.selectedValue.4'),
          5: this.$t('starRatings.personalityConsideration.selectedValue.5'),
        },
      }
    },
    categoryNameList2: function() {
      return [
        { cid: 1,  label: this.$t('cast_tags.looks'),     icon: IconRip   },
        { cid: 2,  label: this.$t('cast_tags.innerSide'), icon: IconHeart },
        { cid: 3,  label: this.$t('cast_tags.service'),   icon: IconHand  },
        { cid: 99, label: this.$t('cast_tags.nothing'),   icon: IconOmg   },
      ]
    },
    // 延長時間
    extensionTime() {
      let minute = 0;
      for (let i = 0; i < this.reservation.service.length; i++) {
        minute += this.reservation.service[i].minute;
      }
      return minute;
    },
    /**
     * 必須項目の入力チェック(trueの場合送信可能状態)
     */
    postCheck() {
      //未評価
      if (this.reservation['is_cast_evaluation'] === false) {
        let flag =
          this.selectTags.length > 0 &&
          this.selectTags.length <= this.selectTagsLimit &&
          this.selectNegativeTags.length > 0;
        return flag;
      }
      // 評価２回目以降
      else if (this.reservation['is_cast_evaluation'] === true) {
        let flag = this.comment.length > 0;
        return flag;
      }
    },
    /*
    * 文字数カウント判定
    */
    textCountCheckMessage() {
      let textCountCheckMessage = '';
      if (this.inputCommentCount > this.maxCommentCount) textCountCheckMessage += '※クチコミは600文字以下で入力してください。<br>';
      if (this.inputCastFreeMemoCount > this.maxCastFreeMemoCount) textCountCheckMessage += '※プライベートメモは600文字以下で入力してください。';
      return textCountCheckMessage;
    },
    commentIntroductionNgFlag() {
      // クチコミの紹介NGフラグ
      return !this.commentIntroductionFlag;
    },
  },
  filters: {
    dispayCourseName: function (item) {
      //「web予約名」を表示する。未定義もしくはnull・空の場合はサービス名を表示
      if (item.course[0] && item.course[0].offical_site_display_name) {
        return item.course[0].offical_site_display_name;
      } else {
        return item.course_name;
      }
    },
  },
  created: async function () {
    if (/Mobi|Android/i.test(navigator.userAgent)) {
        document.body.style.overflow = 'hidden';
        document.body.style.touchAction = 'none';
      }
    if (this.reservationId && this.customerId) {
      // 予約詳細を取得
      await this.getAjaxReservation();
      // キャストタグを取得
      this.getAjaxTags();
      // 7秒以上読み込めなかったら閉じるボタン表示
      this.wrapSetTimeout(() => {
        if (this.loading) {
          this.errorContents = true;
        }
      }, 7000)
    } else {
      this.errorDialog = true;
    }
  },
  beforeDestroy: function () {
    this.clearAllTimeouts();
  },
  mounted: function () {},
  watch: {
    comment: function () {
      let replaceComment = (this.comment).replaceAll(/\s+/g, '');
      this.inputCommentCount = replaceComment.length;
    },
    castFreeMemo: function () {
      let replaceComment = (this.castFreeMemo).replaceAll(/\s+/g, '');
      this.inputCastFreeMemoCount = replaceComment.length;
    },
  },
  methods: {
    /**
     * ajax: 予約情報取得
     */
    async getAjaxReservation() {
      await this.$axios
        .post('public/get-customer-reservation', {
          r: this.reservationId,
          c: this.customerId,
        })
        .then((response) => {
          if (response.data.id) {
            this.reservation = response.data;
            this.loading = false;
          } else {
            // 予約情報を取得出来なかったらエラーダイアログを表示
            this.errorDialog = true;
          }
        })
        .catch((error) => {
          // 予約情報を取得出来なかったらエラーダイアログを表示
          this.errorDialog = true;
        });
    },
    /**
     * ajax: キャストタグ取得
     */
    getAjaxTags() {
      this.$axios
        .post('public/cast-tags', {
          mode: 'with_negative_tags',
          business_type_id: this.reservation.cast.shop.business_type_id,
        })
        .then((response) => {
          if (response.data) {
            this.tags = response.data.tags;
            this.negativeTags = response.data.negative_tags;
          } else {
            // 予約情報を取得出来なかったらエラーダイアログを表示
            this.errorDialog = true;
          }
        })
        .catch((error) => {
          // 予約情報を取得出来なかったらエラーダイアログを表示
          this.errorDialog = true;
        });
    },
    /**
     * キャスト評価とホテル評価を保存する
     */
    postEvaluation() {
      this.btnDisabled = true;
      this.$store.dispatch('loading/post', true);
      const stars = _.map(this.starRatings, (v) => {
        return v.point;
      });
      
      let commentIntroductionNgFlag =
        this.commentIntroductionNgFlag === false ? 0 : 1;
      //ただしdisplay_type=3（非公開出勤）だった場合は自動的に1（非公開）とする
      if (this.reservation.cast_schedule.display_type === 3) commentIntroductionNgFlag = 1;
      const guidanceNgFlag = this.guidanceNgFlag === true ? 1 : 0;
      const shopIntroductionNgFlag =
        this.shopIntroductionNgFlag === false ? 0 : 1;
      const treatmentSatisfaction =
        this.reservation.cast.shop.business_type_id === 2
          ? this.treatmentSatisfaction
          : '';
      const postData =
        this.reservation['is_cast_evaluation'] === false
          ? // 初回評価
            {
              reservation_id: this.reservationId,
              customer_id: this.customerId,
              // キャストへの評価
              overall_satisfaction: this.overallSatisfaction,
              treatment_satisfaction: treatmentSatisfaction,
              star_ratings: stars,
              // クチコミ
              comment: this.comment,
              comment_introduction_ng_flag: commentIntroductionNgFlag,
              // プラスな印象
              impressions: this.selectTags,
              // マイナスな印象
              negative_impressions: this.selectNegativeTags,
              guidance_ng_flag: guidanceNgFlag,
              // ホテルへの評価
              shop_introduction_ng_flag: shopIntroductionNgFlag,
              // 他
              cast_free_memo: this.castFreeMemo,
              no_evaluation_flag: 0, // 評価する
            }
          : // ２回め以降評価
            {
              reservation_id: this.reservationId,
              customer_id: this.customerId,
              // キャストへの評価
              overall_satisfaction: this.overallSatisfaction,
              treatment_satisfaction: treatmentSatisfaction,
              star_ratings: ['', '', '', '', ''],
              // クチコミ
              comment: this.comment,
              comment_introduction_ng_flag: commentIntroductionNgFlag,
              // プラスな印象
              impressions: [],
              // マイナスな印象
              negative_impressions: [],
              guidance_ng_flag: guidanceNgFlag,
              // ホテルへの評価
              shop_introduction_ng_flag: shopIntroductionNgFlag,
              // 他
              cast_free_memo: this.castFreeMemo,
              no_evaluation_flag: 0, // 評価する
            };
      this.castEvaluationSave(postData);
    },
    /**
     * キャスト評価を閉じる
     */
    postNoEvaluation() {
      this.btnDisabled = true;
      this.$store.dispatch('loading/post', true);
      const postData = {
        reservation_id: this.reservationId,
        customer_id: this.customerId,
        // キャストへの評価
        overall_satisfaction: '',
        treatment_satisfaction: '',
        star_ratings: ['', '', '', '', ''],
        // クチコミ
        comment: '',
        comment_introduction_ng_flag: 1,
        // プラスな印象
        impressions: [],
        // マイナスな印象
        negative_impressions: [],
        negative_comment: '',
        guidance_ng_flag: 1,
        // 店舗への評価
        improvement_comment: '',
        // システムへの評価
        system_comment: '',
        // 他
        cast_free_memo: '',
        no_evaluation_flag: 1, // キャスト評価を閉じる

      };
      this.castEvaluationSave(postData);
    },
    /**
     * save
     */
    castEvaluationSave(postData) {
      this.$axios
        .post('public/save-cast-evaluation2', postData, {})
        .then((response) => {
          this.postflag = true;
          this.$store.dispatch('loading/post', false);
          this.$store.dispatch('notification/castEvaluation', null);
          this.confirmDialog1 = false;
          this.confirmDialog2 = false;
          document.body.style.overflow = '';
          document.body.style.touchAction = '';
          if (!postData.no_evaluation_flag) {
            this.savedEvaluationDialog = true;
          }
          setTimeout(() => {
            if (!postData.no_evaluation_flag) {
            this.savedEvaluationDialog = false;
            }
            this.$router.push(`/${this.$i18n.locale}`);
            this.$emit('closeDialog');
          }, 2000);
        })
        .catch((error) => {});
    },
    /**
     * ★の選択と吹き出しの位置の連動
     */
     ratingStyle(rating) {
      const baseRight = 205; // 三角形の初期位置
      const margin = 35; // 星の間のマージン
      const position = baseRight - (rating * margin);
      return {
        right: `${position}px`,
      };
    },
    clickTag(e) {
      const nothingId = _.flatMapDeep(this.tags).find(
        (tag) => tag.content === '何もない'
      ).id;
      if (!e.target.checked || e.target.value == nothingId) return;
      if (this.selectTags.length >= this.selectTagsLimit) {
        this.limitOverDialog = true;
        e.preventDefault();
      }
    },
    selectTag(id) {
      const nothingId = _.flatMapDeep(this.tags).find(
        (tag) => tag.content === '何もない'
      ).id;
      this.selectTags = this.selectTags.filter((i) =>
        id === nothingId ? i === nothingId : i !== nothingId
      );
    },
    selectNegativeTag(id) {
      const nothingId = _.flatMapDeep(this.negativeTags).find(
        (tag) => tag.content === '何もない'
      ).id;
      this.selectNegativeTags = this.selectNegativeTags.filter((i) =>
        id === nothingId ? i === nothingId : i !== nothingId
      );
    },
    defaultImage(thumbnailUrl, defaultImageName) {
      if (!thumbnailUrl) {
        if (defaultImageName.search('ng') == -1) {
          return this.ngimage_m;
        } else {
          return this.noimage_m;
        }
      } else {
        return thumbnailUrl;
      }
    },
    closeErrorDialogToTop() {
      this.errorDialog = false;
      window.location.href = '/';
    },
  },
};
</script>


<style scoped>
.help-dialog-header {
  background: var(--sub-color);
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  height: 40px;
}

.icon-question {
  width: 25px;
  height:25px;
  fill: #fff;
}


.evaluation_tag {
  display: inline-block;
}
.evaluation_tag input[type='checkbox'] {
  display: none;
}
.evaluation_tag
  input[type='checkbox']:checked
  ~ label
  .evaluation_tag_select_button {
  border: 1px solid var(--main-color);
  background-color: var(--main-color);
  color: rgb(245, 237, 237);
}
.evaluation_tag_select_button {
  font-size: var(--read-font-size-s);
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  padding: 2%;
  font-weight: bold;
  line-height: 3vh;
  color: var(--read-font-color-sub);
  white-space: nowrap;
  box-shadow: 0 1.5px 1.5px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 2px 1px -2px rgba(0, 0, 0, 0.2) !important;
}
.usr-evalutaion-header__msg {
  color: var(--read-font-color-primary);
  font-size: var(--read-font-size-s);
  line-height: 1.5;
  font-weight: bold;
}
.cast-detail__date {
  color: var(--main-color);
  font-size: var(--read-font-size-m);
  line-height: 1.5;
  font-weight: bold;
}
.cast-detail__name {
  color: var(--main-color);
  font-size: var(--read-font-size-xl);
  line-height: 1.5;
  font-weight: bold;
}
.cast-detail__honorific {
  color: var(--main-color);
  font-size: var(--read-font-size-m);
  line-height: 1.5;
  font-weight: bold;
}
.cast-call {
  height: 100%;
  line-height: 1.6;
  background: var(--main-bg-color);
  font-size: var(--read-font-size-s);
}
.cast-report__wrapper{
  background-color: var(--main-bg-color);
  margin: 20px 0;
  padding: 10px 20px;
}
.v-rating__title{
  font-weight: bold;
}
.v-rating__wrapper {
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  padding: 0 0 20px 0;
  .v-rating{
    padding: 0 20px 0 0;
  }
  .v-card__text {
    margin-top: 15px;
    position: relative;  /* 追加: relative positioning */
    flex-basis: 100%;
    font-size: var(--read-font-size-s);
    background: linear-gradient(0deg, #CF9F00, #DEBC4A);
    padding: 0 20px;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    color: #fff;
    right: 0;
    z-index: 2;
  }
  .triangle {
    position: absolute;
    top: 40px; /* 親要素の上にはみ出す位置 */
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 16px solid #DEBC4A; /* 吹き出しの色に合わせる */
    z-index: 1;
  }
}
.cast-report__header {
  display:flex;
  align-items: center;
  font-size: var(--read-font-size-xl);
  font-weight: bold;
  color: var(--main-color);
  margin: 33px 0 20px;
}
.cast-report__header span {
  color: #fff;
  text-align: center;
  font-size: 14px;
  padding: 3px;
  width: 75px;
  display: inline-block;
  background: var(--sub-color);
  margin-left: 7px;
  border-radius: 2px;
}
.cast-report__header-required {
  font-size: var(--read-font-size-m);
  font-weight: bold;
  color: var(--main-color);
}
.cast-report__header-optional {
  display:flex;
  align-items: center;
  font-size: var(--read-font-size-xl);
  font-weight: bold;
  color: var(--main-color);
  margin: 33px 0 10px;
}
.cast-report__header-optional span {
  border: var(--main-color) solid 2px;
  background-color: #fff;
  margin: 0 0 0 10px;
  border-radius: 2px;
  padding: 1px 5px;
  display: inline-block;
  font-size: var(--read-font-size-s);
}
.cast-report__header-sub{
  width: 100%;
  font-size: 14px;
  background-color: #F0F3F4;
  padding: 5px 15px;
  margin: 0 0 30px;
  display: flex;
  align-items: center;
}
.cast-repaort_good_call {
  color: var(--read-font-color-sub);
  font-size: var(--read-font-size-s);
  line-height: 1.5;
  font-weight: bold;
}
.cast-repaort_msg {
  font-size: var(--read-font-size-s);
  line-height: 1.5;
  font-weight: bold;
}
.text-msg2 {
  color: var(--main-color);
  font-size: var(--read-font-size-s);
  line-height: 1.5;
  font-weight: bold;
  margin: 10px 0 0;
  flex-basis: 100%;
  text-align: center;
}
.status-item {
  font-weight: bold;
  font-size: 1.8vh;
}

.title_annotation {
  color: var(--main-color);
  font-size: var(--read-font-size-s);
  line-height: 1.5;
  font-weight: bold;
}
.title_comment {
  font-size: var(--read-font-size-m);
  font-weight: bold;
  color: var(--read-font-color-sub);
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  border-left: 1vw solid var(--main-color);
  /* padding-left: 1vw; */
}
.vertical-align input {
  vertical-align: middle;
}
.comment_checkbox {
  color: var(--main-color);
  font-size: var(--read-font-size-s);
  line-height: 1.5;
  font-weight: bold;
}
.theme--light:not(.v-btn--active).v-btn:focus::before {
  opacity: 0;
}
.theme--light:not(.v-btn--active).v-btn:hover::before {
  opacity: 0;
}
.about-cast-review__title {
  font-size: var(--read-font-size-m);
  padding: 7px;
  margin-bottom: 8px;
  color: #FF466E;
  font-weight: bold;
}
.about-cast-review {
  border: 1vw solid var(--main-color);
  font-size: var(--read-font-size-s);
  padding: 7px;
}
.checkbox-text {
  color: var(--main-color);
  font-weight: bold;
  color: red;
}
.cast-card {
  background: #FFF4F7;
}
.cast-card-header{
  background: var(--sub-color);
  font-weight: bold;
  justify-content: center;
  color:#fff;
}
.star-box {
  min-width: 110px;
}
::v-deep .leading_room_checkbox .v-label {
  font-size: var(--read-font-size-s);
  font-weight: bold;
}
.v-application .accent--text{
  color: var(--day-sat-color) !important;
}
.v-application .accent--text::before {
  color: var(--day-sat-color) !important;
}
.v-rating__text {
  font-weight: bold;
  margin: 20px 0 10px;
}
</style>