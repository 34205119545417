export const state = () => ({
  openDialog: false,
  siteMemberId: '',
  castReviewId: '',
  displayCastThumbnail: false,
})

export const mutations = {
  setOpenDialog (state, data) {
    state.openDialog = data;
  },
  setSiteMemberId (state, data) {
    state.siteMemberId = data;
  },
  setCastReviewId (state, data) {
    state.castReviewId = data;
  },
  setDisplayCastThumbnail (state, data) {
    state.displayCastThumbnail = data;
  },
}

export const actions = {
  async commitOpenDialog(context, data) {
    context.commit('setOpenDialog', data)
  },
  async commitSiteMemberId(context, data) {
    context.commit('setSiteMemberId', data)
  },
  async commitCastReviewId(context, data) {
    context.commit('setCastReviewId', data)
  },
  async commitDisplayCastThumbnail (context, data) {
    context.commit('setDisplayCastThumbnail', data)
  },
}