export const state = () => ({
  selected_shops: [],
  selected_cast: null,
})

export const mutations = {
  setSelectedshops (state, data) {
    state.selected_shops = data;
  },
  setSelectedCasts (state, data) {
    state.selected_cast = data;
  }
}

export const actions = {
  commitSelectedShops(context, data) {
    context.commit('setSelectedshops', data)
  },
  commitSelectedCast(context, data) {
    context.commit('setSelectedCasts', data)
  },
}
