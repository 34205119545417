export const state = () => ({
  newSiteMemberHelpfullCount: 0,
  newSiteMemberFollowerCount: 0,
  // newNotificationSum: 0,
})

export const mutations = {
  setNewSiteMemberHelpfullCount (state, data) {
    state.newSiteMemberHelpfullCount = data;
  },
  setNewSiteMemberFollowerCount (state, data) {
    state.newSiteMemberFollowerCount = data;
  },
  // setNewNotificationSum (state, data) {
  //   state.newNotificationSum = data;
  // },
}

export const actions = {
  async commitNewSiteMemberHelpfullCount(context, data) {
    context.commit('setNewSiteMemberHelpfullCount', data)
  },
  async commitNewSiteMemberFollowerCount(context, data) {
    context.commit('setNewSiteMemberFollowerCount', data)
  },
  // async commitNewNotificationSum(context, data) {
  //   context.commit('setNewNotificationSum', data)
  // },
}