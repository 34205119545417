/**
 * UIに関するデータ保持 (Cookie保持)
 */
export const state = () => ({
  // 18禁ダイアログ(boolean)
  prohibitedUnder18Dialog: true,
  // ログアウト時のラストURL
  logoutLastUri: '',
  logoutCastProfileUri: '',
  disableAutoFeaturesDialog: false, // キャストの重視するポイントダイアログを自動で表示させない



  // isAuthenticated: false // ベーシック認証



})

export const mutations = {
  // 18禁ダイアログ
  setProhibitedUnder18Dialog(state, data) {
    state.prohibitedUnder18Dialog = data;
  },
  // ログアウト時のラストURI
  setLogoutLastUri(state, data) {
    state.logoutLastUri = data;
  },
  // ログアウト時のキャストプロフィールラストURI
  setLogoutCastProfileUri(state, data) {
    state.logoutCastProfileUri = data;
  },
  // disableAutoFeaturesDialog
  disableAutoFeaturesDialog(state, data) {
    state.disableAutoFeaturesDialog = data;
  },
}

export const actions = {
  // 18禁ダイアログ
  async commitProhibitedUnder18Dialog(context, data) {
    context.commit('setProhibitedUnder18Dialog', data)
  },
  // ログアウト時のラストURI
  async commitLogoutLastUri(context, data) {
    context.commit('setLogoutLastUri', data)
  },
  // ログアウト時のキャストプロフィールラストURI
  async commitLogoutCastProfileUri(context, data) {
    context.commit('setLogoutCastProfileUri', data)
  },
  // disableAutoFeaturesDialog
  async commitDisableAutoFeaturesDialog(context, data) {
    context.commit('disableAutoFeaturesDialog', data)
  },
}