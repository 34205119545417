import Pusher from 'pusher-js'
import Vue from 'vue'
let socket = [] // 接続情報

export default ({ store }) => {
  Vue.prototype.$pusherConnectFunction = function(roomId){
    if(roomId){
      // Pusherに接続
      socket = new Pusher(process.env.PUSHER_APP_KEY, {
        authEndpoint: process.env.API_URL_BROWSER+'private/broadcasting-auth',
        authTransport: 'ajax',
        auth: {
          headers: {
            Authorization: this.$auth.$storage.getUniversal('_token.auth0', true),
            'X-Requested-With': 'XMLHttpRequest',
          }
        },
        cluster: 'ap3',
        forceTLS: true
      })

      // 現在接続状態のチャンネルを取得して接続解除
      socket.allChannels().forEach(channel => { socket.unsubscribe(channel.name) });
      // ルームIDのチャンネルを接続
      const channel = socket.subscribe('private-'+roomId)
      channel.bind('chatEvent', function (data) {
        store.commit('pusher/setMessage', data)
      })
      // socket.allChannels().forEach(channel => { console.log('「'+channel.name+'」チャンネルに接続しました') });
    }
  }
  Vue.prototype.$pusherDisconnectFunction = function(){
    // socket.allChannels().forEach(channel => { console.log('「'+channel.name+'」チャンネルを切断しました') });
    // Pusherから切断
    socket.disconnect()
  }
}