 export const state = () => ({
    openDialog: false,
    siteMemberId: '',
  })
  
  export const mutations = {
    setOpenDialog (state, data) {
      state.openDialog = data;
    },
    setSiteMemberId (state, data) {
      state.siteMemberId = data;
    },
  }
  
  export const actions = {
    async commitOpenDialog(context, data) {
      context.commit('setOpenDialog', data)
    },
    async commitSiteMemberId(context, data) {
      context.commit('setSiteMemberId', data)
    },
  }
  