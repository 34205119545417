export const state = () => ({
  recommendCasts: [],
  todayCasts: [],
  newCasts: [],
  newCastReviews: [],
  rankingCast1: [],
  rankingCast2: [],
  rankingCast3: [],
  rankingCast4: [],
  rankingCast5: [],
  allCasts: [],
  rsvCasts: [],
})
export const mutations = {
  // レコメンドキャストを取得したときにセット
  saveRecommendCastsFollowerCountData(state, data) {
    state.recommendCasts = data;
  },
  // 本日出勤で出勤回数が3回以内のキャストを取得したときにセット
  saveTodayCastsFollowerCountData(state, data) {
    state.todayCasts = data;
  },
  // 新人キャストを取得したときにセット
  saveNewCastsFollowerCountData(state, data) {
    state.newCasts = data;
  },
  // 新着クチコミを取得したときにセット
  saveNewCastReviewsFollowerCountData(state, data) {
    state.newCastReviews = data;
  },
  // ランキングを取得したときにセット
  saveRankingCast1FollowerCountData(state, data) {
    state.rankingCast1 = data;
  },
  saveRankingCast2FollowerCountData(state, data) {
    state.rankingCast2 = data;
  },
  saveRankingCast3FollowerCountData(state, data) {
    state.rankingCast3 = data;
  },
  saveRankingCast4FollowerCountData(state, data) {
    state.rankingCast4 = data;
  },
  saveRankingCast5FollowerCountData(state, data) {
    state.rankingCast5 = data;
  },
  saveAllCastsFollowerCountData(state, data) {
    state.allCasts = data;
  },
  saveRsvCastsFollowerCountData(state, data) {
    state.rsvCasts = data;
  },
  // ハート押下で更新時
  updateFollowerCountData(state, data) {
    state.recommendCasts = data.recommendCasts;
    state.todayCasts = data.todayCasts;
    state.newCasts = data.newCasts;
    state.newCastReviews = data.newCastReviews;
    state.rankingCast1 = data.rankingCast1;
    state.rankingCast2 = data.rankingCast2;
    state.rankingCast3 = data.rankingCast3;
    state.rankingCast4 = data.rankingCast4;
    state.rankingCast5 = data.rankingCast5;
    state.allCasts = data.allCasts;
    state.rsvCasts = data.rsvCasts;
  },
  // deleteFollowerCountData(state) {
  //   state.allCasts = [];
  // },
}
export const actions = {
  saveRecommendCastsFollowerCountData(context, data) {
    context.commit('saveRecommendCastsFollowerCountData', data)
  },
  saveTodayCastsFollowerCountData(context, data) {
    context.commit('saveTodayCastsFollowerCountData', data)
  },
  saveNewCastsFollowerCountData(context, data) {
    context.commit('saveNewCastsFollowerCountData', data)
  },
  saveNewCastReviewsFollowerCountData(context, data) {
    context.commit('saveNewCastReviewsFollowerCountData', data)
  },
  saveRankingCast1FollowerCountData(context, data) {
    context.commit('saveRankingCast1FollowerCountData', data)
  },
  saveRankingCast2FollowerCountData(context, data) {
    context.commit('saveRankingCast2FollowerCountData', data)
  },
  saveRankingCast3FollowerCountData(context, data) {
    context.commit('saveRankingCast3FollowerCountData', data)
  },
  saveRankingCast4FollowerCountData(context, data) {
    context.commit('saveRankingCast4FollowerCountData', data)
  },
  saveRankingCast5FollowerCountData(context, data) {
    context.commit('saveRankingCast5FollowerCountData', data)
  },
  saveAllCastsFollowerCountData(context, data) {
    context.commit('saveAllCastsFollowerCountData', data)
  },
  saveRsvCastsFollowerCountData(context, data) {
    context.commit('saveRsvCastsFollowerCountData', data)
  },
  updateFollowerCountData(context, data) {
    context.commit('updateFollowerCountData', data)
  },
  // saveAllCastsFollowerCountData(context, data) {
  //   context.commit('saveAllCastsFollowerCountData', data)
  // },
  // deleteFollowerCountData(context) {
  //   context.commit('deleteFollowerCountData')
  // },
}