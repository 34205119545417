/**
 * キャスト情報保持
 * ページ遷移時にキャストの情報を維持する
 */

export const state = () => ({
  cast: [],
  casts: [],
  newCasts: [],
  todayCasts: [],
  // newCastArea: { value: 0, label: 'すべて' },
  // newCastGenre: { value: 0, label: 'すべて' },
  newCastExperienceEntryOnly: false,
  recommendCasts: [],
  newCastReviews: [],
  // genreItems: [
  //   {value: 0, label: 'すべて'},
  //   {value: 1, label: 'デリヘル'},
  //   {value: 2, label: 'エステ'},
  //   {value: 3, label: 'オナクラ'},
  // ],
  // areaItems: [
  //   {value: 0, label: 'すべて'},
  //   {value: 13, label: '東京都'},
  //   {value: 14, label: '神奈川県'},
  //   {value: 11, label: '埼玉'},
  //   {value: 12, label: '千葉'},
  // ],
});

export const mutations = {
  // キャスト情報を保存
  saveCastData(state, data) {
    state.cast = data;
  },
  // キャスト情報を保存
  saveCastsData(state, data) {
    state.casts = data;
  },
  // キャスト情報を削除
  deleteCastsData(state) {
    state.casts = [];
  },
  // 新人キャスト情報を保存
  saveNewCastsData(state, data) {
    state.newCasts = data;
  },
  // 新人キャスト情報を保存
  saveTodayCastsData(state, data) {
    state.todayCasts = data;
  },
  saveNewCastArea(state, data) {
    state.newCastArea = data;
  },
  saveNewCastGenre(state, data) {
    state.newCastGenre = data;
  },
  saveNewCastExperienceEntryOnly(state, data) {
    state.newCastExperienceEntryOnly = data;
  },
  // レコメンドキャスト情報を保存
  saveRecommendCastsData(state, data) {
    state.recommendCasts = data;
  },

  // キャスト写メ情報を保存
  savePhotoArticleCastData(state, data) {
    state.cast.photoDiaryArticle.push(...data);
  },
  saveArticleUnfoldingFlag(state, data) {
    state.cast.photoDiaryArticle[data.index].unfolding_flag = true;
  },
  deletePhotoArticleCastData(state) {
    state.cast.photoDiaryArticle = [];
  },
  // キャスト写メをいいね
  likePhotoArticleCastData(state, data) {
    const { index, siteMemberId, siteMemberNickname, createdAt } = data;
    state.cast.photoDiaryArticle[index]['like_flag'] = true;
    state.cast.photoDiaryArticle[index]['like_sum'] =
      state.cast.photoDiaryArticle[index]['like_sum'] + 1;
    state.cast.photoDiaryArticle[index][
      'site_member_like_cast_photo_diary_article'
    ].push({
      cast_photo_diary_article_id: state.cast.photoDiaryArticle[index].id,
      site_member_id: siteMemberId,
      nickname: siteMemberNickname,
      created_at: createdAt,
    });
  },
  // いいねしたキャスト写メのいいねを外す
  removeLikePhotoArticleCastData(state, data) {
    const { index, siteMemberId } = data;
    state.cast.photoDiaryArticle[index]['like_flag'] = false;
    state.cast.photoDiaryArticle[index]['like_sum'] =
      state.cast.photoDiaryArticle[index]['like_sum'] == 0
        ? 0
        : state.cast.photoDiaryArticle[index]['like_sum'] - 1;
    state.cast.photoDiaryArticle[index][
      'site_member_like_cast_photo_diary_article'
    ] = state.cast.photoDiaryArticle[index][
      'site_member_like_cast_photo_diary_article'
    ].filter((row) => row.site_member_id !== siteMemberId);
  },
  saveNewCastReviews(state, data) {
    state.newCastReviews = data;
  },
};

export const actions = {
  saveCastData(context, data) {
    context.commit('saveCastData', data);
  },
  saveCastsData(context, data) {
    context.commit('saveCastsData', data);
  },
  deleteCastsData(context) {
    context.commit('deleteCastsData');
  },
  saveNewCastsData(context, data) {
    context.commit('saveNewCastsData', data);
  },
  saveTodayCastsData(context, data) {
    context.commit('saveTodayCastsData', data);
  },
  saveNewCastArea(context, data) {
    context.commit('saveNewCastArea', data);
  },
  saveNewCastGenre(context, data) {
    context.commit('saveNewCastGenre', data);
  },
  saveNewCastExperienceEntryOnly(context, data) {
    context.commit('saveNewCastExperienceEntryOnly', data);
  },
  saveRecommendCastsData(context, data) {
    context.commit('saveRecommendCastsData', data);
  },

  savePhotoArticleCastData(context, data) {
    context.commit('savePhotoArticleCastData', data);
  },
  saveArticleUnfoldingFlag(context, data) {
    context.commit('saveArticleUnfoldingFlag', data);
  },
  deletePhotoArticleCastData(context) {
    context.commit('deletePhotoArticleCastData');
  },
  likePhotoArticleCastData(context, data) {
    context.commit('likePhotoArticleCastData', data);
  },
  removeLikePhotoArticleCastData(context, data) {
    context.commit('removeLikePhotoArticleCastData', data);
  },
  async commitNewCastReviews(context, data) {
    context.commit('saveNewCastReviews', data);
  },
};
