<template>
    <v-dialog v-model="this.$store.state.dialog.followSiteMemberLimitDateDialog" max-width="400" transition="dialog-bottom-transition" style="z-index: 9999" persistent>
      <v-card class="pa-3 text-center" style="font-weight: bold">
        <div class="pa-3 text-left">
          申し訳ございませんがフォローは1日{{ this.$store.state.siteMemberData.maxFollowSiteMember }}件が上限となっております。<br/>
          ※24:00にリセットされます<br/><br/>
        </div>
        <div class="px-3">
          <v-btn
            block
            small
            elevation="0"
            v-on:click="$store.dispatch('dialog/saveFollowSiteMemberLimitDateDialogFlag', false)"
            style="color: var(--main-color); font-weight: bold"
            >閉じる</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
	props: [
  ],
  data: () => ({
  }),
}
</script>
