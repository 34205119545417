<template>
  <v-dialog v-model="dialog" max-width="370" style="z-index: 9999">
    <v-card class="pa-3 text-center" style="font-weight: bold">
      <div class="pa-3 primary--text text-center">
        {{ message }}
      </div>
      <div class="pa-5">
        <v-btn
          block
          color="primary"
          v-on:click="toLogin()"
          elevation="0"
          height="45"
          style="font-weight: bold"
        >
          <v-icon class="pr-2">mdi-lock</v-icon>{{ $t('login_register') }}
        </v-btn>
      </div>
      <div class="px-5 pt-2 pb-5">
        <v-btn
          block
          elevation="0"
          height="45"
          v-on:click="dialog = !dialog"
          style="color: var(--main-color); font-weight: bold"
        >
          {{ $t('close') }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  props: ['message'],
  data() {
    return {
      dialog: false,
    };
  },
  computed: {},
  created: function () {},
  mounted: function () {},
  methods: {},
};
</script>
