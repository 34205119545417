<template>
  <v-dialog
    v-model="this.$store.state.dialog.loginError"
    max-width="350"
    persistent
    no-click-animation
    transition="dialog-bottom-transition"
    style="z-index: 9999"
  >
    <v-card
      class="pa-4"
      color="primary"
      style="color: #fff; font-size: var(--read-font-size-s)"
    >
      <v-row>
        <v-col class="pa-0" cols="2">
          <v-layout column justify-center fill-height>
            <v-container class="pa-0 text-center">
              <img :src="alertImg" />
            </v-container>
          </v-layout>
        </v-col>
        <v-col class="pa-1" cols="10">
          <div>{{ $t('text_fboc') }}<br />{{ $t('text_lsiz') }}</div>
        </v-col>
      </v-row>
      <div class="text-right">
        <br>
        <v-btn
          ><a :href="logoutUrl" style="text-decoration: none">{{
            $t('back_to_top')
          }}</a></v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import alertImg from '~/assets/img/alert.png';
export default {
  data() {
    return {
      alertImg: alertImg,
      logoutUrl: `https://${process.env.AUTH0_DOMAIN}/v2/logout?returnTo=${process.env.APP_URL}/${this.$i18n.defaultLocale}/logout&client_id=${process.env.AUTH0_CLIENT_ID}`,
    };
  },
};
</script>
