/**
 * ダイアログ
 */

 export const state = () => ({
    cast_id: '',
    business_type_ids: [],
    area_ids: [],
    shop_ids: [],
    order_by: 'date',
    order: '',
    only_favorite: false,
    only_follow_site_members: false,
    selected_shops: [],
    selected_casts: [],
  })
  
  export const mutations = {
    setCastId (state, data) {
      state.cast_id = data;
    },
    setBusinessTypeIds (state, data) {
      state.business_type_ids = data;
    },
    setAreaIds (state, data) {
      state.area_ids = data;
    },
    setShopIds (state, data) {
      state.shop_ids = data;
    },
    setOrderBy (state, data) {
      state.order_by = data;
    },
    setOrder (state, data) {
      state.order = data;
    },
    setOnlyFavorite (state, data) {
      state.only_favorite = data;
    },
    setOnlyFollowSiteMembers (state, data) {
      state.only_follow_site_members = data;
    },
    setSelectedshops (state, data) {
      state.selected_shops = data;
    },
    setSelectedCasts (state, data) {
      state.selected_casts = data;
    }
  }
  
  export const actions = {
    commitCastId(context, data) {
      context.commit('setCastId', data)
    },
    commitBusinessTypeIds(context, data) {
      context.commit('setBusinessTypeIds', data)
    },
    commitAreaIds(context, data) {
      context.commit('setAreaIds', data)
    },
    commitShopIds(context, data) {
      context.commit('setShopIds', data)
    },
    commitSelectedshops(context, data) {
      context.commit('setSelectedshops', data)
    },
    commitSelectedCasts(context, data) {
      context.commit('setSelectedCasts', data)
    },
    commitOrderBy(context, data) {
      context.commit('setOrderBy', data)
    },
    commitOrder(context, data) {
      context.commit('setOrder', data)
    },
    commitOnlyFavorite(context, data) {
      context.commit('setOnlyFavorite', data)
    },
    commitOnlyFollowSiteMembers(context, data) {
      context.commit('setOnlyFollowSiteMembers', data)
    },
  }
  