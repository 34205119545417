const resetServiceWorker = () => {
  if (process.client && 'serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (const worker of registrations) {
        worker.unregister();
      }
    });
    if (window.name !== 'isReload') {
      window.location.reload(true);
      window.name = 'isReload';
    } else {
      window.name = '';
    }
  }
}

export default ({}, inject) => {
  inject('resetServiceWorker', resetServiceWorker);
}