export const state = () => ({
    followCastIds: [],
    publicCastIds: [],
})

export const mutations = {
    setFollowCastIds (state, data) {
      state.followCastIds = data;
    },
    setPublicCastIds (state, data) {
      state.publicCastIds = data;
    }
}