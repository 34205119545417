export const state = () => ({
  //【Flag系】
  showDatePicker: false, // date_picker表示
  reserveBtnStatus: false, // キャストページ、スケジュールの予約ボタン状態管理(予約ボタンを押した状態がtrue)
  reserveAlertDialog: false, // 5日以内しか予約はできない警告用モーダル表示フラグ
  selectedOtherArea: false, // その他エリア
  onlyFavoriteCasts: false, // お気に入りのみ
  //【Value系】
  arrivalTimeValue: 30, // 選択された到着時間
  arrivalTimeLabel: '', // 30分後
  arrivalDateTimeValue: '', // 選択された実際の到着時間 Value (YYYY-MM-DD HH:mm:ss 形式)
  selectedArrivalDateValue: '', // 選択した日付
  selectedArrivalTimeValue: '', // 選択した時間
  selectedNoDateValue: false, // 日時を指定しない
  searchRangeAllTimeFlag: true, // 0：時間指定あり、1：全時間設定（時間指定なし）
  rangeStartTime1: null, // レンジ時間指定1 2021-11-10 11:10:00
  rangeStartTime2: null, // レンジ時間指定2 2021-11-10 11:10:00
  regionValue: 1, // 選択された県object
  regionLabel: '', // 関東
  searchPrefectureId: 13,
  areaValue: 6, // ★選択されたエリアobject（未使用）
  subAreaValue: '', // ★選択されたエリアobject（未使用）
  areaLabel: '', // 五反田
  mapAreaId: '',
  nominateCast: '',
  castFree: false,
  favaliteTagIds: [],
  ngSmokeValue: null,
  ngTattooValue: null,
  ngBodyPiercingFlag: 0,
  newCastFlag: 0,
  searchUnderHairType: null, // null: こだわりなし, 1: パイパン, 2: パイパンNG
  searchDrinkType: null,
  selectedHotelId: '',
  selectedHotelName: '',
  selectedHotel: {}, // 検索で選択されたホテルオブジェクト
  selectedCorporationId: '',
  selectedReserveId: '',
  allCasts: [],
  rsvCasts: [],
  searchType: 2, //検索タイプ 1: 今すぐ、2: レンジ、3: 日時指定なし
  genreValue: [],
  budgetValue: [0, 1, 2, 3, 4, 5, 6],
  castAgeValue: [1, 2, 3, 4, 5, 6, 7],
  castHeightValue: [1, 2, 3, 4, 5, 6, 7, 8],
  castCupValue: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  castSmValue: [1, 2, 3, 4, 5],
  search_cast_budget_amount_min: 0,
  search_cast_budget_amount_max: 99999,
  search_cast_age_min: 18,
  search_cast_age_max: 50,
  search_cast_height_min: 135,
  search_cast_height_max: 185,
  search_cast_cup_min: 1,
  search_cast_cup_max: 10,
  search_cast_s_degree: 1,
  search_cast_m_degree: 5,
  disableAutoFeaturesDialog: false, // キャストの重視するポイントダイアログを自動で表示させない
  changeReservationId: '',
  changeReservationCourseMinute: 0,
  //【その他】
  cupList: {
    1: 'A',
    2: 'B',
    3: 'C',
    4: 'D',
    5: 'E',
    6: 'F',
    7: 'G',
    8: 'H',
    9: 'I',
    10: 'J',
    11: 'K',
    12: 'L',
    13: 'M',
    14: 'N'
  },
  countUp: 20, // 1ページあたりのキャスト数
  profileScheduleUseFlag: false, // プロフィールのスケジュールから日付指定したかどうか(/callでクリア))
  tempArrivalTimeValue: 0, // プロフィールのスケジュールで日付指定した場合の一時退避用
  tempArrivalTimeLabel: '', // プロフィールのスケジュールで日付指定した場合の一時退避用
  //【お支払い方法(カードor現金)】
  paymentMethodValue: 1,
  selectPlace: 'selected-hotel', // ホテル(selected-hotel) or 自宅(selected-home)
  // searchShopList: [], // 店舗一覧  {shop_id, shop_name, shop_kana}
  selectedShopIds: [], // 選択された店舗Id [44, 45]
  selectedShopObj: [], // 選択された店舗  {shop_id, shop_name, shop_kana}
  selectedCastIds: [], // 選択されたキャストId [44, 45]
  selectedCastObj: [],
  recommend_site_member_important_cast_features: [{
      category: 1,
      point: 1
    },
    {
      category: 2,
      point: 1
    },
    {
      category: 3,
      point: 1
    },
    {
      category: 4,
      point: 1
    },
    {
      category: 5,
      point: 1
    },
  ],
  //【Data】
  // /**
  //  * 到着時間item
  //  */
  // arrivalTimeItems: [
  //   {time: 15, label: '今すぐ'},
  //   {time: 30, label: '30分後'},
  //   {time: 60, label: '60分後'},
  //   {time: 90, label: '90分後'},
  //   {time: 0,  label: 'それ以外'},
  // ],
  // /**
  //  * 地域
  //  */
  // regionItems: [
  //   { id: 1, label: "関東"},
  // ],
  // prefectureItems: [
  //   { id: 13, label: "東京"},
  //   { id: 14, label: "神奈川"},
  //   { id: 12, label: "千葉"},
  //   { id: 11, label: "埼玉"},
  // ],
  // /**
  //  * エリア
  //  */
  // areaItems: {
  //   1: [
  //     // 東京
  //     { id: 1,  label: "錦糸町", subAreaId: null },
  //     { id: 2,  label: "五反田/品川", subAreaId: null },
  //     { id: 3,  label: "六本木/赤坂", subAreaId: null },
  //     { id: 4,  label: "渋谷", subAreaId: null },
  //     { id: 5,  label: "新宿/歌舞伎町", subAreaId: null },
  //     { id: 6,  label: "池袋", subAreaId: null },
  //     { id: 7,  label: "鶯谷", subAreaId: null },
  //     { id: 8,  label: "新橋/銀座", subAreaId: null },
  //     { id: 9,  label: "恵比寿/目黒", subAreaId: null },
  //     // 横浜
  //     { id: 10, label: "横浜市神奈川区", subAreaId: null },
  //     { id: 11, label: "横浜市西区", subAreaId: null },
  //     { id: 12, label: "横浜市南区", subAreaId: null },
  //     { id: 13, label: "横浜市中区", subAreaId: null },
  //     { id: 14, label: "横浜市鶴見区", subAreaId: null },
  //     { id: 15, label: "横浜市保土ヶ谷区", subAreaId: null },
  //     { id: 16, label: "横浜市磯子区", subAreaId: null },
  //     { id: 17, label: "横浜市金沢区", subAreaId: null },
  //     { id: 18, label: "横浜市港北区", subAreaId: null },
  //     { id: 19, label: "横浜市戸塚区", subAreaId: null },
  //     { id: 20, label: "横浜市港南区", subAreaId: null },
  //     { id: 21, label: "横浜市旭区", subAreaId: null },
  //     { id: 22, label: "横浜市緑区", subAreaId: null },
  //     { id: 23, label: "横浜市瀬谷区", subAreaId: null },
  //     { id: 24, label: "横浜市栄区", subAreaId: null },
  //     { id: 25, label: "横浜市泉区", subAreaId: null },
  //     { id: 26, label: "横浜市青葉区", subAreaId: null },
  //     { id: 27, label: "横浜市都筑区", subAreaId: null },
  //     // 千葉
  //     { id: 28, label: "千葉市中央区", subAreaId: null },
  //     { id: 29, label: "千葉市花見川区", subAreaId: null },
  //     { id: 30, label: "千葉市稲毛区", subAreaId: null },
  //     { id: 31, label: "千葉市若葉区", subAreaId: null },
  //     { id: 32, label: "千葉市緑区", subAreaId: null },
  //     { id: 33, label: "千葉市美浜区", subAreaId: null },
  //     // 埼玉
  //     { id: 34, label: "さいたま市西区", subAreaId: null },
  //     { id: 35, label: "さいたま市北区", subAreaId: null },
  //     { id: 36, label: "さいたま市大宮区", subAreaId: null },
  //     { id: 37, label: "さいたま市見沼区", subAreaId: null },
  //     { id: 38, label: "さいたま市中央区", subAreaId: null },
  //     { id: 39, label: "さいたま市桜区", subAreaId: null },
  //     { id: 40, label: "さいたま市浦和区", subAreaId: null },
  //     { id: 41, label: "さいたま市南区", subAreaId: null },
  //     { id: 42, label: "さいたま市緑区", subAreaId: null },
  //     { id: 43, label: "さいたま市岩槻区", subAreaId: null },
  //   ],
  // },
  // /**
  //  * キャストタグ
  //  */
  // castTags: {
  //   // 外見
  //   appearance: [
  //     {id: 1, label: 'かわいい'},
  //     {id: 2, label: 'きれい'},
  //     {id: 26, label: '清楚'},
  //     {id: 23, label: 'ハーフ顔'},
  //     {id: 25, label: '童顔'},
  //     {id: 48, label: '歯がきれい'},
  //     {id: 28, label: '色気たっぷり'},
  //     {id: 49, label: '美肌'},
  //     {id: 19, label: '美白'},
  //     {id: 6, label: 'スレンダー'},
  //     {id: 8, label: 'グラマー'},
  //     {id: 17, label: '美乳'},
  //     {id: 29, label: '綺麗なくびれ'},
  //     {id: 30, label: '美尻'},
  //     {id: 18, label: '美脚'},
  //     {id: 3, label: 'お姉さま'},
  //     {id: 39, label: 'OL系'},
  //     {id: 40, label: '人妻系'},
  //     {id: 38, label: 'ロリ系'},
  //   ],
  //   // 内面
  //   innerSurface: [
  //     {id: 13, label: '明るい'},
  //     {id: 14, label: 'おしとやか'},
  //     {id: 15, label: 'エロい'},
  //     {id: 16, label: '癒し系'},
  //     {id: 34, label: '愛嬌抜群'},
  //     {id: 50, label: '話し上手'},
  //     {id: 35, label: '聡明'},
  //     {id: 36, label: 'おっとり'},
  //     {id: 37, label: '恥ずかしがり'},
  //   ],
  //   // サービス
  //   service: [
  //     {id: 4, label: '素人感'},
  //     {id: 9, label: 'テクニシャン'},
  //     {id: 10, label: '雰囲気作り'},
  //     {id: 32, label: '恋人気分'},
  //     {id: 12, label: '受身好き'},
  //     {id: 11, label: '責め好き'},
  //     {id: 20, label: '感度抜群'},
  //     {id: 33, label: '潮吹き体質'},
  //     {id: 51, label: 'キス上手'},
  //     {id: 52, label: '手コキ上手'},
  //     {id: 53, label: 'フェラ上手'},
  //     {id: 54, label: 'スマタ上手'},
  //     {id: 21, label: 'ドM'},
  //     {id: 22, label: 'ドS'},
  //   ],
  // },
  // /**
  //  * ジャンル
  //  */
  // genreItems: [
  //   {id: 1, name: 'ヘルス'},
  //   {id: 2, name: 'エステ'},
  //   {id: 3, name: 'オナクラ'},
  // ],
  // ngSmoke: [
  //   {label: '紙・加熱式・電子タバコもNG', value: 1},
  //   {label: '紙タバコのみNG', value: 2}
  // ],
  // ngTattoo: [
  //   {label: 'タトゥー完全NG', value: 1},
  //   {label: '手のひらサイズ以上のタトゥーはNG', value: 2}
  // ],
  // ngBodyPiercing: {
  //   label: 'ボディピアス(耳以外)NG', value: 1
  // },
  // newCast: {
  //   label: '入店後勤務日数10日以下', value: 1
  // },
  // searchUnderHair: [
  //   {label: 'パイパン', value: 1},
  //   {label: 'パイパンNG', value: 2}
  // ],
  // searchDrink: [
  //   {label: '一緒にお酒が飲める', value: 1},
  //   {label: '乾杯程度なら飲める', value: 2}
  // ],
  // /**
  //  * 支払い方法
  //  */
  // paymentMethodItems: {
  //   1: 'クレジットカード（手数料無料）',
  //   2: '現金決済',
  // },
  /**
   * 検索条件入力時に一時的に保存する
   */
  tmpSearchConditions: {
    'favaliteTagIds': [],
    'genreValue': [1, 2, 3]
  },
  /**
   * 退会理由
   */
  // unregistReasonItems: {
  //   1: '利用する機会がない',
  //   2: 'サイトが使いずらい',
  //   3: '使い方が分からない',
  //   4: '他店のサービスの方が良い',
  //   5: '興味を惹かれる女性がいない',
  //   6: '利用できるエリアがない',
  //   7: 'その他'
  // },
  /**
   * 選択キャスト
   */
  selectedCasts: [],

  // /**
  //  * Tokyo Escort OTOMEを知ったきっかけ
  //  */
  // registerReasonFrom: [
  //   { id: 1, name: 'シティヘブンネット' },
  //   { id: 2, name: 'ぴゅあらば' },
  //   { id: 3, name: '口コミ風俗情報局' },
  //   { id: 4, name: '風俗じゃぱん' },
  //   { id: 5, name: 'デリヘルタウン' },
  //   { id: 6, name: 'エステの達人' },
  //   { id: 7, name: 'デリヘルじゃぱん' },
  //   { id: 8, name: 'エステナビ' },
  //   { id: 9, name: '知人紹介・クチコミ' },
  //   { id: 10, name: 'オフィシャルホームページ' },
  //   { id: 11, name: '忘れた' },
  //   { id: 12, name: 'その他' }
  // ],

  // 対応可能オプション（プロフプレイオプションカテゴリー）
  profilePlayOptionCategoryIds: [],

  // 全キャストから探すかどうか
  searchCastAll: true,
  // 日時/場所を指定して探す > 時間の選択値　デフォルトは0=今すぐ
  selectedTimeIds: [0],
  // profilePlayOptionCategories: [
  //   {
  //       id: 1,
  //       name: "ローター"
  //   },
  //   {
  //       id: 2,
  //       name: "バイブ"
  //   },
  //   {
  //       id: 3,
  //       name: "電マ"
  //   },
  //   {
  //       id: 4,
  //       name: "オナニー鑑賞"
  //   },
  //   {
  //       id: 5,
  //       name: "放尿"
  //   },
  //   {
  //       id: 6,
  //       name: "顔射"
  //   },
  //   {
  //       id: 7,
  //       name: "ごっくん"
  //   },
  //   {
  //       id: 8,
  //       name: "AF"
  //   }
  // ],
  sortType: 1, // ソートタイプ 1: マッチ度順, 2: 入店順

  onlyFavoriteCastsLabel: {
    label: 'お気に入りキャストのみ表示',
  },

})
export const mutations = {
  mapAreaId(state, value) {
    state.mapAreaId = value;
  },
  sortType(state, value) {
    state.sortType = value;
  },
  //// 到着時間 ////
  arrivalTimeValue(state, value) {
    state.arrivalTimeValue = value;
  },
  arrivalTimeLabel(state, value) {
    state.arrivalTimeLabel = value;
  },
  arrivalDateTimeValue(state, value) {
    state.arrivalDateTimeValue = value;
  },
  showDatePicker(state, value) {
    state.showDatePicker = value;
  },
  selectedArrivalDateValue(state, value) {
    state.selectedArrivalDateValue = value;
  },
  selectedArrivalTimeValue(state, value) {
    state.selectedArrivalTimeValue = value;
  },
  rangeStartTime1(state, value) {
    state.rangeStartTime1 = value;
  },
  rangeStartTime2(state, value) {
    state.rangeStartTime2 = value;
  },
  selectedNoDateValue(state, value) {
    state.selectedNoDateValue = value;
  },
  searchRangeAllTimeFlag(state, value) {
    state.searchRangeAllTimeFlag = value;
  },
  //// エリア ////
  regionValue(state, value) {
    state.regionValue = value;
  },
  regionLabel(state, value) {
    state.regionLabel = value;
  },
  searchPrefectureId(state, value) {
    state.searchPrefectureId = value;
  },
  areaValue(state, value) {
    state.areaValue = value;
  },
  subAreaValue(state, value) {
    state.subAreaValue = value;
  },
  areaLabel(state, value) {
    state.areaLabel = value;
  },
  //// 指名キャスト ////
  nominateCast(state, value) {
    state.nominateCast = value;
  },
  castFree(state, value) {
    state.castFree = value;
  },
  decidedHotel(state, value) {
    state.decidedHotel = value;
  },
  selectedHotelId(state, value) {
    state.selectedHotelId = value;
  },
  selectedHotelName(state, value) {
    state.selectedHotelName = value;
  },
  selectedHotel(state, value) {
    state.selectedHotel = value;
  },
  selectedCorporationId(state, value) {
    state.selectedCorporationId = value;
  },
  selectedReserveId(state, value) {
    state.selectedReserveId = value;
  },
  allCasts(state, value) {
    state.allCasts = value;
  },
  rsvCasts(state, value) {
    state.rsvCasts = value;
  },
  searchType(state, value) {
    state.searchType = value;
  },
  roomNumber(state, value) {
    state.roomNumber = value;
  },
  homeAddress(state, value) {
    state.homeAddress = value;
  },
  profileScheduleUseFlag(state, value) {
    state.profileScheduleUseFlag = value;
  },
  tempArrivalTimeValue(state, value) {
    state.tempArrivalTimeValue = value;
  },
  tempArrivalTimeLabel(state, value) {
    state.tempArrivalTimeLabel = value;
  },
  favaliteTagIds(state, value) {
    state.favaliteTagIds = value;
  },
  ngSmokeValue(state, value) {
    state.ngSmokeValue = value;
  },
  ngTattooValue(state, value) {
    state.ngTattooValue = value;
  },
  ngBodyPiercingFlag(state, value) {
    state.ngBodyPiercingFlag = value;
  },
  newCastFlag(state, value) {
    state.newCastFlag = value;
  },
  searchUnderHairType(state, value) {
    state.searchUnderHairType = value;
  },
  searchDrinkType(state, value) {
    state.searchDrinkType = value;
  },
  paymentMethodValue(state, value) {
    state.paymentMethodValue = value;
  },
  selectPlace(state, value) {
    state.selectPlace = value;
  },
  reserveBtnStatus(state, value) {
    state.reserveBtnStatus = value;
  },
  reserveAlertDialog(state, value) {
    state.reserveAlertDialog = value;
  },
  selectedOtherArea(state, value) {
    state.selectedOtherArea = value;
  },
  onlyFavoriteCasts(state, value) {
    state.onlyFavoriteCasts = value;
  },
  genreValue(state, value) {
    state.genreValue = value;
  },
  budgetValue(state, value) {
    state.budgetValue = value;
  },
  castAgeValue(state, value) {
    state.castAgeValue = value
  },
  castHeightValue(state, value) {
    state.castHeightValue = value
  },
  castCupValue(state, value) {
    state.castCupValue = value
  },
  castSmValue(state, value) {
    state.castSmValue = value
  },
  castBudgetUpdate(state, data) {
    state.search_cast_budget_amount_min = data.search_cast_budget_amount_min;
    state.search_cast_budget_amount_max = data.search_cast_budget_amount_max;
    state.budgetValue = data.budgetValue;
  },
  castAgeUpdate(state, data) {
    state.search_cast_age_min = data.search_cast_age_min;
    state.search_cast_age_max = data.search_cast_age_max;
    state.castAgeValue = data.castAgeValue;
  },
  castHeightUpdate(state, data) {
    state.search_cast_height_min = data.search_cast_height_min;
    state.search_cast_height_max = data.search_cast_height_max;
    state.castHeightValue = data.castHeightValue;
  },
  castCupUpdate(state, data) {
    state.search_cast_cup_min = data.search_cast_cup_min;
    state.search_cast_cup_max = data.search_cast_cup_max;
    state.castCupValue = data.castCupValue;
  },
  castSmUpdate(state, data) {
    state.search_cast_s_degree = data.search_cast_s_degree;
    state.search_cast_m_degree = data.search_cast_m_degree;
    state.castSmValue = data.castSmValue;
  },
  castAttributeUpdate(state, data) {
    state.search_cast_age_min = data.search_cast_age_min;
    state.search_cast_age_max = data.search_cast_age_max;
    state.search_cast_height_min = data.search_cast_height_min;
    state.search_cast_height_max = data.search_cast_height_max;
    state.search_cast_cup_min = data.search_cast_cup_min;
    state.search_cast_cup_max = data.search_cast_cup_max;
    state.search_cast_s_degree = data.search_cast_s_degree;
    state.search_cast_m_degree = data.search_cast_m_degree;
    state.castAgeValue = data.castAgeValue;
    state.castHeightValue = data.castHeightValue;
    state.castCupValue = data.castCupValue;
    state.castSmValue = data.castSmValue;
  },
  // disableAutoFeaturesDialog
  disableAutoFeaturesDialog(state, data) {
    state.disableAutoFeaturesDialog = data;
  },
  // changeReservationId
  changeReservationId(state, data) {
    state.changeReservationId = data;
  },
  // changeReservationCourseMinute
  changeReservationCourseMinute(state, data) {
    state.changeReservationCourseMinute = data;
  },
  tmpSearchConditions(state, data) {
    if (data['arrivalTimeValue'] || data['arrivalTimeValue'] === 0) {
      state.tmpSearchConditions.arrivalTimeValue = data['arrivalTimeValue'];
    }
    if (data['arrivalDateTimeValue']) {
      state.tmpSearchConditions.arrivalDateTimeValue = data['arrivalDateTimeValue'];
    }
    if (data['areaValue']) {
      state.tmpSearchConditions.areaValue = data['areaValue'];
    }
    if (data['favaliteTagIds']) {
      state.tmpSearchConditions.favaliteTagIds = data['favaliteTagIds'];
    }
    if (data['ngSmokeValue']) {
      state.tmpSearchConditions.ngSmokeValue = data['ngSmokeValue'];
    }
    if (data['ngTattooValue']) {
      state.tmpSearchConditions.ngTattooValue = data['ngTattooValue'];
    }
    if (data['ngBodyPiercingFlag']) {
      state.tmpSearchConditions.ngBodyPiercingFlag = data['ngBodyPiercingFlag'];
    }
    if (data['newCastFlag']) {
      state.tmpSearchConditions.newCastFlag = data['newCastFlag'];
    }
    if (data['searchUnderHairType']) {
      state.tmpSearchConditions.searchUnderHairType = data['searchUnderHairType'];
    }
    if (data['searchDrinkType']) {
      state.tmpSearchConditions.searchDrinkType = data['searchDrinkType'];
    }
    // if(data['genreValue']){
    //   state.tmpSearchConditions.genreValue = data['genreValue'];
    // }
  },
  selectedCasts(state, value) {
    state.selectedCasts = value;
  },
  selectedShopIds(state, value) {
    state.selectedShopIds = value;
  },
  selectedShopObj(state, value) {
    state.selectedShopObj = value;
  },
  selectedCastIds(state, value) {
    state.selectedCastIds = value;
  },
  selectedCastObj(state, value) {
    state.selectedCastObj = value;
  },
  selectedCastUpdate(state, data) {
    state.selectedCastIds = data.selectedCastIds;
    state.selectedCastObj = data.selectedCastObj;
  },
  setProfilePlayOptionCategoryIds(state, value) {
    state.profilePlayOptionCategoryIds = value;
  },
  recommend_site_member_important_cast_features(state, value) {
    state.recommend_site_member_important_cast_features = value;
  },
  searchCastAll(state, value) {
    state.searchCastAll = value;
  },
  selectedTimeIds(state, value) {
    state.selectedTimeIds = value;
  }
}

export const actions = {
  async mapAreaId({
    commit
  }, value) {
    commit('mapAreaId', value);
  },
  async sortType({
    commit
  }, value) {
    commit('sortType', value);
  },
  //// 到着時間 ////
  async arrivalTimeValue({
    commit
  }, value) {
    commit('arrivalTimeValue', value);
  },
  async arrivalTimeLabel({
    commit
  }, value) {
    commit('arrivalTimeLabel', value);
  },
  async arrivalDateTimeValue({
    commit
  }, value) {
    commit('arrivalDateTimeValue', value);
  },
  async showDatePicker({
    commit
  }, value) {
    commit('showDatePicker', value);
  },
  async selectedArrivalDateValue({
    commit
  }, value) {
    commit('selectedArrivalDateValue', value);
  },
  async selectedArrivalTimeValue({
    commit
  }, value) {
    commit('selectedArrivalTimeValue', value);
  },
  async rangeStartTime1({
    commit
  }, value) {
    commit('rangeStartTime1', value);
  },
  async rangeStartTime2({
    commit
  }, value) {
    commit('rangeStartTime2', value);
  },
  async selectedNoDateValue({
    commit
  }, value) {
    commit('selectedNoDateValue', value);
  },
  async searchRangeAllTimeFlag({
    commit
  }, value) {
    commit('searchRangeAllTimeFlag', value);
  },
  //// エリア ////
  async regionValue({
    commit
  }, value) {
    commit('regionValue', value);
  },
  async regionLabel({
    commit
  }, value) {
    commit('regionLabel', value);
  },
  async searchPrefectureId({
    commit
  }, value) {
    commit('searchPrefectureId', value);
  },
  async areaValue({
    commit
  }, value) {
    commit('areaValue', value);
  },
  async subAreaValue({
    commit
  }, value) {
    commit('subAreaValue', value);
  },
  async areaLabel({
    commit
  }, value) {
    commit('areaLabel', value);
  },
  //// 指名キャスト ////
  async nominateCast({
    commit
  }, value) {
    commit('nominateCast', value);
  },
  async castFree({
    commit
  }, value) {
    commit('castFree', value);
  },
  async decidedHotel({
    commit
  }, value) {
    commit('decidedHotel', value);
  },
  async selectedHotelId({
    commit
  }, value) {
    commit('selectedHotelId', value);
  },
  async selectedHotelName({
    commit
  }, value) {
    commit('selectedHotelName', value);
  },
  async selectedHotel({
    commit
  }, value) {
    commit('selectedHotel', value);
  },
  async selectedCorporationId({
    commit
  }, value) {
    commit('selectedCorporationId', value);
  },
  async selectedReserveId({
    commit
  }, value) {
    commit('selectedReserveId', value);
  },
  async allCasts({
    commit
  }, value) {
    commit('allCasts', value);
  },
  async rsvCasts({
    commit
  }, value) {
    commit('rsvCasts', value);
  },
  async searchType({
    commit
  }, value) {
    commit('searchType', value);
  },
  async roomNumber({
    commit
  }, value) {
    commit('roomNumber', value);
  },
  async homeAddress({
    commit
  }, value) {
    commit('homeAddress', value);
  },
  async profileScheduleUseFlag({
    commit
  }, value) {
    commit('profileScheduleUseFlag', value);
  },
  async tempArrivalTimeValue({
    commit
  }, value) {
    commit('tempArrivalTimeValue', value);
  },
  async tempArrivalTimeLabel({
    commit
  }, value) {
    commit('tempArrivalTimeLabel', value);
  },
  async ngSmokeValue({
    commit
  }, value) {
    commit('ngSmokeValue', value);
  },
  async favaliteTagIds({
    commit
  }, value) {
    commit('favaliteTagIds', value);
  },
  async ngTattooValue({
    commit
  }, value) {
    commit('ngTattooValue', value);
  },
  async ngBodyPiercingFlag({
    commit
  }, value) {
    commit('ngBodyPiercingFlag', value);
  },
  async newCastFlag({
    commit
  }, value) {
    commit('newCastFlag', value);
  },
  async searchUnderHairType({
    commit
  }, value) {
    commit('searchUnderHairType', value);
  },
  async searchDrinkType({
    commit
  }, value) {
    commit('searchDrinkType', value);
  },
  async paymentMethodValue({
    commit
  }, value) {
    commit('paymentMethodValue', value);
  },
  async selectPlace({
    commit
  }, value) {
    commit('selectPlace', value);
  },
  async reserveBtnStatus({
    commit
  }, value) {
    commit('reserveBtnStatus', value);
  },
  async reserveAlertDialog({
    commit
  }, value) {
    commit('reserveAlertDialog', value);
  },
  async selectedOtherArea({
    commit
  }, value) {
    commit('selectedOtherArea', value);
  },
  async genreValue({
    commit
  }, value) {
    commit('genreValue', value);
  },
  async budgetValue({
    commit
  }, value) {
    commit('budgetValue', value);
  },
  async castAgeValue({
    commit
  }, value) {
    commit('castAgeValue', value);
  },
  async castHeightValue({
    commit
  }, value) {
    commit('castHeightValue', value);
  },
  async castCupValue({
    commit
  }, value) {
    commit('castCupValue', value);
  },
  async castSmValue({
    commit
  }, value) {
    commit('castSmValue', value);
  },
  castBudgetUpdate(context, data) {
    context.commit('castBudgetUpdate', data)
  },
  castAgeUpdate(context, data) {
    context.commit('castAgeUpdate', data)
  },
  castHeightUpdate(context, data) {
    context.commit('castHeightUpdate', data)
  },
  castCupUpdate(context, data) {
    context.commit('castCupUpdate', data)
  },
  castSmUpdate(context, data) {
    context.commit('castSmUpdate', data)
  },
  castAttributeUpdate(context, data) {
    context.commit('castAttributeUpdate', data)
  },
  // disableAutoFeaturesDialog
  async commitDisableAutoFeaturesDialog(context, data) {
    context.commit('disableAutoFeaturesDialog', data)
  },
  // changeReservationId
  async commitChangeReservationId(context, data) {
    context.commit('changeReservationId', data);
  },
  // changeReservationCourseMinute
  async commitChangeReservationCourseMinute(context, data) {
    context.commit('changeReservationCourseMinute', data);
  },
  async tmpSearchConditions({
    commit
  }, data) {
    commit('tmpSearchConditions', data);
  },
  async selectedCasts({
    commit
  }, value) {
    commit('selectedCasts', value);
  },
  // async searchShopList({ commit }, value) {
  //   commit('searchShopList', value);
  // },
  async selectedShopIds({
    commit
  }, value) {
    commit('selectedShopIds', value);
  },
  async selectedShopObj({
    commit
  }, value) {
    commit('selectedShopObj', value);
  },
  async selectedCastIds({
    commit
  }, value) {
    commit('selectedCastIds', value);
  },
  async selectedCastObj({
    commit
  }, value) {
    commit('selectedCastObj', value);
  },
  selectedCastUpdate(context, data) {
    context.commit('selectedCastUpdate', data)
  },
  async commitProfilePlayOptionCategoryIds(context, payload) {
    context.commit('setProfilePlayOptionCategoryIds', payload);
  },
  async recommend_site_member_important_cast_features({
    commit
  }, value) {
    commit('recommend_site_member_important_cast_features', value);
  },
  async searchCastAll({
    commit
  }, value) {
    commit('searchCastAll', value);
  },
  async selectedTimeIds({
    commit
  }, value) {
    commit('selectedTimeIds', value);
  },
  async onlyFavoriteCasts({
    commit
  }, value) {
    commit('onlyFavoriteCasts', value);
  },
}