import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{staticStyle:{"z-index":"9999"},attrs:{"max-width":"350","persistent":"","no-click-animation":"","transition":"dialog-bottom-transition"},model:{value:(this.$store.state.dialog.loginError),callback:function ($$v) {_vm.$set(this.$store.state.dialog, "loginError", $$v)},expression:"this.$store.state.dialog.loginError"}},[_c(VCard,{staticClass:"pa-4",staticStyle:{"color":"#fff","font-size":"var(--read-font-size-s)"},attrs:{"color":"primary"}},[_c(VRow,[_c(VCol,{staticClass:"pa-0",attrs:{"cols":"2"}},[_c(VLayout,{attrs:{"column":"","justify-center":"","fill-height":""}},[_c(VContainer,{staticClass:"pa-0 text-center"},[_c('img',{attrs:{"src":_vm.alertImg}})])],1)],1),_vm._v(" "),_c(VCol,{staticClass:"pa-1",attrs:{"cols":"10"}},[_c('div',[_vm._v(_vm._s(_vm.$t('text_fboc'))),_c('br'),_vm._v(_vm._s(_vm.$t('text_lsiz')))])])],1),_vm._v(" "),_c('div',{staticClass:"text-right"},[_c('br'),_vm._v(" "),_c(VBtn,[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":_vm.logoutUrl}},[_vm._v(_vm._s(_vm.$t('back_to_top')))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }