export default ({ app, store }) => {
  /*
  ** クライアントサイドかつプロダクションモードでのみ実行
  */
  if (process.env.APP_ENV !== 'production') return
  /*
  ** Google アナリティクスのスクリプトをインクルード
  */
  (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
  /*
  ** 現在のページをセット
  */
  ga('create', process.env.GOOGLE_TRACKING_ID, 'auto')
  /*
  ** ルートが変更されるたびに毎回実行（初期化も実行される）
  */
  app.router.afterEach((to) => {
    /*
    ** Google アナリティクスにページビューが追加されたことを伝える
    ** ログイン状態に応じて設定値を変更
    */
    if(store.state.auth.loggedIn && store.state.siteMemberData.id){
      ga('set', 'dimension2', '会員（ログインユーザー）');
      ga('set', 'dimension3', store.state.siteMemberData.id);
    }else{
      ga('set', 'dimension1', '非ログイン');
    }
    ga('set', 'page', to.fullPath)
    ga('send', 'pageview')
  })
}