export const state = () => ({
  timerIds: [],
})

export const mutations = {
  setTimerIds(state, id) {
    state.timerIds.push(id);
  },
  initTimerIds(state, emptyIds) {
    state.timerIds = [];
  }
}

export const actions = {
  async commitTimerIds(context, id) {
    context.commit('setTimerIds', id)
  },
  async commitInitTimerIds(context, emptyIds) {
    context.commit('initTimerIds', emptyIds)
  },
}
  