import Vue from 'vue';
import ngimage_m from '~/assets/img/ngimage_m.png';
import noimage_m from '~/assets/img/noimage_m.png';
import ngimage_p from '~/assets/img/ngimage_p.png';
import _ from 'lodash';
const moment = require('moment');

Vue.mixin({
  data() {
    return {
      ngimage_m: ngimage_m,
      noimage_m: noimage_m,
      ngimage_p: ngimage_p,
    };
  },
  methods: {
    /**
     * ajax: サイト会員情報を取得
     * storeを更新
     */
    getAjaxSiteMember() {
      if (
        this.$store.state.auth.user.email !== '' &&
        this.$store.state.siteMemberData.id !== ''
      ) {
        const postData = {
          email: `${this.$store.state.auth.user.email}`,
        };
        this.$axios
          .post('private/site-member', postData, {
            headers: {
              Authorization: `${this.$auth.$storage.getUniversal(
                '_token.auth0',
                true
              )}`,
            },
          })
          .then((response) => {
            // 会員情報を更新
            if (response.data.id) {
              const data = {
                id: response.data.id,
                uuid: response.data.uuid,
                tel: response.data.tel,
                nickname: response.data.nickname,
                selfIntroduction: response.data.self_introduction,
                imageFilename: response.data.image_filename,
                profileImageCheckStatus: response.data.profile_image_check_status,
                point: response.data.point,
                area_ids: response.data.area_ids,
                reservationNgFlag: response.data.reservation_ng_flag,
                noUseFlag: response.data.no_use_flag,
                prefecture_id: response.data.prefecture_id,
                home_area_id: response.data.home_area_id,
                home_sub_area_id: response.data.home_sub_area_id,
                home_city_text: response.data.home_city_text,
                home_address: response.data.home_address,
                home_building: response.data.home_building,
                home_room_number: response.data.home_room_number,
                home_hope_flag: response.data.home_hope_flag,
                last_use_place_search_type: response.data.last_use_place_search_type,
                talent_ng_site_members: response.data.talent_ng_site_members,
                created_at: response.data.created_at,
                recommend_site_member_important_cast_features: response.data.recommend_site_member_important_cast_features,
                frequentlyPlayedArea: response.data.frequently_played_area_text,
                frequentlyPlayedGenreIds: response.data
                  .frequently_played_genre_ids ?
                  JSON.parse(response.data.frequently_played_genre_ids) :
                  [],
                frequentlyPlayedShopId: response.data.frequently_played_shop_id,
                starPublicFlag: response.data.star_public_flag,
                gradePublicFlag: response.data.grade_public_flag,
                gradeId: response.data.site_member_grade_id,
                star: response.data.star,
                favoriteCastCount: response.data.favorite_cast_count,
                reviewCount: response.data.review_count,
                followCount: response.data.follow_count,
                followerCount: response.data.follower_count,
                followCastIds: response.data.follow_cast_ids,
              };
              // storeを更新
              this.$store.dispatch('siteMemberData/saveSiteMemberData', data);
            } else {
              // ログインエラー
              this.$store.dispatch('dialog/loginError', true);
            }
          })
          .catch((error) => {
            // ログインエラー
            this.$store.dispatch('dialog/loginError', true);
          });
      } else {
        this.$store.dispatch('dialog/loginError', true);
      }
    },
    /**
     * ajax: サイト会員完全ブラックチェック
     * storeを更新
     */
    getAjaxBlackInfo() {
      this.$axios
        .post(
          'private/customer-black-info', {
            tel: this.$store.state.siteMemberData.tel,
          }, {
            headers: {
              Authorization: `${this.$auth.$storage.getUniversal(
                '_token.auth0',
                true
              )}`,
            },
          }
        )
        .then((response) => {
          if (response.data) {
            // 完全ブラックの場合ログインエラー
            this.$store.dispatch('dialog/loginError', true);
          }
        })
        .catch((error) => {
          // 完全ブラックの場合ログインエラー
          this.$store.dispatch('dialog/loginError', true);
        });
    },
    /**
     * ajax: サイト会員通知チェック(予約)
     * storeを更新
     */
    getAjaxReserveNotification() {
      if (!this.$store.state.auth.loggedIn) return;
      this.$axios
        .post(
          'private/action-required-reserve', {
            siteMemberId: this.$store.state.siteMemberData.id,
          }, {
            headers: {
              Authorization: `${this.$auth.$storage.getUniversal(
                '_token.auth0',
                true
              )}`,
            },
          }
        )
        .then((response) => {
          if (response.data) {
            // storeを更新
            this.$store.dispatch('notification/notificationBadgesFlag', true);
            if (response.data.id && response.data.date) {
              // 予約時間10分前後でホテル未入力の場合
              this.$store.dispatch('notification/hotelAlert', response.data);
            } else {
              this.$store.dispatch('notification/hotelAlert', []);
            }
          } else {
            this.$store.dispatch('notification/notificationBadgesFlag', false);
          }
        })
        .catch((error) => {
          // 取得に失敗したらフラグを下げておく
          this.$store.dispatch('notification/notificationBadgesFlag', false);
        });
    },
    /**
     * ajax: サイト会員通知チェック(チャット)
     * storeを更新
     */
    getAjaxChatNotification() {
      if (!this.$store.state.auth.loggedIn) return;
      this.$axios.post('private/get-chat-unread-messages', {
          siteMemberId: this.$store.state.siteMemberData.id
        }, {
          headers: {
            Authorization: `${this.$auth.$storage.getUniversal('_token.auth0', true)}`
          }
        })
        .then(response => {
          if (response.data) {
            // storeを更新
            this.$store.dispatch('notification/chatUnReadMessageCount', response.data.count);
          }
        })
    },
    /**
     * Store情報 クリア
     */
    resetStore: function () {
      this.$store.dispatch('call/arrivalTimeValue', 30); // 今から30分後
      this.$store.dispatch('call/arrivalTimeLabel', '30分後'); // 今から30分後
      this.$store.dispatch('call/arrivalDateTimeValue', ''); // 何日何時何分
      this.$store.dispatch('call/nominateCast', ''); // 指名キャスト
      this.$store.dispatch('call/castFree', false); // キャストフリー
    },
    /**
     * レコメンド
     */
    getRecommendCasts: function () {
      const accessToken = this.$auth.$storage.getUniversal(
        '_token.auth0',
        true
      );
      this.$axios
        .post(
          'private/recommend-casts', {
            siteMemberId: this.$store.state.siteMemberData.id,
            email: this.$store.state.siteMemberData.email,
            areaValue: this.$store.state.siteMemberData.area_ids,
            genreValue: this.$store.state.siteMemberData.search_cast_genre_ids,
            favaliteTagIds: this.$store.state.siteMemberData.favaliteTagIds,
            noUseFlag: this.$store.state.siteMemberData.noUseFlag,
            ngSmokeValue: this.$store.state.call.ngSmokeValue,
            ngTattooValue: this.$store.state.call.ngTattooValue,
            siteMemberTel: this.$store.state.siteMemberData.tel,
          }, {
            headers: {
              Authorization: `${accessToken}`,
            },
          }
        )
        .then((response) => {
          this.$store.dispatch(
            'bindCast/saveRecommendCastsData',
            response.data
          );
        })
        .catch((error) => {});
    },
    // /**
    //  * 会員フォローの上限情報
    //  */
    // getFollowSiteMemberLimit() {
    //   if (this.$store.state.auth.loggedIn === false) {
    //     return;
    //   }
    //   this.$axios.post('private/get-follow-siteMember-limit', {
    //     email: this.$store.state.auth.user.email
    //   }, {
    //     headers: {
    //       Authorization: this.$auth.$storage.getUniversal('_token.auth0', true)
    //     }
    //   }).then(async response => {
    //     this.$store.dispatch('siteMemberData/saveFollowSiteMemberLimit', {
    //       followSiteMemberLimit: response.data.followSiteMemberLimit,
    //       maxFollowSiteMember: response.data.maxFollowSiteMember,
    //     });
    //   }).catch(error => {});
    // },
    /**
     * 店舗チャットルームIDを取得してルームへ移動
     */
    toShopChatRoom(shopId) {
      if (this.$store.state.auth.loggedIn === false) {
        this.isGuestDialog('ログイン/会員登録すると利用可能になります');
        return;
      }
      this.$axios
        .post(
          'private/get-shop-room-id', {
            email: this.$store.state.auth.user.email,
            shopId: shopId,
          }, {
            headers: {
              Authorization: this.$auth.$storage.getUniversal(
                '_token.auth0',
                true
              ),
            },
          }
        )
        .then(async (response) => {
          // if (response.data.statusCode == 200)
          //   this.$router.push('/chat/shopRoom/' + response.data.roomId);
        })
        .catch((error) => {
          this.$router.push('/error');
        });
    },
    /**
     * キャストプロフィールモーダル（開く）
     */
    toCastProfileDialog(cast, scheduleDialog = null, className = null) {
      this.$store.dispatch('call/selectedCorporationId', cast.corporation_id);
      this.$store.dispatch('bindCast/saveCastData', cast);
      this.$store.dispatch('castDialog/selectedCastUrlHash', cast.url_hash);
      if (className) {
        this.$store.dispatch(
          'castDialog/selectedSlideCastClass',
          className + cast.id
        );
      }
      this.$store.dispatch('castDialog/selectedCastProfileDialog', true);
      if (scheduleDialog) {
        this.$store.dispatch(
          'castDialog/selectedCastProfileScheduleDialog',
          scheduleDialog
        );
      }
      this.$store.dispatch('castDialog/selectedTopScrollY', window.scrollY);
      document.body.style.position = 'fixed';
    },
    /**
     * キャストプロフィール（遷移）
     */
    toCastProfileTransition(castUrlHash, nickname, route = null) {
      if (this.$route.name != 'cast-id') {
        this.$router.push({
          query: {
            url_hash: castUrlHash,
          },
        });
      }
      if (route == 'cast') {
        setTimeout(() => this.$router.push(`/cast/${nickname.url_hash}`), 500);
      } else if (route == 'photo-diary') {
        setTimeout(
          () => this.$router.push(`/cast/photo-diary/${nickname.url_hash}`),
          500
        );
      } else if (route == 'siteMember') {
        setTimeout(
          () => this.$router.push(`/user/${nickname.site_member_id}`),
          500
        );
      }
    },
    /**
     * キャストダイアログ閉じる（プロフィール、予約）
     */
    closeCastDialog(path = null, transition = null) {
      if (this.$store.state.castDialog.selectedCastReserveDialog) {
        this.$store.dispatch('call/reserveBtnStatus', false);
        this.$store.dispatch('call/nominateCast', '');
        this.$store.dispatch('call/profileScheduleUseFlag', false); // プロフィールのスケジュールから日付指定したかどうか(/callでクリア))

        this.$store.dispatch('castDialog/selectedCastReserveDialog', false);
      } else {
        document.body.style.position = '';
        var topScrollY = this.$store.state.castDialog.selectedTopScrollY;
        if (
          this.$route.name == 'home' &&
          this.$store.state.castDialog.selectedSlideCastClass
        ) {
          var selectedSlideCastClass =
            this.$store.state.castDialog.selectedSlideCastClass;
          if (document.getElementsByClassName(selectedSlideCastClass)[0]) {
            setTimeout(
              () =>
              document
              .getElementsByClassName(selectedSlideCastClass)[0]
              .scrollIntoView({
                behavior: 'instant',
                block: 'start',
                inline: 'start',
              }),
              100
            );
            setTimeout(
              () =>
              window.scrollTo({
                behavior: 'instant',
                top: topScrollY,
              }),
              200
            );
          } else {
            window.scrollTo(0, topScrollY);
          }
        } else {
          window.scrollTo(0, topScrollY);
          if (this.$store.state.castDialog.sliderScrollX) {
            setTimeout(() => {
              const target = document.getElementsByClassName(
                this.$store.state.castDialog.sliderScrollXTargetId
              )[0].childNodes[0];
              target.scrollLeft += this.$store.state.castDialog.sliderScrollX;
              this.$store.dispatch('castDialog/sliderScrollXTargetId', '');
              this.$store.dispatch('castDialog/sliderScrollX', 0);
            }, 200);
          }
        }
        if (
          transition != 'transition' &&
          this.$store.state.castDialog.selectedCastProfileDialog
        ) {
          this.$router.push({
            query: {},
          });
        }
        if (path != '/call/step2') {
          this.$store.dispatch('bindCast/saveCastData', []);
        }
        this.$store.dispatch('castDialog/selectedSlideCastClass', '');
        this.$store.dispatch('castDialog/selectedCastUrlHash', '');
        this.$store.dispatch('castDialog/selectedTopScrollY', 0);
        this.$store.dispatch('castDialog/selectedCastProfileDialog', false);
        this.$store.dispatch(
          'castDialog/selectedCastProfileScheduleDialog',
          false
        );
      }
    },
    /**
     * API_URLの切り替え
     *
     * ■ searchType: 3
     * キャスト検索【空き枠検索（日時指定なし）API（評価・新人）】（日時指定なし）
     * ■ searchType: 2
     * キャスト検索【時間範囲検索API（評価・新人）】
     *  - search-casts-range-all
     *  - search-casts-range-reservable
     * ■ searchType: 1
     * キャスト検索【今すぐ検索API（評価・新人）】
     *  - search-casts-now-soon-all
     *  - search-casts-now-soon-reservable
     */
    selectSearchCastApiUrl: function (searchType) {
      let apiUrl = 'private/search-casts-now-soon';
      // 日時指定なしフラグにチェックがあれば、空き枠（日付なし）
      if (searchType === 3) {
        apiUrl = 'public/search-casts-enroll';
        // レンジ検索
      } else if (searchType === 2) {
        apiUrl = 'public/search-casts-range';
        // 今すぐ検索
      } else {
        apiUrl = 'private/search-casts-now-soon';
      }
      return apiUrl;
    },
    /**
     *  入店日が過去90日以内か、未来14日以内か
     */
    debutDateWithin90DaysOr14DaysLater(date) {
      const debutDate = moment(date);
      const now = moment();
      const isBefore = debutDate.isBefore(now);
      return isBefore ?
        Math.abs(debutDate.diff(now, 'days')) <= 90 :
        now.diff(debutDate, 'days') <= 14;
    },
    /**
     * 会員プロフィールを開く
     */
    openSiteMemberDialog(siteMemberId) {
      this.$store.dispatch('siteMemberDialog/commitOpenDialog', true);
      this.fixModalBackground(true);
      this.$store.dispatch('siteMemberDialog/commitSiteMemberId', siteMemberId);
    },
    /**
     * 会員プロフィールダイアログを閉じる
     */
    closeSiteMemberProfileDialog() {
      this.$store.dispatch('siteMemberDialog/commitOpenDialog', false);
      this.fixModalBackground(false);
      this.$store.dispatch('siteMemberDialog/commitSiteMemberId', '');
    },
    /**
     * 入店予定かどうかを返す
     */
    debutDate(date) {
      if (!date) return '';
      if (moment() < moment(date)) {
        return (
          moment(date).format('M/D') + ' ' + this.$i18n.t('upcoming') //入店予定
        );
      } else {
        return moment(date).format('M/D') + ' ' + this.$i18n.t('debuted'); //入店
      }
    },
    /**
     *  デフォルトイメージ
     */
    defaultImage(
      thumbnailUrl,
      defaultImageName,
      castRankingPublicFlag = true,
      enrollFlag = true
    ) {
      if (!castRankingPublicFlag || !enrollFlag) return this.ngimage_m;
      if (thumbnailUrl) return thumbnailUrl;
      if (defaultImageName.search('ng') === -1) {
        return this.ngimage_m;
      } else {
        return this.noimage_m;
      }
    },
    // /**
    //  * 会員お知らせ通知取得
    //  */
    // getNoticeNotification() {
    //   const params = {
    //     siteMemberId: this.$store.state.siteMemberData.id,
    //     siteMemberCreatedAt: this.$store.state.siteMemberData.created_at
    //   };
    //   this.$axios.post("public/notice-notification", params, {
    //     headers: {
    //       Authorization: this.$auth.$storage.getUniversal("_token.auth0", true),
    //     },
    //   }).then((response) => {
    //     this.$store.dispatch(
    //       "notification/noticeNotificationDayBefore",
    //       response.data.dayBefore
    //     );
    //     this.$store.dispatch(
    //       "notification/noticeNotificationUntreated",
    //       response.data.untreated
    //     );
    //     this.$store.dispatch(
    //       "notification/noticeNotificationUncheckCount",
    //       response.data.uncheckCount
    //     );
    //   }).catch((error) => {
    //   });
    // },
    // /**
    //  * 参考になったダイアログを開く
    //  */
    // openSiteMemberHelpfullCastReviewDialog(
    //   siteMemberId = '',
    //   castReviewId = '',
    //   displayCastThumnail = false
    // ) {
    //   this.$store.dispatch(
    //     'siteMemberHelpfullCastReviewDialog/commitOpenDialog',
    //     true
    //   );
    //   this.$store.dispatch(
    //     'siteMemberHelpfullCastReviewDialog/commitSiteMemberId',
    //     siteMemberId
    //   );
    //   this.$store.dispatch(
    //     'siteMemberHelpfullCastReviewDialog/commitCastReviewId',
    //     castReviewId
    //   );
    //   this.$store.dispatch(
    //     'siteMemberHelpfullCastReviewDialog/commitDisplayCastThumbnail',
    //     displayCastThumnail
    //   );
    // },
    // /**
    //  * 参考になったダイアログを閉じる
    //  */
    // closeSiteMemberHelpfullCastReviewDialog() {
    //   // this.getHelpfullAndFollowerUnconfirmedCount();
    //   this.$store.dispatch(
    //     'siteMemberHelpfullCastReviewDialog/commitOpenDialog',
    //     false
    //   );
    //   this.$store.dispatch(
    //     'siteMemberHelpfullCastReviewDialog/commitSiteMemberId',
    //     ''
    //   );
    //   this.$store.dispatch(
    //     'siteMemberHelpfullCastReviewDialog/commitCastReviewId',
    //     ''
    //   );
    //   this.$store.dispatch(
    //     'siteMemberHelpfullCastReviewDialog/commitDisplayCastThumbnail',
    //     false
    //   );
    // },
    /**
     * 参考になった未読数を取得
     */
    async getSiteMemberHelpfullUnconfirmedCount() {
      if (
        !this.$store.state.auth.loggedIn ||
        !this.$store.state.siteMemberData.id
      )
        return;
      const params = {
        site_member_id: this.$store.state.siteMemberData.id,
      };
      let result = 0;
      await this.$axios
        .post('private/get-site-member-helpfull-unconfirmed-count', params, {
          headers: {
            Authorization: this.$auth.$storage.getUniversal(
              '_token.auth0',
              true
            ),
          },
        })
        .then((response) => {
          this.$store.dispatch(
            'newNotification/commitNewSiteMemberHelpfullCount',
            response.data.unconfirmedCount
          );
          result = response.data.unconfirmedCount;
        });
      return result;
    },
    // /**
    //  * Tokyo Escort OTOME会員フォロワー未読数を取得
    //  */
    // async getSiteMemberFollowerUnconfirmedCount() {
    //   if (!this.$store.state.auth.loggedIn || !this.$store.state.siteMemberData.id) return;
    //   const params = {
    //     'following_site_member_id': this.$store.state.siteMemberData.id
    //   };
    //   let result = 0;
    //   await this.$axios.post('private/get-site-member-follower-unconfirmed-count', params, {
    //     headers: {
    //       Authorization: this.$auth.$storage.getUniversal("_token.auth0", true)
    //     },
    //   }).then((response) => {
    //     this.$store.dispatch('newNotification/commitNewSiteMemberFollowerCount', response.data.unconfirmedCount);
    //     result = response.data.unconfirmedCount;
    //   });
    //   return result;
    // },
    // /**
    //  * 通知合計
    //  */
    // async getHelpfullAndFollowerUnconfirmedCount() {
    //   const helpfullUnconfrimedCount = await this.getSiteMemberHelpfullUnconfirmedCount();
    //   const followerUnconfrimedCount = await this.getSiteMemberFollowerUnconfirmedCount();
    //   this.$store.dispatch('newNotification/commitNewNotificationSum', helpfullUnconfrimedCount + followerUnconfrimedCount);
    // },
    /**
     * マッチ度 小数点なし
     *  - ユーザー
     *    vectorU
     *     [1, 1, 1, 1, 1]
     *  - キャスト
     *    vectorC
     *     [1, 1, 1, 1, 1]
     *  - 評価数
     * 　 count
     */
    matchScore(vectorU, vectorC) {
      let f1 = 0;
      let f2 = 0;
      let f3 = 0;
      let f4 = 0;
      // 事前計算
      for (let i = 0; i < vectorU.length; i++) {
        f1 += Number(vectorU[i]) * Number(vectorU[i]);
        f2 += Number(vectorC[i]) * Number(vectorC[i]);
        f3 += Number(vectorU[i]) * Number(vectorC[i]);
        f4 += Number(vectorC[i]);
      }
      // コサイン類似度
      const cos = Number(f3) / Number(Math.sqrt(f1) * Math.sqrt(f2));
      const score = Math.floor(cos * 100);
      return score;
    },
    /**
     * マッチ度 小数点1
     *  - ユーザー
     *    vectorU
     *     [1, 1, 1, 1, 1]
     *  - キャスト
     *    vectorC
     *     [1, 1, 1, 1, 1]
     *  - 評価数
     * 　 count
     */
    matchScore1(vectorU, vectorC) {
      console.log('-------------');
      console.log(vectorU);
      console.log(vectorC);

      let f1 = 0;
      let f2 = 0;
      let f3 = 0;
      let f4 = 0;
      // 事前計算
      for (let i = 0; i < vectorU.length; i++) {
        f1 += Number(vectorU[i]) * Number(vectorU[i]);
        f2 += Number(vectorC[i]) * Number(vectorC[i]);
        f3 += Number(vectorU[i]) * Number(vectorC[i]);
        f4 += Number(vectorC[i]);
      }
      console.log(f1);
      console.log(f2);
      console.log(f3);
      console.log(f4);
      // コサイン類似度
      const cos = Number(f3) / Number(Math.sqrt(f1) * Math.sqrt(f2));
      const score = Math.floor(cos * 1000) / 10;

      console.log(cos);
      console.log(score);
      console.log('-------------');

      return score;
    },
    /**
     * マッチ度 小数点2
     *  - ユーザー
     *    vectorU
     *     [1, 1, 1, 1, 1]
     *  - キャスト
     *    vectorC
     *     [1, 1, 1, 1, 1]
     *  - 評価数
     * 　 count
     */
    matchScore2(vectorU, vectorC) {
      let f1 = 0;
      let f2 = 0;
      let f3 = 0;
      let f4 = 0;
      // 事前計算
      for (let i = 0; i < vectorU.length; i++) {
        f1 += Number(vectorU[i]) * Number(vectorU[i]);
        f2 += Number(vectorC[i]) * Number(vectorC[i]);
        f3 += Number(vectorU[i]) * Number(vectorC[i]);
        f4 += Number(vectorC[i]);
      }
      // コサイン類似度
      const cos = Number(f3) / Number(Math.sqrt(f1) * Math.sqrt(f2));
      const score = Math.floor(cos * 10000) / 100;
      return score;
    },
    /**
     * GuestAlertダイアログ
     * Gusetの場合のみダイアログを出す
     */
    isGuestDialog: function (message) {
      if (this.$store.state.auth.loggedIn === false) {
        this.$refs.guestAlert.dialog = true;
        this.guestAlertMessage = message;
      } else {
        this.$refs.guestAlert.dialog = false;
      }
    },
    /**
     * 指定した長さ以上の文字列をスライスして、３点リーダーを足して返す
     */
    omittedText: function (text, length) {
      return length && text.length > length ?
        text.slice(0, length) + '...' :
        text;
    },
    /**
     * 指定した長さ以上の文字列をスライスして、３点リーダーを足して返す
     * 200文字以上の時には「続きを見る」のボタンを付けてる（トップページを除く）
     */
    reviewOmittedText: function (text, unfoldingFlag) {
      return !unfoldingFlag && text.length >= 200 ?
        text.slice(0, 200) + '...' :
        text;
    },
    /**
     * Guestからログイン画面へ遷移するときに、元々のURIを保持しつつ遷移する
     */
    toLogin: function () {
      const urlHash = this.$store.state.bindCast.cast.url_hash;
      const logoutLastUri = _.isNil(urlHash) ?
        this.$route.path :
        `${this.$i18n.locale}/cast/${urlHash}`;
      this.$store.dispatch('browserUI/commitLogoutLastUri', logoutLastUri);
      // ログイン画面に移動
      this.$router.push(`/${this.$i18n.defaultLocale}/login`);
    },
    /**
     * 参考になったが押されたら、vuexのstoreに保存されているbindCast/newCastReviewsの参考になったを上書き
     * castReviewId: 上書きするクチコミID
     * is_help_full: 参考になったのフラグ
     */
    updateIsHelpfullOfCastReviews: function (castReviewId, is_help_full) {
      const castReviews = JSON.parse(
        JSON.stringify(this.$store.state.bindCast.newCastReviews)
      );
      for (let castReview of castReviews) {
        if (Number(castReview.id) === Number(castReviewId)) {
          castReview.is_help_full = is_help_full;
          if (castReview.is_help_full) {
            castReview.site_member_help_full_cast_review_count++;
          } else {
            castReview.site_member_help_full_cast_review_count--;
          }
          this.$store.dispatch('bindCast/commitNewCastReviews', castReviews);
          return;
        }
      }
    },
    updatefollowCastIds: function (followCastIds, castId, favorite) {
      let following = followCastIds;
      if (favorite && followCastIds.includes(castId) === false) {
        following = [...followCastIds, castId];
      } else if (!favorite && followCastIds.includes(castId) === true) {
        following = _.without(followCastIds, castId);
      }
      return following;
    },
    updateFollowerCount: function (follwerCountData, castId, favorite) {
      const updatedCasts = [];
      Object.keys(follwerCountData).forEach(function (type) {
        const bind = JSON.parse(JSON.stringify(follwerCountData[type]));
        const casts = _.map(bind, (v) => {
          if (Number(v.cast_id) === Number(castId)) {
            if (favorite === true) {
              v.site_member_follow_casts_count =
                v.site_member_follow_casts_count + 1;
            } else {
              v.site_member_follow_casts_count =
                v.site_member_follow_casts_count - 1;
            }
          }
          const obj = new Object();
          Object.assign(obj, {
            cast_id: v.cast_id,
            site_member_follow_casts_count: v.site_member_follow_casts_count,
          });
          return obj;
        });
        updatedCasts[type] = casts;
      });
      return updatedCasts;
    },
    showFollowerCountData: function (follwerCountData, castId) {
      const bind = _.flatMap(follwerCountData);
      const cast = bind.find((e) => e.cast_id === castId);
      const count = cast.site_member_follow_casts_count;
      return count;
    },
    // キャストIDとフォロワー数だけのデータを生成
    pickFollowerCountData: function (data) {
      const bind = JSON.parse(JSON.stringify(data));
      const casts = _.map(bind, (v) => {
        const obj = new Object();
        Object.assign(obj, {
          cast_id: v.cast_id,
          site_member_follow_casts_count: v.site_member_follow_casts_count,
        });
        return obj;
      });
      return casts;
    },
    pickFollowerCountDataForCastReviews: function (data) {
      const casts = [];
      Object.keys(data).forEach(function (key) {
        const bind = JSON.parse(JSON.stringify(data[key].cast));
        const obj = new Object();
        Object.assign(obj, {
          cast_id: bind.id,
          site_member_follow_casts_count: bind.site_member_follow_casts_count,
        });
        return (casts[key] = obj);
      });
      return casts;
    },
    pickFollowerCountDataForRanking: function (data) {
      const bind = JSON.parse(JSON.stringify(data));
      const casts = _.map(bind, (v) => {
        const obj = new Object();
        Object.assign(obj, {
          cast_id: v.id, // score_total に変更後は v.cast_id
          site_member_follow_casts_count: v.site_member_follow_casts_count,
        });
        return obj;
      });
      return casts;
    },
    /**
     * キャストIDを元にfollowerCountDataの重複を削除してユニークにする
     * baseData: storeのfollowerCountDataから取得したデータ
     * addData: storeのfollowerCountDataに追加したいデータ
     */
    uniqueFollowerCountData: function (baseData, addData) {
      const concatData = baseData.concat(addData);
      const unique = concatData.filter(
        ({
          cast_id
        }, i) =>
        i === concatData.findIndex((e) => e.cast_id === cast_id)
      );
      return unique;
    },
    /**
     * 「フォロー」ボタンクリックでbindCastを変更する
     *  bindCasts: object storeのbindCast
     *  castId: number 変更対象CastId
     *  isFollow: boolean お気に入り追加かどうか
     */
    updateSiteMemberFollowBindCasts: function (
      bindCasts,
      siteMemberId,
      isFollow
    ) {
      const bind = JSON.parse(JSON.stringify(bindCasts));
      const casts = _.map(bind, (v) => {
        if (Number(v.site_member.id) === Number(siteMemberId)) {
          v.site_member.is_following = isFollow;
        }
        return v;
      });
      return casts;
    },
    /**
     * ジャンル取得
     * @param {*} params
     * @returns
     */
    async getBusinessTypes(params = {}) {
      let results = [];
      await this.$axios
        .post('public/get-business-types', params, {})
        .then((response) => {
          results = response.data.data;
        });

      let businessTypes = this.$t('business_types');
      results.forEach(function (genre, key) {
        if (businessTypes[genre.id]) {
          genre.name = businessTypes[genre.id]['name'];
        }
      });
      return results;
    },
    /**
     * 店舗一覧
     * @param {*} params
     * @returns
     */
    async getShops(params = {}) {
      return await this.$axios
        .post('public/get-shops-list2', params, {})
        .then((response) => {
          let results = [];
          const target = response.data.shop_group;
          for (const prefKey in target) {
            const shopGroupByPrefKey = target[prefKey];
            for (const key2 in shopGroupByPrefKey) {
              for (const shop of shopGroupByPrefKey[key2]) {
                results.push(shop);
              }
            }
          }
          return results;
        });
    },
    getCastEnrolledFlag: function (cast) {
      return cast.site_public_flag && cast.site_member_site_public_flag;
    },
    /**
     * 背景を固定する or　元に戻す
     */
    fixModalBackground(fixed) {
      const scrollbarWidth = window.innerWidth - document.body.clientWidth;
      document.body.style.paddingRight = fixed ? `${scrollbarWidth}px` : '';

      // スクロール位置の要素を取得
      const scrollingElement = () => {
        const browser = window.navigator.userAgent.toLowerCase();
        if ('scrollingElement' in document) return document.scrollingElement;
        if (browser.indexOf('webkit') > 0) return document.body;
        return document.documentElement;
      };

      const scrollY = fixed ?
        scrollingElement().scrollTop :
        parseInt(document.body.style.top || '0');

      // モーダルの後ろ側を固定するCSS
      const styles = {
        height: '100vh',
        left: '0',
        overflow: 'hidden',
        position: 'fixed',
        top: `${scrollY * -1}px`,
        width: '100vw',
      };

      Object.keys(styles).forEach((key) => {
        document.body.style[key] = fixed ? styles[key] : '';
      });

      // モーダルと閉じた時に定位置に戻す
      if (!fixed) window.scrollTo(0, scrollY * -1);
    },
    /**
     * シングルクォーテーションなどの文字化け対応
     */
    unescapeString(targetString) {
      return String(targetString)
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&quot;/g, '"')
        .replace(/&#039;/g, "'")
        .replace(/&#39;/g, "'"); // インバウンド用対応
    },
    /**
     * cm -> inch 変換
     */
    cmToInch(cm) {
      return Math.round(cm * 0.39370);
    },
    /**
     * cm -> feet/inches 変換
     */
    cmToFeetAndInches(cm) {
      var inches = cm * 0.39370;
      var feet = Math.floor(inches / 12);
      inches = Math.round(inches % 12);
      return feet + "′" + inches + "″";
    },
    // サイト会員にAuth0Idを保存
    saveAuth0Id(email, auth0_id) {
      this.$axios.post('private/save-auth0-id', {'email':email, 'auth0_id': auth0_id})
        .then(response => {
        })
        .catch(error => {
          console.error('Error sending user data to API:', error);
        });
    }
  },
});