<template>
  <div class="d-flex align-center">
    <div v-if="!loading">
      <component 
        v-if="!loadingComplete" 
        @click="getMoreData()" 
        :is="btnName"
      />
    </div>
    <div v-else style="width: 146px;">
      <loading-image />
    </div>
  </div>
</template>

<script>
import btnMore from '~/assets/img/btn-more.svg';
import btnMore2 from '~/assets/img/btn-more-2.svg';
import btnEnd1 from '~/assets/img/btn-end-1.svg';
import btnEnd2 from '~/assets/img/btn-end-2.svg';
import btnEnd3 from '~/assets/img/btn-end-3.svg';

export default {
  components: {
    btnMore,
    btnMore2,
    btnEnd1,
    btnEnd2,
    btnEnd3,
  },
  props: {
    loading: {
      type: Boolean
    },
    loadingComplete: {
      type: Boolean
    },
    btnName: {
      type: String
    }
  },
  methods: {
    getMoreData() {
      if (this.btnName === 'btnEnd1' || this.btnName === 'btnEnd2') {
        return;
      }
      this.$emit('getMoreData');
    }
  }
}
</script>

<style lang="scss" scoped>
.see_more {
  cursor: pointer;
  align-items: center;
  background-color: var(--sub-color);
  border-radius: 10px;
  color: white;
  display: flex;
  font-weight: bold;
  justify-content: center;
  margin:5px 10px 5px 5px;
  width: 160px;

  &:hover {
    background-color: #f7b2c9 !important;
  }
}
.last_card {
  align-items: center;
  background-color: var(--read-font-color-sub);
  border-radius: 10px;
  color: white;
  display: flex;
  font-weight: bold;
  justify-content: center;
  margin:5px 10px 5px 5px;
  padding: 5px;
  width: 160px;
  white-space: inherit;
  line-height: 2.5em;
  font-size: 15px;
}
</style>