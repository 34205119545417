import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _66a55f32 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _6ca40fd2 = () => interopDefault(import('../pages/affiliate/index.vue' /* webpackChunkName: "pages/affiliate/index" */))
const _eb807be2 = () => interopDefault(import('../pages/call/index.vue' /* webpackChunkName: "pages/call/index" */))
const _7d1a181a = () => interopDefault(import('../pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _62586a89 = () => interopDefault(import('../pages/chat/index.vue' /* webpackChunkName: "pages/chat/index" */))
const _a8c09d4c = () => interopDefault(import('../pages/email-verification.vue' /* webpackChunkName: "pages/email-verification" */))
const _c0398108 = () => interopDefault(import('../pages/email-verification-complete.vue' /* webpackChunkName: "pages/email-verification-complete" */))
const _6c45a086 = () => interopDefault(import('../pages/gateway.vue' /* webpackChunkName: "pages/gateway" */))
const _01339732 = () => interopDefault(import('../pages/help/index.vue' /* webpackChunkName: "pages/help/index" */))
const _738091ab = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _49a2ac38 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _fa7937a8 = () => interopDefault(import('../pages/mypage/index.vue' /* webpackChunkName: "pages/mypage/index" */))
const _880daaa6 = () => interopDefault(import('../pages/photo-diary/index.vue' /* webpackChunkName: "pages/photo-diary/index" */))
const _65dc38e5 = () => interopDefault(import('../pages/questionnaire.vue' /* webpackChunkName: "pages/questionnaire" */))
const _45eeb95e = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _08bb3b01 = () => interopDefault(import('../pages/reserve/index.vue' /* webpackChunkName: "pages/reserve/index" */))
const _53d6acc9 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _05f3d34c = () => interopDefault(import('../pages/account/home-deli.vue' /* webpackChunkName: "pages/account/home-deli" */))
const _51b478a4 = () => interopDefault(import('../pages/account/mail-address.vue' /* webpackChunkName: "pages/account/mail-address" */))
const _21f4a80a = () => interopDefault(import('../pages/account/mail-setting.vue' /* webpackChunkName: "pages/account/mail-setting" */))
const _2f7bb504 = () => interopDefault(import('../pages/account/profile-edit.vue' /* webpackChunkName: "pages/account/profile-edit" */))
const _18a6a991 = () => interopDefault(import('../pages/call/mixin.js' /* webpackChunkName: "pages/call/mixin" */))
const _6977593b = () => interopDefault(import('../pages/call/search.vue' /* webpackChunkName: "pages/call/search" */))
const _08044223 = () => interopDefault(import('../pages/call/step2.vue' /* webpackChunkName: "pages/call/step2" */))
const _081259a4 = () => interopDefault(import('../pages/call/step3.vue' /* webpackChunkName: "pages/call/step3" */))
const _457fe999 = () => interopDefault(import('../pages/chat/operationRoom.vue' /* webpackChunkName: "pages/chat/operationRoom" */))
const _6a434e18 = () => interopDefault(import('../pages/help/etc.vue' /* webpackChunkName: "pages/help/etc" */))
const _3fd78e40 = () => interopDefault(import('../pages/help/qa.vue' /* webpackChunkName: "pages/help/qa" */))
const _6b7eefa2 = () => interopDefault(import('../pages/reserve/confirm.vue' /* webpackChunkName: "pages/reserve/confirm" */))
const _2d3f4a5f = () => interopDefault(import('../pages/reserve/mixin.js' /* webpackChunkName: "pages/reserve/mixin" */))
const _0fb886bb = () => interopDefault(import('../pages/sp/lp/index.vue' /* webpackChunkName: "pages/sp/lp/index" */))
const _c06e9f1e = () => interopDefault(import('../pages/cast/photo-diary/_id.vue' /* webpackChunkName: "pages/cast/photo-diary/_id" */))
const _8f207672 = () => interopDefault(import('../pages/invoice/reserve/_id.vue' /* webpackChunkName: "pages/invoice/reserve/_id" */))
const _006ae7d0 = () => interopDefault(import('../pages/cast/_id.vue' /* webpackChunkName: "pages/cast/_id" */))
const _2c2e09a9 = () => interopDefault(import('../pages/reserve/_id.vue' /* webpackChunkName: "pages/reserve/_id" */))
const _7dcfb0a4 = () => interopDefault(import('../pages/user/_id.vue' /* webpackChunkName: "pages/user/_id" */))
const _ac4e22d8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
      path: "/en/account",
      component: _66a55f32,
      name: "account___en"
    }, {
      path: "/zh-cn/account",
      component: _66a55f32,
      name: "account___zh-cn"
    }, {
      path: "/zh-tw/account",
      component: _66a55f32,
      name: "account___zh-tw"
    }, {
      path: "/ko/account",
      component: _66a55f32,
      name: "account___ko"
    }, {
      path: "/en/affiliate",
      component: _6ca40fd2,
      name: "affiliate___en"
    }, {
      path: "/zh-cn/affiliate",
      component: _6ca40fd2,
      name: "affiliate___zh-cn"
    }, {
      path: "/zh-tw/affiliate",
      component: _6ca40fd2,
      name: "affiliate___zh-tw"
    }, {
      path: "/ko/affiliate",
      component: _6ca40fd2,
      name: "affiliate___ko"
    }, {
      path: "/en/call",
      component: _eb807be2,
      name: "call___en"
    }, {
      path: "/zh-cn/call",
      component: _eb807be2,
      name: "call___zh-cn"
    }, {
      path: "/zh-tw/call",
      component: _eb807be2,
      name: "call___zh-tw"
    }, {
      path: "/ko/call",
      component: _eb807be2,
      name: "call___ko"
    }, {
      path: "/en/callback",
      component: _7d1a181a,
      name: "callback___en"
    }, {
      path: "/zh-cn/callback",
      component: _7d1a181a,
      name: "callback___zh-cn"
    }, {
      path: "/zh-tw/callback",
      component: _7d1a181a,
      name: "callback___zh-tw"
    }, {
      path: "/ko/callback",
      component: _7d1a181a,
      name: "callback___ko"
    }, {
      path: "/en/chat",
      component: _62586a89,
      name: "chat___en"
    }, {
      path: "/zh-cn/chat",
      component: _62586a89,
      name: "chat___zh-cn"
    }, {
      path: "/zh-tw/chat",
      component: _62586a89,
      name: "chat___zh-tw"
    }, {
      path: "/ko/chat",
      component: _62586a89,
      name: "chat___ko"
    }, {
      path: "/en/email-verification",
      component: _a8c09d4c,
      name: "email-verification___en"
    }, {
      path: "/zh-cn/email-verification",
      component: _a8c09d4c,
      name: "email-verification___zh-cn"
    }, {
      path: "/zh-tw/email-verification",
      component: _a8c09d4c,
      name: "email-verification___zh-tw"
    }, {
      path: "/ko/email-verification",
      component: _a8c09d4c,
      name: "email-verification___ko"
    }, {
      path: "/en/email-verification-complete",
      component: _c0398108,
      name: "email-verification-complete___en"
    }, {
      path: "/zh-cn/email-verification-complete",
      component: _c0398108,
      name: "email-verification-complete___zh-cn"
    }, {
      path: "/zh-tw/email-verification-complete",
      component: _c0398108,
      name: "email-verification-complete___zh-tw"
    }, {
      path: "/ko/email-verification-complete",
      component: _c0398108,
      name: "email-verification-complete___ko"
    }, {
      path: "/en/gateway",
      component: _6c45a086,
      name: "gateway___en"
    }, {
      path: "/zh-cn/gateway",
      component: _6c45a086,
      name: "gateway___zh-cn"
    }, {
      path: "/zh-tw/gateway",
      component: _6c45a086,
      name: "gateway___zh-tw"
    }, {
      path: "/ko/gateway",
      component: _6c45a086,
      name: "gateway___ko"
    }, {
      path: "/en/help",
      component: _01339732,
      name: "help___en"
    }, {
      path: "/zh-cn/help",
      component: _01339732,
      name: "help___zh-cn"
    }, {
      path: "/zh-tw/help",
      component: _01339732,
      name: "help___zh-tw"
    }, {
      path: "/ko/help",
      component: _01339732,
      name: "help___ko"
    }, {
      path: "/en/login",
      component: _738091ab,
      name: "login___en"
    }, {
      path: "/zh-cn/login",
      component: _738091ab,
      name: "login___zh-cn"
    }, {
      path: "/zh-tw/login",
      component: _738091ab,
      name: "login___zh-tw"
    }, {
      path: "/ko/login",
      component: _738091ab,
      name: "login___ko"
    }, {
      path: "/en/logout",
      component: _49a2ac38,
      name: "logout___en"
    }, {
      path: "/zh-cn/logout",
      component: _49a2ac38,
      name: "logout___zh-cn"
    }, {
      path: "/zh-tw/logout",
      component: _49a2ac38,
      name: "logout___zh-tw"
    }, {
      path: "/ko/logout",
      component: _49a2ac38,
      name: "logout___ko"
    }, {
      path: "/en/mypage",
      component: _fa7937a8,
      name: "mypage___en"
    }, {
      path: "/zh-cn/mypage",
      component: _fa7937a8,
      name: "mypage___zh-cn"
    }, {
      path: "/zh-tw/mypage",
      component: _fa7937a8,
      name: "mypage___zh-tw"
    }, {
      path: "/ko/mypage",
      component: _fa7937a8,
      name: "mypage___ko"
    }, {
      path: "/en/photo-diary",
      component: _880daaa6,
      name: "photo-diary___en"
    }, {
      path: "/zh-cn/photo-diary",
      component: _880daaa6,
      name: "photo-diary___zh-cn"
    }, {
      path: "/zh-tw/photo-diary",
      component: _880daaa6,
      name: "photo-diary___zh-tw"
    }, {
      path: "/ko/photo-diary",
      component: _880daaa6,
      name: "photo-diary___ko"
    }, {
      path: "/en/questionnaire",
      component: _65dc38e5,
      name: "questionnaire___en"
    }, {
      path: "/zh-cn/questionnaire",
      component: _65dc38e5,
      name: "questionnaire___zh-cn"
    }, {
      path: "/zh-tw/questionnaire",
      component: _65dc38e5,
      name: "questionnaire___zh-tw"
    }, {
      path: "/ko/questionnaire",
      component: _65dc38e5,
      name: "questionnaire___ko"
    }, {
      path: "/en/register",
      component: _45eeb95e,
      name: "register___en"
    }, {
      path: "/zh-cn/register",
      component: _45eeb95e,
      name: "register___zh-cn"
    }, {
      path: "/zh-tw/register",
      component: _45eeb95e,
      name: "register___zh-tw"
    }, {
      path: "/ko/register",
      component: _45eeb95e,
      name: "register___ko"
    }, {
      path: "/en/reserve",
      component: _08bb3b01,
      name: "reserve___en"
    }, {
      path: "/zh-cn/reserve",
      component: _08bb3b01,
      name: "reserve___zh-cn"
    }, {
      path: "/zh-tw/reserve",
      component: _08bb3b01,
      name: "reserve___zh-tw"
    }, {
      path: "/ko/reserve",
      component: _08bb3b01,
      name: "reserve___ko"
    }, {
      path: "/en/terms",
      component: _53d6acc9,
      name: "terms___en"
    }, {
      path: "/zh-cn/terms",
      component: _53d6acc9,
      name: "terms___zh-cn"
    }, {
      path: "/zh-tw/terms",
      component: _53d6acc9,
      name: "terms___zh-tw"
    }, {
      path: "/ko/terms",
      component: _53d6acc9,
      name: "terms___ko"
    }, {
      path: "/en/account/home-deli",
      component: _05f3d34c,
      name: "account-home-deli___en"
    }, {
      path: "/zh-cn/account/home-deli",
      component: _05f3d34c,
      name: "account-home-deli___zh-cn"
    }, {
      path: "/zh-tw/account/home-deli",
      component: _05f3d34c,
      name: "account-home-deli___zh-tw"
    }, {
      path: "/ko/account/home-deli",
      component: _05f3d34c,
      name: "account-home-deli___ko"
    }, {
      path: "/en/account/mail-address",
      component: _51b478a4,
      name: "account-mail-address___en"
    }, {
      path: "/zh-cn/account/mail-address",
      component: _51b478a4,
      name: "account-mail-address___zh-cn"
    }, {
      path: "/zh-tw/account/mail-address",
      component: _51b478a4,
      name: "account-mail-address___zh-tw"
    }, {
      path: "/ko/account/mail-address",
      component: _51b478a4,
      name: "account-mail-address___ko"
    }, {
      path: "/en/account/mail-setting",
      component: _21f4a80a,
      name: "account-mail-setting___en"
    }, {
      path: "/zh-cn/account/mail-setting",
      component: _21f4a80a,
      name: "account-mail-setting___zh-cn"
    }, {
      path: "/zh-tw/account/mail-setting",
      component: _21f4a80a,
      name: "account-mail-setting___zh-tw"
    }, {
      path: "/ko/account/mail-setting",
      component: _21f4a80a,
      name: "account-mail-setting___ko"
    }, {
      path: "/en/account/profile-edit",
      component: _2f7bb504,
      name: "account-profile-edit___en"
    }, {
      path: "/zh-cn/account/profile-edit",
      component: _2f7bb504,
      name: "account-profile-edit___zh-cn"
    }, {
      path: "/zh-tw/account/profile-edit",
      component: _2f7bb504,
      name: "account-profile-edit___zh-tw"
    }, {
      path: "/ko/account/profile-edit",
      component: _2f7bb504,
      name: "account-profile-edit___ko"
    }, {
      path: "/en/call/mixin",
      component: _18a6a991,
      name: "call-mixin___en"
    }, {
      path: "/zh-cn/call/mixin",
      component: _18a6a991,
      name: "call-mixin___zh-cn"
    }, {
      path: "/zh-tw/call/mixin",
      component: _18a6a991,
      name: "call-mixin___zh-tw"
    }, {
      path: "/ko/call/mixin",
      component: _18a6a991,
      name: "call-mixin___ko"
    }, {
      path: "/en/call/search",
      component: _6977593b,
      name: "call-search___en"
    }, {
      path: "/zh-cn/call/search",
      component: _6977593b,
      name: "call-search___zh-cn"
    }, {
      path: "/zh-tw/call/search",
      component: _6977593b,
      name: "call-search___zh-tw"
    }, {
      path: "/ko/call/search",
      component: _6977593b,
      name: "call-search___ko"
    }, {
      path: "/en/call/step2",
      component: _08044223,
      name: "call-step2___en"
    }, {
      path: "/zh-cn/call/step2",
      component: _08044223,
      name: "call-step2___zh-cn"
    }, {
      path: "/zh-tw/call/step2",
      component: _08044223,
      name: "call-step2___zh-tw"
    }, {
      path: "/ko/call/step2",
      component: _08044223,
      name: "call-step2___ko"
    }, {
      path: "/en/call/step3",
      component: _081259a4,
      name: "call-step3___en"
    }, {
      path: "/zh-cn/call/step3",
      component: _081259a4,
      name: "call-step3___zh-cn"
    }, {
      path: "/zh-tw/call/step3",
      component: _081259a4,
      name: "call-step3___zh-tw"
    }, {
      path: "/ko/call/step3",
      component: _081259a4,
      name: "call-step3___ko"
    }, {
      path: "/en/chat/operationRoom",
      component: _457fe999,
      name: "chat-operationRoom___en"
    }, {
      path: "/zh-cn/chat/operationRoom",
      component: _457fe999,
      name: "chat-operationRoom___zh-cn"
    }, {
      path: "/zh-tw/chat/operationRoom",
      component: _457fe999,
      name: "chat-operationRoom___zh-tw"
    }, {
      path: "/ko/chat/operationRoom",
      component: _457fe999,
      name: "chat-operationRoom___ko"
    }, {
      path: "/en/help/etc",
      component: _6a434e18,
      name: "help-etc___en"
    }, {
      path: "/zh-cn/help/etc",
      component: _6a434e18,
      name: "help-etc___zh-cn"
    }, {
      path: "/zh-tw/help/etc",
      component: _6a434e18,
      name: "help-etc___zh-tw"
    }, {
      path: "/ko/help/etc",
      component: _6a434e18,
      name: "help-etc___ko"
    }, {
      path: "/en/help/qa",
      component: _3fd78e40,
      name: "help-qa___en"
    }, {
      path: "/zh-cn/help/qa",
      component: _3fd78e40,
      name: "help-qa___zh-cn"
    }, {
      path: "/zh-tw/help/qa",
      component: _3fd78e40,
      name: "help-qa___zh-tw"
    }, {
      path: "/ko/help/qa",
      component: _3fd78e40,
      name: "help-qa___ko"
    }, {
      path: "/en/reserve/confirm",
      component: _6b7eefa2,
      name: "reserve-confirm___en"
    }, {
      path: "/zh-cn/reserve/confirm",
      component: _6b7eefa2,
      name: "reserve-confirm___zh-cn"
    }, {
      path: "/zh-tw/reserve/confirm",
      component: _6b7eefa2,
      name: "reserve-confirm___zh-tw"
    }, {
      path: "/ko/reserve/confirm",
      component: _6b7eefa2,
      name: "reserve-confirm___ko"
    }, {
      path: "/en/reserve/mixin",
      component: _2d3f4a5f,
      name: "reserve-mixin___en"
    }, {
      path: "/zh-cn/reserve/mixin",
      component: _2d3f4a5f,
      name: "reserve-mixin___zh-cn"
    }, {
      path: "/zh-tw/reserve/mixin",
      component: _2d3f4a5f,
      name: "reserve-mixin___zh-tw"
    }, {
      path: "/ko/reserve/mixin",
      component: _2d3f4a5f,
      name: "reserve-mixin___ko"
    }, {
      path: "/en/sp/lp",
      component: _0fb886bb,
      name: "sp-lp___en"
    }, {
      path: "/zh-cn/sp/lp",
      component: _0fb886bb,
      name: "sp-lp___zh-cn"
    }, {
      path: "/zh-tw/sp/lp",
      component: _0fb886bb,
      name: "sp-lp___zh-tw"
    }, {
      path: "/ko/sp/lp",
      component: _0fb886bb,
      name: "sp-lp___ko"
    }, {
      path: "/en/cast/photo-diary/:id?",
      component: _c06e9f1e,
      name: "cast-photo-diary-id___en"
    }, {
      path: "/zh-cn/cast/photo-diary/:id?",
      component: _c06e9f1e,
      name: "cast-photo-diary-id___zh-cn"
    }, {
      path: "/zh-tw/cast/photo-diary/:id?",
      component: _c06e9f1e,
      name: "cast-photo-diary-id___zh-tw"
    }, {
      path: "/ko/cast/photo-diary/:id?",
      component: _c06e9f1e,
      name: "cast-photo-diary-id___ko"
    }, {
      path: "/en/invoice/reserve/:id?",
      component: _8f207672,
      name: "invoice-reserve-id___en"
    }, {
      path: "/zh-cn/invoice/reserve/:id?",
      component: _8f207672,
      name: "invoice-reserve-id___zh-cn"
    }, {
      path: "/zh-tw/invoice/reserve/:id?",
      component: _8f207672,
      name: "invoice-reserve-id___zh-tw"
    }, {
      path: "/ko/invoice/reserve/:id?",
      component: _8f207672,
      name: "invoice-reserve-id___ko"
    }, {
      path: "/en/cast/:id?",
      component: _006ae7d0,
      name: "cast-id___en"
    }, {
      path: "/zh-cn/cast/:id?",
      component: _006ae7d0,
      name: "cast-id___zh-cn"
    }, {
      path: "/zh-tw/cast/:id?",
      component: _006ae7d0,
      name: "cast-id___zh-tw"
    }, {
      path: "/ko/cast/:id?",
      component: _006ae7d0,
      name: "cast-id___ko"
    }, {
      path: "/en/reserve/:id",
      component: _2c2e09a9,
      name: "reserve-id___en"
    }, {
      path: "/zh-cn/reserve/:id",
      component: _2c2e09a9,
      name: "reserve-id___zh-cn"
    }, {
      path: "/zh-tw/reserve/:id",
      component: _2c2e09a9,
      name: "reserve-id___zh-tw"
    }, {
      path: "/ko/reserve/:id",
      component: _2c2e09a9,
      name: "reserve-id___ko"
    }, {
      path: "/en/user/:id?",
      component: _7dcfb0a4,
      name: "user-id___en"
    }, {
      path: "/zh-cn/user/:id?",
      component: _7dcfb0a4,
      name: "user-id___zh-cn"
    }, {
      path: "/zh-tw/user/:id?",
      component: _7dcfb0a4,
      name: "user-id___zh-tw"
    }, {
      path: "/ko/user/:id?",
      component: _7dcfb0a4,
      name: "user-id___ko"
    }, {
      path: "/en",
      component: _ac4e22d8,
      name: "index___en"
    }, {
      path: "/zh-cn",
      component: _ac4e22d8,
      name: "index___zh-cn"
    }, {
      path: "/zh-tw",
      component: _ac4e22d8,
      name: "index___zh-tw"
    }, {
      path: "/ko",
      component: _ac4e22d8,
      name: "index___ko"
    }],

  fallback: false
}

export function createRouter() {
  return new Router(routerOptions)
}
