<template>
  <v-app>
    <!-- Layout Component: CommonHeader -->
    <common-header v-if="isCommonHeader === true" />
    <!-- PC Page Contents -->
    <nuxt :class="contentsStyle" id="contents-border" :style="this.nuxtStyle" />
    <!-- [ログアウト時のみ] 新規登録/ログインFixボタン-->
    <client-only>
      <SignInButton
        v-if="
          this.$store.state.auth.loggedIn === false &&
          this.$route.path !== `/${this.$i18n.locale}/login` &&
          this.$route.path !== `/${this.$i18n.locale}/email-verification` &&
          this.$route.path !==
            `/${this.$i18n.locale}/email-verification-complete` &&
          this.$route.path !== `/${this.$i18n.locale}/register` &&
          this.$route.path !== `/${this.$i18n.locale}/questionnaire` &&
          this.$route.path !== `/${this.$i18n.locale}/event` &&
          this.$route.path !== `/${this.$i18n.locale}/error` &&
          this.$route.path !== `/${this.$i18n.locale}/terms` &&
          this.$route.path !== `/${this.$i18n.locale}/call/step2` &&
          this.$route.path !== `/${this.$i18n.locale}/call/search`
        "
      />
    </client-only>
    <!-- Layout Component: CommonNav -->
    <common-nav
      v-if="
        this.$route.path !== `/${this.$i18n.locale}/login` &&
        this.$route.path !== `/${this.$i18n.locale}/email-verification` &&
        this.$route.path !==
          `${this.$i18n.locale}/email-verification-complete` &&
        this.$route.path !== `/${this.$i18n.locale}/register` &&
        this.$route.path !== `/${this.$i18n.locale}/questionnaire` &&
        this.$route.path !== `/${this.$i18n.locale}/event` &&
        this.$route.path !== `/${this.$i18n.locale}/error` &&
        this.$route.path !== `/${this.$i18n.locale}/terms` &&
        this.$route.path !== `/${this.$i18n.locale}/call/step2` &&
        this.$route.path !== `/${this.$i18n.locale}/call/search` &&
        this.$route.path !== `/${this.$i18n.locale}/call2/search` &&
        this.$route.path !== `/${this.$i18n.locale}/call3/search` &&
        this.$route.path !== `/${this.$i18n.locale}/call4/search` &&
        !this.$route.path.match('/chat/') &&
        !this.$store.state.castDialog.selectedCastProfileDialog &&
        !this.$store.state.castDialog.selectedCastReserveDialog
      "
    />
    <div
      v-if="
        this.$route.path !== `/${this.$i18n.locale}/login` &&
        this.$route.path !== `/${this.$i18n.locale}/email-verification` &&
        this.$route.path !== `/${this.$i18n.locale}/email-verification-complete`
      "
    >
      <!-- UI Component: LoadingLogin -->
      <loading-login />
      <!-- UI Component: LoadingLogout -->
      <loading-logout />
      <!-- UI Component: LoginErrorDialog -->
      <login-error-dialog />
    </div>
    <!-- FollowSiteMemberLimitDialog -->
    <FollowSiteMemberLimitDialog />
  </v-app>
</template>

<script>
import CommonHeader from '~/components/layouts/CommonHeader.vue';
import CommonNav from '~/components/layouts/CommonNav.vue';
import PcContents from '~/components/layouts/PcContents.vue';
import LoginErrorDialog from '~/components/pages/dialog/LoginErrorDialog.vue';
import SignInButton from '~/components/ui/button/SignInButton.vue';
import FollowSiteMemberLimitDialog from '~/components/pages/dialog/FollowSiteMemberLimitDialog.vue';

export default {
  components: {
    CommonHeader,
    CommonNav,
    PcContents,
    LoginErrorDialog,
    SignInButton,
    FollowSiteMemberLimitDialog,
  },
  computed: {
    nuxtStyle: function () {
      return this.$route.name === 'photo-diary'
        ? 'padding-top:40px !important;'
        : '';
    },
    isCommonHeader: function () {
      if (
        this.$route.path !== `/${this.$i18n.locale}/login` &&
        this.$route.path !== `/${this.$i18n.locale}/email-verification` &&
        !this.$route.path.match(/email-verification-complete/) &&
        this.$route.path !== `/${this.$i18n.locale}/register` &&
        this.$route.path !== `/${this.$i18n.locale}/questionnaire` &&
        this.$route.path !== `/${this.$i18n.locale}/event` &&
        !this.$route.path.match('/cast/photo-diary/') &&
        !this.$store.state.castDialog.selectedCastProfileDialog &&
        !this.$store.state.castDialog.selectedCastReserveDialog
      ) {
        return true;
      } else {
        return false;
      }
    },
    contentsStyle: function () {
      if (this.isCommonHeader === true) {
        return 'contents1';
      } else {
        return 'contents2';
      }
    },
  },
  data() {
    return {
      Env: process.env.APP_ENV,
      transitionCount: 0,
    };
  },
  mounted: function () {},
  fetch: function ({ store, redirect }) {
    // ローディングを初期化
    store.dispatch('loading/save', false);
    store.dispatch('loading/post', false);
    store.dispatch('loading/delete', false);
    store.dispatch('loading/change', false);
    store.dispatch('loading/login', false);
    store.dispatch('loading/logout', false);
    // ダイアログを初期化
    store.dispatch('dialog/loginError', false);
  },
  watch: {
    $route: function (to, from) {
      if (this.transitionCount == 1 && to.query.url_hash) {
        this.transitionCount = 0;
        history.replaceState(
          null,
          document.getElementsByTagName('title')[0].innerHTML,
          null
        );
        window.addEventListener('popstate', this.backLink(), false);
      }
      if (to.path !== from.path) {
        this.transitionCount = 0;
        this.closeCastDialog(to.path, 'transition');
        if (this.$route.query.url_hash) {
          this.toCastProfileDialog({ url_hash: this.$route.query.url_hash });
          this.transitionCount = 1;
          this.$router.push({ query: {} });
        }
      }
    },
  },
  methods: {
    backLink() {
      this.$router.go(-2);
    },
  },
};
</script>

<style scoped>
.contents1 {
  height: 100%;
  width: 100%;
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 0px calc(56px + env(safe-area-inset-bottom)) !important;
}
.contents2 {
  height: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 1024px) {
  #contents-border {
    border-left: 1px solid rgb(221, 221, 221);
    border-right: 1px solid rgb(221, 221, 221);
  }
}
</style>
