/**
 * ランキング情報保持
 * ページ遷移時にランキングの情報を維持する
 */

export const state = () => ({
  // ランキングデータ
  helpfullRankings: [],
  castReviewRankings: [],
  followerRankings: [],
  // // 選択肢の初期値
  // selectedTermType: {value: 1, label: '前日までの30日間'},
  // selectedBusinessTypeId: {value: 0, label: 'すべて'},
  // selectedPrefectureId: {value: 0, label: 'すべて'},
  // // 選択肢
  // termTypeItems: [
  //   {value: 1, label: '前日までの30日間'},
  //   {value: 2, label: '前日までの90日間'},
  //   {value: 3, label: '累計'},
  // ],
  // businessTypeItems: [
  //   {value: 0, label: 'すべて'},
  //   {value: 1, label: 'デリヘル'},
  //   {value: 2, label: 'エステ'},
  //   {value: 3, label: 'オナクラ'},
  // ],
  // prefectureItems: [
  //   {value: 0, label: 'すべて'},
  //   {value: 13, label: '東京都'},
  //   {value: 14, label: '神奈川県'},
  //   {value: 11, label: '埼玉'},
  //   {value: 12, label: '千葉'},
  // ],
});

export const mutations = {
  // ランキングデータ
  saveHelpfullRankings(state, data) {
    state.helpfullRankings = data;
  },
  saveCastReviewRankings(state, data) {
    state.castReviewRankings = data;
  },
  saveFollowerRankings(state, data) {
    state.followerRankings = data;
  },
  // 選択肢データ
  saveSelectedTermType(state, data) {
    state.selectedTermType = data;
  },
  saveSelectedBusinessTypeId(state, data) {
    state.selectedBusinessTypeId = data;
  },
  saveSelectedPrefectureId(state, data) {
    state.selectedPrefectureId = data;
  },
};

export const actions = {
  commitHelpfullRankings(context, data) {
    context.commit('saveHelpfullRankings', data);
  },
  commitCastReviewRankings(context, data) {
    context.commit('saveCastReviewRankings', data);
  },
  commitFollowerRankings(context, data) {
    context.commit('saveFollowerRankings', data);
  },
  commitSelectedTermType(context, data) {
    context.commit('saveSelectedTermType', data);
  },
  commitSelectedBusinessTypeId(context, data) {
    context.commit('saveSelectedBusinessTypeId', data);
  },
  commitSelectedPrefectureId(context, data) {
    context.commit('saveSelectedPrefectureId', data);
  },
};
