import Vue from 'vue'
import loadImage from 'blueimp-load-image'
const moment = require('moment')

Vue.mixin({
  methods: {
    /**
     * 写メ日記限定公開
     */
    getScopeClass(scope, isBig = false) {
      return this.isOutScope(scope)
        ? `scope_${scope} scope_out${isBig ? '_big' : ''}`
        : `scope_${scope}`;
    },
    /**
     * 写メ日記限定公開
     */
    getScopeLabel(scope) {
      if (scope === 1) return this.$t('text_f3o8');
      // if (scope === 2) return '本指名3回以上かつ\nお気に入り登録';
      // if (scope === 3) return '本指名3か月以内かつ\nお気に入り登録';
      return '';
    },
    /**
     * 写メ日記限定公開
     */
    isOutScope(scope) {
      const favoriteCast = this.cast.site_members.find(
        (sm) => sm.id === this.$store.state.siteMemberData.id
      );
      if (favoriteCast) {
        if (scope === 1) {
          // お気に入り登録10日以上
          return !(moment().diff(moment(favoriteCast.follow_at), 'days') >= 10);
        } else if (scope === 2) {
          // 本指名3回以上
          return !(this.cast.reservations.length >= 3);
        } else if (scope === 3) {
          // 本指名3か月以内
          return this.cast.reservations.length > 0
            ? !(
                moment().diff(
                  moment(this.cast.reservations[0].date),
                  'months'
                ) <= 3
              )
            : true;
        }
      }
      return scope !== 0;
    },
    /**
     * いいね追加
     */
    likeArticle: function (article_id, index) {
      if (this.$store.state.auth.loggedIn === false) {
        this.isGuestDialog($t('login_text_5'));
        return;
      }
      const cache_key = this.cast.cacheKey;
      if (this.$store.state.siteMemberData.likedPhotoDiaryLimit) {
        this.$store.dispatch('dialog/saveLikedPhotoDiaryLimitDialogFlag', true);
      } else {
        this.$store.dispatch('bindCast/likePhotoArticleCastData', {
          index: index,
          siteMemberId: this.$store.state.siteMemberData.id,
          siteMemberNickname: this.$store.state.siteMemberData.nickname,
          createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
        });
        this.$axios
          .post(
            'private/like-cast-photo-diary-article',
            {
              email: this.$store.state.auth.user.email,
              article_id: article_id,
              shop_id: this.cast.shop_id,
              t_id: this.cast.talent_id, // 「talent」の文字は使わない
              c_id: this.cast.id,
              cache_key: cache_key,
            },
            {
              headers: {
                Authorization: this.$auth.$storage.getUniversal(
                  '_token.auth0',
                  true
                ),
              },
            }
          )
          .then((response) => {
            if (response.data.completeFlag) {
              this.$store.dispatch('siteMemberData/saveLikedPhotoDiaryLimit', {
                likedPhotoDiaryLimit: response.data.likedPhotoDiaryLimit,
              });
            }
          })
          .catch((error) => {});
      }
      this.$emit('getLikedNicknames', this.photoDiaryArticles);
    },
    /**
     * いいね解除
     */
    removeLikeArticle: function (article_id, index) {
      if (this.$store.state.auth.loggedIn === false) {
        return;
      }
      const cache_key = this.cast.cacheKey;
      this.$store.dispatch('bindCast/removeLikePhotoArticleCastData', {
        index: index,
        siteMemberId: this.$store.state.siteMemberData.id,
      });
      this.$axios
        .post(
          'private/remove-like-cast-photo-diary-article',
          {
            email: this.$store.state.auth.user.email,
            article_id: article_id,
            shop_id: this.cast.shop_id,
            t_id: this.cast.talent_id, // 「talent」の文字は使わない
            c_id: this.cast.id,
            cache_key: cache_key,
          },
          {
            headers: {
              Authorization: this.$auth.$storage.getUniversal(
                '_token.auth0',
                true
              ),
            },
          }
        )
        .then((response) => {
          if (response.data.completeFlag) {
            this.$store.dispatch('siteMemberData/saveLikedPhotoDiaryLimit', {
              likedPhotoDiaryLimit: response.data.likedPhotoDiaryLimit,
            });
          }
        })
        .catch((error) => {});
      this.$emit('getLikedNicknames', this.photoDiaryArticles);
    },
    onLikedSumClicked: function (article) {
      this.onLikedArticleIdb = `photoId-${article.id}`;
      const {
        site_member_like_cast_photo_diary_article,
        talent_like_cast_photo_diary_article,
      } = article;
      const persons = []
        .concat(site_member_like_cast_photo_diary_article)
        .concat(talent_like_cast_photo_diary_article);
      if (persons.length > 0) {
        this.likedPersons = persons.sort((a, b) =>
          a.created_at > b.created_at ? 1 : -1
        );
      }
    },
    getLikedSum: function (article) {
      const {
        site_member_like_cast_photo_diary_article,
        talent_like_cast_photo_diary_article,
      } = article;
      return (
        site_member_like_cast_photo_diary_article.length +
        talent_like_cast_photo_diary_article.length
      );
    },
    unescapeHtml: function (target, isConvHashtag = false) {
      if (typeof target !== 'string') return target;
      let patterns = {
        '&lt;': '<',
        '&gt;': '>',
        '&amp;': '&',
        '&quot;': '"',
        '&#x27;': "'",
        '&#x60;': '`',
      };
      let result = target.replace(
        /&(lt|gt|amp|quot|#x27|#x60);/g,
        (match) => patterns[match]
      );
      if (isConvHashtag) {
        this.hashtagCategories.forEach((category) => {
          category.cast_photo_diary_hashtags.forEach((tag) => {
            result = result.replaceAll(
              tag.name,
              `<a class="hashtag" style="color: #4472c4;">${tag.name}</a>`
            );
          });
        });
      }
      return result;
    },
    convertBodyContent: function (body, scope) {
      const maxLength = 15; // 先頭から10文字のみ表示する
      return this.isOutScope(scope)
        ? `${body.substring(
            0,
            maxLength
          )}...<br><span style="color: var(--main-color);">${this.getScopeAnnotation(
            scope
          )}</span>`
        : body;
    },
    hashtagClicked(e) {
      const tag = e.target.innerHTML;
      this.$emit('onBodyHashtagClicked', tag);
    },
    articleUnfoldingFlag: function (index, flag) {
      this.$store.dispatch('bindCast/saveArticleUnfoldingFlag', {
        index: index,
        flag: flag,
      });
    },
  }
})