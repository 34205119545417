import Vue from 'vue'
/* dialog */
import CastEvaluationDialog from '~/components/pages/dialog/CastEvaluationDialog.vue';
import HotelAlertDialog from '~/components/pages/dialog/HotelAlertDialog.vue';
import GuestAlertDialog from '~/components/pages/dialog/GuestAlertDialog.vue';

/* 検索条件 */
import SearchConditionTagsContainer from '~/components/pages/common/SearchCondition/SearchConditionTagsContainer.vue';
import TagTermType from '~/components/pages/common/SearchCondition/TagTermType.vue';


/* Page Parts */
import ContentsHeader from '~/components/layouts/ContentsHeader.vue';
import SeeMoreBtn from '~/components/layouts/SeeMoreBtn.vue';

/* Icon */
import IconHome from '~/assets/img/icon-home.svg';
import IconTalk from '~/assets/img/icon-talk.svg';
import IconSp from '~/assets/img/icon-sp.svg';
import IconCamera from '~/assets/img/icon-camera.svg';
import IconMypage from '~/assets/img/icon-mypage.svg';
import IconQuestion from '~/assets/img/icon-question.svg';
import IconGenre from '~/assets/img/icon-genre.svg';
import IconShop2 from '~/assets/img/icon-shop2.svg';
import IconCast from '~/assets/img/icon-cast.svg';
import IconTag2 from '~/assets/img/icon-tag2.svg';
import IconNo from '~/assets/img/icon-no.svg';
import IconYes from '~/assets/img/icon-yes.svg';
import IconRip from '~/assets/img/icon-rip.svg';
import IconHeart from '~/assets/img/icon-heart.svg';
import IconHand from '~/assets/img/icon-hand.svg';
import IconDenma from '~/assets/img/icon-denma.svg';
import IconMap from '~/assets/img/icon-map.svg';
import IconHotel from '~/assets/img/icon-hotel.svg';

Vue.mixin({
  components: {
    CastEvaluationDialog,
    HotelAlertDialog,
    GuestAlertDialog,
    SearchConditionTagsContainer,
    TagTermType,
    ContentsHeader,
    SeeMoreBtn,
    IconHome,
    IconTalk,
    IconSp,
    IconCamera,
    IconMypage,
    IconQuestion,
    IconGenre,
    IconShop2,
    IconCast,
    IconTag2,
    IconNo,
    IconYes,
    IconRip,
    IconHeart,
    IconHand,
    IconDenma,
    IconMap,
    IconHotel,
  }
})