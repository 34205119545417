<template>
  <div class="pc-contents">
    <v-img :src="pc"></v-img>
  </div>
</template>

<script>
import pc from '~/assets/img/pc.jpg'
export default {
  data: () => ({
    pc: pc,
  }),
}
</script>