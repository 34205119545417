const storeName = 'castTags'
const apiUrl = 'private/cast-tags'

export const state = () => ({
  items: []
})

export const getters = {
  items: (state) => state.items
}

export const mutations = {
  setItems (state, { items }) {
    state.items = items
  }
}

export const actions = {
  async fetchItems({ commit }, params) {
    const accessToken = this.$auth.$storage.getUniversal('_token.auth0', true);
    await this.$axios.post(apiUrl,
      params.params
    , {
      headers: {
        Authorization: `${accessToken}`
      },
    }).then((response) => {
      commit('setItems', { items: response.data })
    })
  },
  async clear({ commit }) {
    commit('setItems', { items: [] })
  },
}