<template>
  <!-- Hotel Alert Dialog -->
  <v-dialog v-model="flag" max-width="500" persistent no-click-animation transition="dialog-bottom-transition">
    <v-card class="pa-6">
      <div>
        <v-img :src="bedImg" width="50" style="margin-left:auto;margin-right:auto;"></v-img>
      </div>
      <div class="py-4 text-center" style="font-weight:bold;font-size:var(--read-font-size-m);color:var(--main-color);">
        <div>ご予約ありがとうございます。</div>
        <div>ホテル情報をご入力ください。</div>
      </div>
      <p style="font-weight:bold;font-size:var(--read-font-size-s);color:var(--read-font-color-primary);">
        {{reserve.date | reserve_moment}}～のご予約についてホテルとお部屋番号がまだご入力いただいておりません。<br>
        マイページから、または以下のボタンを押し予約確認ページからホテル情報をご入力くださいますようお願いいたします。
      </p>

      <v-card class="px-4" elevation="0" style="color:#fff;font-weight:bold;font-size:var(--read-font-size-xs);color:var(--main-color);background-color:var(--main-bg-color);">
        <V-row>
          <v-col class="pa-0" cols="2">
            <v-layout column justify-center fill-height>
              <v-container class="pa-0 text-center">
                <v-icon color="primary">mdi-alert</v-icon>
              </v-container>
            </v-layout>
          </v-col>
          <v-col cols="10">
            <div>ご連絡なくキャンセルとなる場合は、次回以降のご予約がとりずらくなる場合がございます。ご了承ください。</div>
          </v-col>
        </V-row>
      </v-card>

      <v-btn
        class="mt-6"
        color="primary"
        block
        height="45"
        style="font-size: var(--read-font-size-m);"
        :to="`/reserve/${reserve.id}`"
      >
        ホテル情報を入力する
      </v-btn>

      <v-btn
        class="mt-2"
        text
        block
        height="45"
        style="font-size:var(--read-font-size-s);font-weight:bold;color:var(--read-font-color-primary);"
        v-on:click="flag=!flag"
      >
        まだホテルが決まっていない
      </v-btn>

    </v-card>
  </v-dialog>
</template>

<script>
import bedImg from '~/assets/img/bed.svg'

export default {
  data () {
    return{
      flag: false,
      bedImg: bedImg,
      reserve: {
        id: this.$store.state.notification.hotelAlert.id,
        date: this.$store.state.notification.hotelAlert.date
      },
    }
  },
  created: function () {
    // storeに入力が必要な予約が存在するか確認
    if(this.$store.state.notification.hotelAlert.id&&this.$store.state.notification.hotelAlert.date){
      if(this.$moment(this.$store.state.notification.hotelAlert.date).format('YYYY-MM-DD HH:mm:ss') >= this.$moment().add(-10, 'minutes').format('YYYY-MM-DD HH:mm:ss') &&
      this.$moment(this.$store.state.notification.hotelAlert.date).format('YYYY-MM-DD HH:mm:ss') <= this.$moment().add(10, 'minutes').format('YYYY-MM-DD HH:mm:ss')){
        this.flag = true;
      }
    }
  },
}
</script>

<style scoped>
</style>