<template>
  <v-list subheader class="mb-4 pa-0">
    <v-list-item
      class="v-list-item--active list_style"
      style="background-color: #fff"
    >
      <v-icon color="rgba(111, 111, 111, 0.75)" style="padding-top: 1px">{{
        icon
      }}</v-icon>
      <v-list-item-content class="pl-2">
        <v-list-item-title>{{ title }}</v-list-item-title>
      </v-list-item-content>
      <!-- <v-icon
        v-if="icon2"
        color="rgba(111, 111, 111, 0.75)"
        style="padding-top: 1px"
        v-on:click="messageDialog = !messageDialog"
        >{{ icon2 }}</v-icon
      > -->
    </v-list-item>

    <!-- Logout Confirm Modal -->
    <!-- <v-dialog
      v-model="messageDialog"
      max-width="400"
      transition="dialog-bottom-transition"
      style="z-index: 9999"
    >
      <v-card
        class="pa-3 text-center"
        style="font-size: var(--read-font-size-s); font-weight: bold"
      >
        <div class="pa-3">
          {{ $t('text_nmi1') }}<br />
          <a
            :href="`mailto:${this.email}`"
            style="font-size: var(--read-font-size-m)"
            >{{ this.email }}</a
          >
        </div>
        <v-btn
          small
          elevation="0"
          v-on:click="messageDialog = !messageDialog"
          style="color: var(--main-color); font-weight: bold"
          >{{ $t('close') }}</v-btn
        >
      </v-card>
    </v-dialog> -->
  </v-list>
</template>

<script>
export default {
  props: ['to', 'icon', 'title', 'icon2'],
  data: () => ({
    messageDialog: false,
    email: process.env.APP_EMAIL,
  }),
};
</script>
