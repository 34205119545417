/**
 * ローディング
 */

export const state = () => ({
  save: false,
  post: false,
  delete: false,
  change: false,
  login: false,
  logout: false,
  pay: false,
})

export const mutations = {
  save(state, flag) {
    state.save = flag;
  },
  post(state, flag) {
    state.post = flag;
  },
  delete(state, flag) {
    state.delete = flag;
  },
  change(state, flag) {
    state.change = flag;
  },
  login(state, flag) {
    state.login = flag;
  },
  logout(state, flag) {
    state.logout = flag;
  },
  pay(state, flag) {
    state.pay = flag;
  },
}

export const actions = {
  save(context, flag) {
    context.commit('save', flag)
  },
  post(context, flag) {
    context.commit('post', flag)
  },
  delete(context, flag) {
    context.commit('delete', flag)
  },
  change(context, flag) {
    context.commit('change', flag)
  },
  login(context, flag) {
    context.commit('login', flag)
  },
  logout(context, flag) {
    context.commit('logout', flag)
  },
  pay(context, flag) {
    context.commit('pay', flag)
  },
}
