/**
 * お知らせ
 */

export const state = () => ({
  castEvaluation: null, // キャスト評価が必要な予約ID
  hotelAlert: [], // 予約時間の10分前までにホテルの入力がない場合予約IDと予約日時を入れる
  notificationBadgesFlag: false, // 通知用バッジフラグ(通知が存在する場合true)
  notificationCheckTime: '', // 通知チェックAPIで最後に取得した時間
  chatUnReadMessageCount: 0,
  noticeNotificationDayBefore: 0, // お知らせ通知(24時間前)
  noticeNotificationUntreated: 0, // お知らせ通知(未処理)
  noticeNotificationUncheckCount: 0, // お知らせ通知(未読数)
  reservationList: 1,
})

export const mutations = {
  // キャスト未評価情報を変更
  castEvaluation(state, data) {
    state.castEvaluation = data;
  },
  // ホテルアラート情報を変更
  hotelAlert(state, data) {
    state.hotelAlert = data;
  },
  // 通知用バッジフラグを変更
  notificationBadgesFlag(state, flag) {
    state.notificationBadgesFlag = flag;
  },
  // 通知チェック時間を変更
  notificationCheckTime(state, time) {
    state.notificationCheckTime = time;
  },
  // 通知チェック時間を変更
  chatUnReadMessageCount(state, count) {
    state.chatUnReadMessageCount = count;
  },
  // お知らせ通知(24時間前)
  noticeNotificationDayBefore(state, data) {
    state.noticeNotificationDayBefore = data;
  },
  // お知らせ通知(未処理)
  noticeNotificationUntreated(state, data) {
    state.noticeNotificationUntreated = data;
  },
  // お知らせ通知(未読数)
  noticeNotificationUncheckCount(state, data) {
    state.noticeNotificationUncheckCount = data;
  },
  // お知らせ通知(未処理)
  reservationList(state, data) {
    state.reservationList = data;
  },
}

export const actions = {
  castEvaluation(context, data) {
    context.commit('castEvaluation', data)
  },
  hotelAlert(context, data) {
    context.commit('hotelAlert', data)
  },
  notificationBadgesFlag(context, flag) {
    context.commit('notificationBadgesFlag', flag)
  },
  notificationCheckTime(context, time) {
    context.commit('notificationCheckTime', time)
  },
  chatUnReadMessageCount(context, count) {
    context.commit('chatUnReadMessageCount', count)
  },
  noticeNotificationDayBefore(context, data) {
    context.commit('noticeNotificationDayBefore', data)
  },
  noticeNotificationUntreated(context, data) {
    context.commit('noticeNotificationUntreated', data)
  },
  noticeNotificationUncheckCount(context, data) {
    context.commit('noticeNotificationUncheckCount', data)
  },
  reservationList(context, data) {
    context.commit('reservationList', data)
  },
}
