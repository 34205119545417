export default function({ store, redirect, route }) {
  // 現在のパスを取得
  const currentPath = route.path;

  // ログインしているかチェック
  if(store.state.auth.loggedIn){

    // メール認証フラグチェック
    if(store.state.auth.user.email_verified){
      // メール認証フラグがtrue場合/email-verificationを表示しない
      if(currentPath==='/en/email-verification'){
        redirect('/en');
      }

      // 初期登録チェック
      if(store.state.siteMemberData.id){
        // /registerに来たら/に移動
        if(currentPath==='/en/register'){
          redirect('/en');
        }
      }else{
        // /registerか/logout以外のページに来たら/に移動
        if(currentPath!=='/en/register' && currentPath!=='/en/terms' && currentPath!=='/en' && currentPath!=='/en/logout'){
          redirect('/en');
        }
      }

    }else{
      // メール未認証の場合email-verificationにリダイレクト
      if(currentPath!=='/en/email-verification' && currentPath!=='/en/logout'){
        redirect('/en/email-verification');
      }
    }
  }else{
    // ログインしていない時はデータを消しておく
    store.dispatch('siteMemberData/deleteSiteMemberData');
  }
}