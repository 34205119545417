/**
 * ダイアログ
 */

export const state = () => ({
  loginError: false,
  reservationImpossibleMode: 0,
  likedPhotoDiaryLimitDialog: false,
  helpFullReviewLimitDialog: false,
  helpFullReviewLimitDateDialog: false,
  followSiteMemberLimitDateDialog: false,
  givingPointDialog: false,
  // overlay
  helpFullButtonOverlay: false,
})

export const mutations = {
  loginError(state, flag) {
    state.loginError = flag;
  },
  reservationImpossibleMode(state, mode) {
    state.reservationImpossibleMode = mode;
  },
  saveLikedPhotoDiaryLimitDialogFlag(state, flag) {
    state.likedPhotoDiaryLimitDialog = flag;
  },
  saveHelpFullReviewLimitDialogFlag(state, flag) {
    state.helpFullReviewLimitDialog = flag;
  },
  saveHelpFullReviewLimitDateDialogFlag(state, flag) {
    state.helpFullReviewLimitDateDialog = flag;
  },
  saveFollowSiteMemberLimitDateDialogFlag(state, flag) {
    state.followSiteMemberLimitDateDialog = flag;
  },
  saveGivingPointDialogFlag(state, flag) {
    state.givingPointDialog = flag;
  },
  saveHelpFullButtonOverlayFlag(state, flag) {
    state.helpFullButtonOverlay = flag;
  },
}

export const actions = {
  loginError(context, flag) {
    context.commit('loginError', flag)
  },
  reservationImpossibleMode(context, mode) {
    context.commit('reservationImpossibleMode', mode)
  },
  saveLikedPhotoDiaryLimitDialogFlag(context, flag) {
    context.commit('saveLikedPhotoDiaryLimitDialogFlag', flag)
  },
  saveHelpFullReviewLimitDialogFlag(context, flag) {
    context.commit('saveHelpFullReviewLimitDialogFlag', flag)
  },
  saveHelpFullReviewLimitDateDialogFlag(context, flag) {
    context.commit('saveHelpFullReviewLimitDateDialogFlag', flag)
  },
  saveFollowSiteMemberLimitDateDialogFlag(context, flag) {
    context.commit('saveFollowSiteMemberLimitDateDialogFlag', flag)
  },
  saveGivingPointDialogFlag(context, flag) {
    context.commit('saveGivingPointDialogFlag', flag)
  },
  saveHelpFullButtonOverlayFlag(context, flag) {
    context.commit('saveHelpFullButtonOverlayFlag', flag)
  },
}
