/**
 * ランキング情報保持
 * ページ遷移時にランキングの情報を維持する
 */

export const state = () => ({
  rankingCast1: [],
  rankingCast2: [],
  rankingCast3: [],
  rankingCast4: [],
  rankingCast5: [],
  selectedTermTypeFavorite: 4,
  selectedTermTypePhotoDiary: 1,
  selectedGenresDesination: [],
  selectedGenresFavorite: [],
  selectedGenresPhotoDiary: [],
  selectedAreasDesination: [],
  selectedAreasFavorite: [],
  selectedAreasPhotoDiary: [],
  selectedShopDesination: null,
  selectedShopFavorite: null,
  selectedShopPhotoDiary: null,
  freeTimeExists: false, // 本指名ランキング　空き枠のあるキャスト用
  page1: 1,
  page2: 1,
  page3: 1,
  page4: 1,
  page5: 1,
  // termTypeItems: [
  //   {id: 1, label: '週間（前日までの７日間）'},
  //   {id: 2, label: '月間（前日までの３０日間）'},
  //   {id: 3, label: '前日'},
  //   {id: 4, label: '累計'},
  // ],
  // genreItems: [
  //   { id: 1, label: 'デリヘル' },
  //   { id: 2, label: 'エステ' },
  //   { id: 3, label: 'オナクラ' },
  // ],
  // areaItems: [
  //   { id: 13, label: '東京都' },
  //   { id: 14, label: '神奈川県' },
  //   { id: 11, label: '埼玉' },
  //   { id: 12, label: '千葉' },
  // ],
  //shopItems: [{ value: 0, label: 'すべて' }],
});

export const mutations = {
  // ランキング情報を保存
  saveRankingCast1Data(state, data) {
    state.rankingCast1 = data.length ? state.rankingCast1.concat(data) : [];
  },
  saveRankingCast2Data(state, data) {
    if (state.page2 === 1) state.rankingCast2 = [];
    if (data.length) state.rankingCast2 = state.rankingCast2.concat(data);
  },
  saveRankingCast3Data(state, data) {
    state.rankingCast3 = data.length ? state.rankingCast3.concat(data) : [];
  },
  saveRankingCast4Data(state, data) {
    state.rankingCast4 = data.length ? state.rankingCast4.concat(data) : [];
  },
  saveRankingCast5Data(state, data) {
    state.rankingCast5 = data.length ? state.rankingCast5.concat(data) : [];
  },
  // 選択値を保存
  saveSelectedTermTypeFavorite(state, data) {
    state.selectedTermTypeFavorite = data;
  },
  saveSelectedTermTypePhotoDiary(state, data) {
    state.selectedTermTypePhotoDiary = data;
  },
  saveSelectedGenresDesination(state, data) {
    state.selectedGenresDesination = data;
  },
  saveSelectedGenresFavorite(state, data) {
    state.selectedGenresFavorite = data;
  },
  saveSelectedGenresPhotoDiary(state, data) {
    state.selectedGenresPhotoDiary = data;
  },
  saveSelectedAreasDesination(state, data) {
    state.selectedAreasDesination = data;
  },
  saveSelectedAreasFavorite(state, data) {
    state.selectedAreasFavorite = data;
  },
  saveSelectedAreasPhotoDiary(state, data) {
    state.selectedAreasPhotoDiary = data;
  },
  saveSelectedShopDesination(state, data) {
    state.selectedShopDesination = data;
  },
  saveSelectedShopFavorite(state, data) {
    state.selectedShopFavorite = data;
  },
  saveSelectedShopPhotoDiary(state, data) {
    state.selectedShopPhotoDiary = data;
  },
  saveFreeTimeExists(state, data) {
    state.freeTimeExists = data;
  },
  setShopItems(state, data) {
    state.shopItems = data;
  },
  savePage1 (state, data) {
    state.page1 = data;
  },
  savePage2 (state, data) {
    state.page2 = data;
  },
  savePage3 (state, data) {
    state.page3 = data;
  },
  savePage4 (state, data) {
    state.page4 = data;
  },
  savePage5 (state, data) {
    state.page5 = data;
  },
};

export const actions = {
  saveRankingCast1Data(context, data) {
    context.commit('saveRankingCast1Data', data);
  },
  saveRankingCast2Data(context, data) {
    context.commit('saveRankingCast2Data', data);
  },
  saveRankingCast3Data(context, data) {
    context.commit('saveRankingCast3Data', data);
  },
  saveRankingCast4Data(context, data) {
    context.commit('saveRankingCast4Data', data);
  },
  saveRankingCast5Data(context, data) {
    context.commit('saveRankingCast5Data', data);
  },
  saveSelectedTermTypeFavorite(context, data) {
    context.commit('saveSelectedTermTypeFavorite', data);
  },
  saveSelectedTermTypePhotoDiary(context, data) {
    context.commit('saveSelectedTermTypePhotoDiary', data);
  },
  saveSelectedGenresDesination(context, data) {
    context.commit('saveSelectedGenresDesination', data);
  },
  saveSelectedGenresFavorite(context, data) {
    context.commit('saveSelectedGenresFavorite', data);
  },
  saveSelectedGenresPhotoDiary(context, data) {
    context.commit('saveSelectedGenresPhotoDiary', data);
  },
  saveSelectedAreasDesination(context, data) {
    context.commit('saveSelectedAreasDesination', data);
  },
  saveSelectedAreasFavorite(context, data) {
    context.commit('saveSelectedAreasFavorite', data);
  },
  saveSelectedAreasPhotoDiary(context, data) {
    context.commit('saveSelectedAreasPhotoDiary', data);
  },
  saveSelectedShopDesination(context, data) {
    context.commit('saveSelectedShopDesination', data);
  },
  saveSelectedShopFavorite(context, data) {
    context.commit('saveSelectedShopFavorite', data);
  },
  saveSelectedShopPhotoDiary(context, data) {
    context.commit('saveSelectedShopPhotoDiary', data);
  },
  saveFreeTimeExists(context, data) {
    context.commit('saveFreeTimeExists', data);
  },
  setShopItems(context, data) {
    context.commit('setShopItems', data);
  },
  commitPage1(context, data) {
    context.commit('savePage1', data)
  },
  commitPage2(context, data) {
    context.commit('savePage2', data)
  },
  commitPage3(context, data) {
    context.commit('savePage3', data)
  },
  commitPage4(context, data) {
    context.commit('savePage4', data)
  },
  commitPage5(context, data) {
    context.commit('savePage5', data)
  },
};
