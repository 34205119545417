import { VApp } from 'vuetify/lib/components/VApp';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[(_vm.isCommonHeader === true)?_c('common-header'):_vm._e(),_vm._v(" "),_c('nuxt',{class:_vm.contentsStyle,style:(this.nuxtStyle),attrs:{"id":"contents-border"}}),_vm._v(" "),_c('client-only',[(
        this.$store.state.auth.loggedIn === false &&
        this.$route.path !== ("/" + (this.$i18n.locale) + "/login") &&
        this.$route.path !== ("/" + (this.$i18n.locale) + "/email-verification") &&
        this.$route.path !==
          ("/" + (this.$i18n.locale) + "/email-verification-complete") &&
        this.$route.path !== ("/" + (this.$i18n.locale) + "/register") &&
        this.$route.path !== ("/" + (this.$i18n.locale) + "/questionnaire") &&
        this.$route.path !== ("/" + (this.$i18n.locale) + "/event") &&
        this.$route.path !== ("/" + (this.$i18n.locale) + "/error") &&
        this.$route.path !== ("/" + (this.$i18n.locale) + "/terms") &&
        this.$route.path !== ("/" + (this.$i18n.locale) + "/call/step2") &&
        this.$route.path !== ("/" + (this.$i18n.locale) + "/call/search")
      )?_c('SignInButton'):_vm._e()],1),_vm._v(" "),(
      this.$route.path !== ("/" + (this.$i18n.locale) + "/login") &&
      this.$route.path !== ("/" + (this.$i18n.locale) + "/email-verification") &&
      this.$route.path !==
        ((this.$i18n.locale) + "/email-verification-complete") &&
      this.$route.path !== ("/" + (this.$i18n.locale) + "/register") &&
      this.$route.path !== ("/" + (this.$i18n.locale) + "/questionnaire") &&
      this.$route.path !== ("/" + (this.$i18n.locale) + "/event") &&
      this.$route.path !== ("/" + (this.$i18n.locale) + "/error") &&
      this.$route.path !== ("/" + (this.$i18n.locale) + "/terms") &&
      this.$route.path !== ("/" + (this.$i18n.locale) + "/call/step2") &&
      this.$route.path !== ("/" + (this.$i18n.locale) + "/call/search") &&
      this.$route.path !== ("/" + (this.$i18n.locale) + "/call2/search") &&
      this.$route.path !== ("/" + (this.$i18n.locale) + "/call3/search") &&
      this.$route.path !== ("/" + (this.$i18n.locale) + "/call4/search") &&
      !this.$route.path.match('/chat/') &&
      !this.$store.state.castDialog.selectedCastProfileDialog &&
      !this.$store.state.castDialog.selectedCastReserveDialog
    )?_c('common-nav'):_vm._e(),_vm._v(" "),(
      this.$route.path !== ("/" + (this.$i18n.locale) + "/login") &&
      this.$route.path !== ("/" + (this.$i18n.locale) + "/email-verification") &&
      this.$route.path !== ("/" + (this.$i18n.locale) + "/email-verification-complete")
    )?_c('div',[_c('loading-login'),_vm._v(" "),_c('loading-logout'),_vm._v(" "),_c('login-error-dialog')],1):_vm._e(),_vm._v(" "),_c('FollowSiteMemberLimitDialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }