<template>
  <div class="search-condition-tags-container py-3 d-flex">
    <slot></slot>
  </div>
</template>

<script>

export default {
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.search-condition-tags-container {
  overflow: scroll;
  ::v-deep .selected-tag {
    border-radius: 5px;
    background: var(--sub-color) !important;
    height: 25px;
    line-height: 25px;
    font-size: 0.75rem;
    font-weight: bold;
    color: #fff;
    .v-btn__content .fixed-cross-mark {
      position: relative !important;
      width: 10px;
      height: 10px;
      background: transparent;
      &:before,
      &:after {
        z-index: 3;
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 3px;
        height: 14px;
        background: #fff;
      }
      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
  ::v-deep  .selected-tag-d-none {
    display: none !important;
  }
}
</style>