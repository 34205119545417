import Vue from 'vue'

export default () => {
  const bind = (el, binding) => {
    const targets = el.querySelectorAll('a[class="hashtag"]')
    for (let i = targets.length; i--;) {
      targets[i].addEventListener('click', binding.value, false)
    }
  }
  const unbind = (el, binding) => {
    const targets = el.querySelectorAll('a[class="hashtag"]')
    for (let i = targets.length; i--;) {
      targets[i].removeEventListener('click', binding.value, false)
    }
  }
  Vue.directive('hashtag-handler', {
    bind,
    unbind,
    componentUpdated(el, binding) {
      unbind(el, binding)
      bind(el, binding)
    },
  })
}
