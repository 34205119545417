<template>
  <div class="loading" :class="{ 'loading-finish': !this.$store.state.loading.login }" v-if="defaultLoading">
    <div class="overlay">
      <div class="wrap">
        <div class="site-name fade">
          <v-img width="55%" max-width="350" :src="logo" style="margin-left:auto;margin-right:auto;"></v-img>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import logo from '~/assets/img/logo_group.svg'

export default {
  data() {
    return {
      defaultLoading: false,
      logo: logo,
    };
  },
  created: function () {
    this.defaultLoading = this.$store.state.loading.login;
  },
  computed: {
    storeLoadingFlag() {
      return this.$store.state.loading.login;
    }
  },
  watch: {
    storeLoadingFlag() {
      setTimeout(() => {
        this.defaultLoading = false;
      }, 1000);
    }
  }
}
</script>

<style lang="scss" scoped>
.overlay {
  background-color: #fff;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.wrap{
  text-align: center;
  width: 100%;
}
.loading-finish {
  transition: opacity 1s;
  opacity: 0;
}
.site-name{
  opacity: 0;
  font-size: 10vw;
}
.fade{
  animation-name:fade-in;
  animation-duration:1s;
  animation-timing-function: ease-out;
  animation-delay:1s;
  animation-iteration-count:1;
  animation-direction:normal;
  animation-fill-mode: forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
