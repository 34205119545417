export const state = () => ({
  newMessage: [], // Pusherから取得した新規のメッセージ
})
export const getters = {
  getMessage(state) {
    return state.newMessage
  }
}
export const mutations = {
  setMessage(state, data) {
    state.newMessage = data.pusherData
  },
  resetStore(state) {
    state.newMessage = []
  },
}
export const actions = {
  resetStore(context) {
    context.commit('resetStore')
  },
}
