export const state = () => ({
  firstLoadTime: '', // ログインやリロードなどを行った時間
  timeToGetNewCast: '', // 新人キャストを取得した時間
  timeToGetCastRankings: '', // ランキングを取得した時間
})

export const mutations = {
  firstLoadTime(state, time) {
    state.firstLoadTime = time;
  },
  timeToGetNewCast(state, time) {
    state.timeToGetNewCast = time;
  },
  timeToGetCastRankings(state, time) {
    state.timeToGetCastRankings = time;
  },
}

export const actions = {
  firstLoadTime(context, time) {
    context.commit('firstLoadTime', time)
  },
  timeToGetNewCast(context, time) {
    context.commit('timeToGetNewCast', time)
  },
  timeToGetCastRankings(context, time) {
    context.commit('timeToGetCastRankings', time)
  },
}
